import styled from "styled-components";
import { PRIMARY_BORDER_GRAY_COLOR, TEXT_SEMIBOLD } from "../utils/constant";

const StyledViewCardFooter = styled.div`
  font-weight: 500;
  .segment-and-filter {
    height: 44px;
    background-color: #dfe2ea66;
  }
  .segment-and-filter {
    display: flex;
    gap: 24px;
    font-size: 12px;
    align-items: center;
    padding: 0px 12px;
    color: ${TEXT_SEMIBOLD};
    & > div {
      display: flex;
      align-items: center;
    }
    img {
      height: 12px;
      width: 12px;
      margin-right: 5px;
    }
    .segments-filter-list {
      height: 100%;
    }
  }
  .select-view {
    border-top: 1px solid ${PRIMARY_BORDER_GRAY_COLOR};
    height: 52px;
    font-size: 13px;
    color: #cc400c;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 0px 12px;
  }
`;

export default StyledViewCardFooter;