import styled from "styled-components";
import Tippy from "@tippyjs/react";
import {
  PRIMARY_BORDER_GRAY_COLOR,
  TEXT_BOLD,
} from "../ViewsComponents/ViewCard/utils/constant";

const StyledButtonWithDropdownOption = styled(Tippy)`
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid ${PRIMARY_BORDER_GRAY_COLOR};
  box-shadow: 0px 5px 3px -2px #0000000f;
  width: 212px;
  .tippy-box {
    max-width: auto;
  }
  .tippy-content {
    padding: 0px;
  }
  ul {
    font-weight: 500;
    color: ${TEXT_BOLD};
    margin: 5px 0px;
    padding: 0px;
    font-size: 14px;

    li {
      padding: 10px 10px;
      display: flex;
      cursor: pointer;
      gap: 12px;
      align-items: center;
      white-space: nowrap;
      &:hover {
        background-color: #f9fafb;
      }
    }
    
  }
`;

export default StyledButtonWithDropdownOption;
