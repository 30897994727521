import {
  ADD_MESSAGE,
  USER_TYPING,
  ASSISTANT_RESPONDING,
} from "./actiontypes/actiontypes";

const initialState = {
  messages: [],
  isUserTyping: false,
  isAssistantResponding: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case USER_TYPING:
      return {
        ...state,
        isUserTyping: action.payload,
      };
    case ASSISTANT_RESPONDING:
      return {
        ...state,
        isAssistantResponding: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
