import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ViewCard from "../ViewCard";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import ApiData from "../../../utils/Api";
import DetailModal from "../DetailModal";
import ViewDetailModalContent from "../ViewDetailModalContent";
import FileIcon from "../../../assets/icons/File type icon.png";
import SearchIcon from "../../../assets/icons/icon-search.svg";
import styled from "styled-components";
import { RxCrossCircled } from "react-icons/rx";
import { setViews } from "../../../reducers/TwoByTwoChart/actionCreators";
import StyledViewsReorderableList from "./Styled.ViewsReorderableList";
// import ViewSelectionTriggerArrowIcon from "../../../assets/icons/view_selection_arrow_icon.svg";
import DeleteView from "../DeleteViewOrReport";
import CreateEditView from "../CreateEditView";
import Toaster from "../Toaster";
import LoadingLayer from "../../widgets/Shared/LoadingLayer";

const StyledBlankListContainer = styled.div`
  height: calc(100vh - 285px);
  display: flex;
  width: calc(100vw - 180px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  .blank-list-image-container {
    height: 104px;
    width: 104px;
    background: linear-gradient(rgb(249, 250, 251) 0%, rgb(237, 240, 243) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div {
    color: rgb(16, 24, 40);
    height: 28px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
  }
`;

function ViewsReorderableList() {
  const viewsListData = useSelector((state) => state?.twoByTwoChart?.viewsList);
  const dispatch = useDispatch();
  const [viewsList, setViewsList] = useState([]);
  const [deleteDataViewModal, setDeleteDataViewModal] = useState(null);
  const [dataOnViewDetailModal, setDataOnViewDetailModal] = useState(null);
  const [cloneViewModal, setCloneViewModal] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { viewIds, viewsData } = useMemo(() => {
    const viewsData = {};
    const viewIds = viewsList.map((view) => {
      viewsData[view?.viewId] = view;
      return view?.viewId;
    });
    return {
      viewIds,
      viewsData,
    };
  }, [viewsList]);

  const reportsIdsSelectedOnViews = useSelector(
    (state) => state?.twoByTwoChart?.reportsIdsSelectedOnViews
  );

  const filteredViewsIds = useMemo(() => {
    const updatedList = viewsList
      ?.filter((view) =>
        view?.viewName?.toLowerCase()?.includes(searchKey.toLowerCase())
      )
      ?.map((view) => view?.viewId);
    return updatedList;
  }, [searchKey, viewsList]);

  useEffect(() => {
    getViewsList(reportsIdsSelectedOnViews);
  }, [reportsIdsSelectedOnViews]);

  useEffect(() => {
    setViewsList(viewsListData);
  }, [viewsListData]);

  function getViewsList(reportIds = []) {
    ApiData.getViewsList(reportIds).then((res) => {
      if (res?.length > 0) {
        dispatch(setViews(res));
        setViewsList(res);
        setIsLoading(false);
      }
    });
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setViewsList((views) => {
        const oldIndex = views.findIndex((view) => view.viewId === active?.id);
        const newIndex = views.findIndex((view) => view.viewId === over?.id);
        const updatedArray = arrayMove(views, oldIndex, newIndex);
        updateViewsOrder(updatedArray);
        return updatedArray;
      });
    }
  };

  function updateViewsOrder(updatedViews) {
    updatedViews = updatedViews?.map(({ viewId }, ind) => {
      return {
        viewId: viewId,
        order: ind
      };
    });
    ApiData.updateViewsOrder(reportsIdsSelectedOnViews[0], updatedViews);
  }

  const handleDefaultView = async (e, viewDetails) => {
    let updatedViewsList = [...viewsList]; // Create a new copy of the array
    updatedViewsList = updatedViewsList?.map((ele) => {
      if (ele?.viewId === viewDetails?.viewId && ele?.isDefaultView === false) {
        ele.isDefaultView = !ele?.isDefaultView;
      } else {
        ele.isDefaultView = false;
      }
      return ele;
    });
    ApiData.setDefaultView(viewDetails?.viewId)
      .then((res) => {
        dispatch(setViews(updatedViewsList));
        setViewsList(updatedViewsList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleToaster = () => {
    setIsToasterOpen(true);
  };

  const handleToasterClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsToasterOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingLayer />
      ) : (
        <StyledViewsReorderableList>
          <div className="views-header-filter-container">
            <div className="views-search-container">
              <img src={SearchIcon} alt="" className="actionIcon" />
              <input
                type="text"
                placeholder="Search Views"
                value={searchKey}
                className="textarea"
                onChange={(e) => {
                  setSearchKey(e.target.value);
                }}
              />
              {searchKey?.length > 0 && (
                <RxCrossCircled
                  className="prefixIcon cursor"
                  onClick={() => {
                    setSearchKey("");
                  }}
                />
              )}
            </div>
          </div>
          {filteredViewsIds?.length > 0 ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "12px",
                }}
              >
                <SortableContext
                  items={viewIds}
                  useDragOverlay={false}
                  strategy={rectSortingStrategy}
                >
                  {filteredViewsIds.map((viewId) => (
                    <ViewCard
                      key={viewId}
                      id={viewId}
                      reportsIdsSelectedOnView={
                        reportsIdsSelectedOnViews?.length > 0
                          ? reportsIdsSelectedOnViews[0]
                          : ""
                      }
                      openViewDeleteModal={(viewDetail) =>
                        setDeleteDataViewModal(viewDetail)
                      }
                      handleDefaultView={(e, viewDetail) =>
                        handleDefaultView(e, viewDetail)
                      }
                      openViewDetailModal={(viewDetail) =>
                        setDataOnViewDetailModal(viewDetail)
                      }
                      openCloneViewModal={(viewDetail) => {
                        setCloneViewModal(viewDetail);
                      }}
                      view={viewsData[viewId]}
                    />
                  ))}
                </SortableContext>
              </div>
            </DndContext>
          ) : (
            <StyledBlankListContainer>
              <div className="blank-list-image-container">
                <img src={FileIcon} alt="" />
              </div>
              <div className="header">
                {searchKey?.length > 0 ? "No views found" : "No Views Created"}
              </div>
            </StyledBlankListContainer>
          )}
          <DetailModal
            open={dataOnViewDetailModal !== null}
            showCloseIcon={false}
            onClose={() => {
              setDataOnViewDetailModal(null);
            }}
          >
            {dataOnViewDetailModal && (
              <ViewDetailModalContent
                view={dataOnViewDetailModal}
                onClose={() => {
                  setDataOnViewDetailModal(null);
                }}
              />
            )}
          </DetailModal>
          {cloneViewModal && (
            <CreateEditView
              isOpen={cloneViewModal !== null}
              viewDetails={cloneViewModal}
              closeModal={() => {
                setCloneViewModal(null);
              }}
              handleShowToaster={toggleToaster}
              selectedViewAction="clone"
            />
          )}
          {deleteDataViewModal && (
            <DeleteView
              selectedType={"view"}
              closeModal={() => setDeleteDataViewModal(null)}
              isOpen={deleteDataViewModal !== null}
              selectedData={[deleteDataViewModal]}
            />
          )}
          {isToasterOpen && (
            <Toaster isOpen={isToasterOpen} onClose={handleToasterClose}>
              View has been succesfully created.
            </Toaster>
          )}
        </StyledViewsReorderableList>
      )}
    </>
  );
}

export default ViewsReorderableList;
