import filtersTransformations from "./filterTransformations";
import ApiData from "./Api";
import { safeParseJSONLocalStorage, safeParseJSONSessionStorage } from "./getBrowserStorageData";
import initData from "./initData";
import { getSessionStorage, setSessionStorage } from "./storageHelpers";
import { getURLPathParams } from "./URLParamsValidations";

export const phoneConvert = (phone) => {
    if(!phone) return '';
    let converted = phone.replace(/[^0-9]/g,'');
    if (converted.length < 3 ) {
        // converted = converted;
    } else if(converted.length===3) {
        converted = `(${converted})`
    } else if (converted.length > 3 && converted.length < 6) {
        converted = `(${converted.substr(0, 3)})${converted.substr(3)}`;
    } else if (converted.length===6) {
        converted = `(${converted.substr(0, 3)})${converted.substr(3, 3)}-`;
    } else if (converted.length>6) {
        converted = `(${converted.substr(0, 3)})${converted.substr(3, 3)}-${converted.substr(6,4)}`;
    } else {
        // converted = converted;
    }
    return converted;
}

export const setUserSelectedMarketsByBrand = () => {
    let globalBrands = safeParseJSONLocalStorage('globalBrands');
    let userSelectedGlobalMarkets = globalBrands?.reduce((accu,ele)=>{
        let result = ele?.marketGroups?.reduce((acc, current) => {
            let selectedMarkets = current?.marketGroupsList?.reduce((innerAcc, innerCurrent) => {
              innerAcc.push(innerCurrent?.identity); 
              return innerAcc;
            }, []);
            let selectedURLMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets')?.find(({brandId})=> brandId == ele?.id)?.markets;
            selectedMarkets = selectedMarkets?.filter(ele => selectedURLMarkets?.some(id => id == ele));
            acc.push(...selectedMarkets); 
            return acc;
          }, []);
        return accu.concat({brandId:ele?.id,markets: [...new Set(result)]});
    },[])
    setSessionStorage('userSelectedGlobalMarkets',JSON.stringify(userSelectedGlobalMarkets));
}

export const setUserSelectedBrandsByMarket = (selectedMarket, selectedBrands) => {
    let brands = safeParseJSONLocalStorage('myBrands');
    let selectedUserBrands = safeParseJSONSessionStorage('userSelectedBrands');
    let userSelectedBrands = selectedUserBrands?.length > 0 ? selectedUserBrands : [];
    let findIndex = userSelectedBrands?.findIndex(({marketId})=>marketId == selectedMarket);
    if(findIndex> -1){
        userSelectedBrands[findIndex].brands = selectedBrands;
    }else if(selectedBrands?.length > 0)
        userSelectedBrands.push({marketId: selectedMarket, brands: selectedBrands})
    else{
        userSelectedBrands.push({marketId: selectedMarket, brands: brands?.map(({id})=>id)})
    }
    setSessionStorage('userSelectedBrands',JSON.stringify(userSelectedBrands));
}

export const setUserSelectedMarkets = (selectedGlobalBrandId, selectedMarkets) => {
    let userSelectedGlobalMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets');
    let findIndex = userSelectedGlobalMarkets?.findIndex(({brandId})=> brandId == selectedGlobalBrandId);
    findIndex > -1 && (userSelectedGlobalMarkets[findIndex].markets = [...new Set(selectedMarkets)]);
    setSessionStorage('userSelectedGlobalMarkets',JSON.stringify(userSelectedGlobalMarkets));
}

export const validationsForHSSMarketLabels = (item, market, zFields) => {
    let data = [];
    for (let key in item) {
        if (key !== 'pool' && key !== 'higherThan' && key !== 'lowerThan' && key !== 'inputName' && key !== 'inputId' && key !== 'date' && key !=='genpop'&& key !=='poolTotal' && key !== 'newDate' && !key.includes('Diff') && key !== 'dailyAverageSampleSize'){
            if(market === 'HSS'){
                let value = item[key];
                let zValue = zFields && zFields[key];
                if(key === 'fun') key = 'approachable';
                else if(key === 'hip') key = 'bestInClass';
                else if(key === 'intelligent') key = 'knowledgeable';
                else if(key === 'stylish') key = 'personable';
                else if(key === 'onlineGaming') key = 'SearchEngineegGoogleBing';

                data.push({ 
                    label: key, 
                    value: Number((value)?.toFixed(1)),
                    zValue: ((zValue)?.toFixed(1))
                });
            }else if(market !== 'HSS'){ 
                data.push({ 
                    label: key, 
                    value: Number((item[key])?.toFixed(1)),
                    zValue: zFields && ((zFields[key])?.toFixed(1))
                });
            }
        }                     
    }
    return data;
}

export const filterHSSMarketTrendsLabels = (market , trendsLabels) => {
    if(market === 'HSS'){
        trendsLabels = trendsLabels.map(obj=>{
            if(obj?.id === 'fun' || obj?.name === 'fun') obj.name = 'Approachable';
            else if(obj?.id === 'hip' || obj?.name === 'hip') obj.name = 'Best in Class';
            else if(obj?.id === 'intelligent' || obj?.name === 'intelligent') obj.name = 'Knowledgeable';
            else if(obj?.id === 'stylish' || obj?.name === 'stylish') obj.name = 'Personable';
            else if(obj?.id === 'onlineGaming' || obj?.name === 'onlineGaming') obj.name = 'Search Engine(e.g., Google, Bing)';
            return obj;
        })
    }
    return trendsLabels;
}

export const segmentsList = (segments) => {
    let segmentsList = segments?.reduce((acc, sr) => {
        acc.push(...sr?.segments);
        return acc;
    }, []);  
    return segmentsList;
}

export const getSelectedGlobalBrandMarketsIds = ( selectedBrand) => {
    let selectedURLMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets')?.find(({brandId})=> brandId == selectedBrand?.id)?.markets;
    let useHSSLabels = selectedURLMarkets?.every(ele=> ele?.includes('HSS'))? true : false;
    const sortedMarketAry = selectedBrand?.marketGroups?.reduce((acc, mg) => {
        acc.push(...mg.marketGroupsList);
        return acc;
    }, []).sort((a,b) => a.position - b.position);
    selectedURLMarkets = sortedMarketAry?.filter(ele => selectedURLMarkets?.some(id => id == ele?.identity))?.map(marketObj=>{ return {marketId: marketObj?.marketId,position: marketObj?.position}});
    let marketsList = selectedURLMarkets?.length>0 ? selectedURLMarkets:sortedMarketAry?.map(marketObj=>{ return {marketId: marketObj?.marketId,position: marketObj?.position}});
    let geoIds = marketsList?.map(obj=> obj?.marketId);
    geoIds = [...new Set(geoIds)];
    return {geoIds ,useHSSLabels, marketsList};
}

export const handleSegmentsValidation = async(filters,geoIds) => {
    //get all filter Ids from filter query
    let segmentIds = filters?.find(({categoryId, categoryName})=> categoryId === null && categoryName === 'Segments')?.filterResponse?.map(({filterId})=> filterId);
    if(segmentIds?.length > 0){
        let validateSegmentsList = await ApiData.validateSegments({segmentIds,geoIds});
        //handling validations[detachedFilters,expiredFilters,segmentMarkets] for segments based on the validateapi for CI,Global and Advanced pages
        await filters.forEach(ele=>{
            if(ele.categoryId === null && ele.categoryName === 'Segments'){
                ele.filterResponse.forEach(filterObj=>{
                    let findSegment = validateSegmentsList?.filter(({segmentId})=> segmentId === filterObj?.filterId);
                    if(findSegment?.length > 0){
                        filterObj.detachedFilters = findSegment[0]?.detachedFilters;
                        filterObj.expiredFilters = findSegment[0]?.expiredFilters;
                        filterObj.filterMarket = findSegment[0]?.segmentMarkets;
                        filterObj.isExpired = findSegment[0]?.isFiltersExpired;
                    }
                })
            }
        });
    }
    return filters;
}

export const formatViewsPayload = (payload, queryParamsList) => {

    let {selectedCampaign, selectedBrand, selectedDate, page, selectedGlobalBrand, selectedTab} = queryParamsList;
    let {segments, brands, brand, chartType, screenType, module, group, metric, response, statTest, audienceType} = getURLPathParams();
    
    //get selected segments
    let segmentsList = queryParamsList?.segments?.reduce((acc, sr) => {
        acc.push(...sr.segments);
         return acc
    }, []);
    segmentsList = segmentsList?.filter(({segmentId}) => segments?.some(id => segmentId == id))?.map(({segmentId, order})=> ({segmentId, position: order}));

    //get selected brands
    let urlBrands = brand? [brand] : brands;
    let primaryBrands = queryParamsList?.brands?.filter(ele => urlBrands?.some(id => id == ele.id))?.map(({id, position}) => ({brandId:id, position}));
    primaryBrands = primaryBrands.sort((a, b) => {
        let indexA = urlBrands.findIndex(id => id == a["brandId"]);
        let indexB = urlBrands.findIndex(id => id == b["brandId"]);
        return indexA - indexB;
    });
    let secondaryBrands = [] ;
    if(page === 'advanced'){
        if(selectedTab == 0 || selectedTab === 1){
            secondaryBrands = primaryBrands.filter((obj,ind)=> ind !== 0);
            primaryBrands = [primaryBrands[0]];
        }else if(selectedTab === 2){
            secondaryBrands = primaryBrands[1];
            primaryBrands = [primaryBrands[0]];
        }else{
            primaryBrands = [primaryBrands[0]];
        }
    }

    //get selected markets
    let {marketsList} = getSelectedGlobalBrandMarketsIds(queryParamsList?.brands[selectedBrand[0]]);
    marketsList = page !== 'global' ? [{marketId:selectedCampaign?.marketId, position:selectedCampaign?.position}] : marketsList;    

    //get applied filters and segments
    let cfilters = JSON.parse(getSessionStorage('applyFilters'));
    let selectedMarketOrBrand = page !== 'global'? selectedCampaign:selectedGlobalBrand && selectedGlobalBrand[0];
    const formatFilterList = filtersTransformations.formatSelectedFilters(cfilters, selectedMarketOrBrand);
    let selectedFilters = filtersTransformations.applyMarketFilters(formatFilterList, queryParamsList?.segments, selectedCampaign);
    payload = {
        ...payload,
        details:{
            markets: marketsList,
            brands:{
                primary: primaryBrands,
                secondary: secondaryBrands
            },
            segments: page === 'segments' ? segmentsList : selectedFilters[0]?.segments,
            filters: selectedFilters[0]?.filters,
            screenType:{
                module: initData.getModuleType(page),
                screenType: screenType,
                chartType: chartType,
                group: group,
                metric: metric,
                response: response,
                intervalType: selectedDate?.selectedInterval
            },
            isStatTestingEnabled: !!statTest,
            audienceType: audienceType,
            datePeriod:{
                primaryStartDate: selectedDate.startdate,
                primaryEndDate: selectedDate.enddate,
                secondaryStartDate: selectedDate?.pstartdate, 
                secondaryEndDate: selectedDate?.penddate,
                primaryperiodType: payload?.isDynamicDateRange !== true?initData.getPeriodOptions()?.find(({id})=> id === selectedDate?.selectedPeriod)?.name.replace(/ /g, '_')?.toUpperCase():'DYNAMIC_DATE_RANGE',
                secondaryPeriodType: initData.getPeriodOptions()?.find(({id})=> id === selectedDate?.pselectedPeriod)?.name.replace(/ /g, '_')?.toUpperCase()
            }
        }
    }
    return payload;
}
