import React from "react";
import StyledCustomTooltip from "./Styled.CustomTooltip";

function CustomTooltip({ children, contentList, offset, placement = "bottom", classname = "" }) {
  return (
    <StyledCustomTooltip
      arrow={false}
      interactive={true}
      // trigger="click"
      className={classname}
      zIndex={901}
      placement={placement}
      offset={offset ? offset : []}
      delay={[300, null]}
      content={
        <ul>
          {contentList.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      }
    >
      {children}
    </StyledCustomTooltip>
  );
}

export default CustomTooltip;
