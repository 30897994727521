import React, { Component } from 'react';
import cx from 'classnames';
import toggleBarIcon from '../../../assets/icons/icon_toggle_bar.svg'
import toggleBarBlueIcon from '../../../assets/icons/icon_toggle_bar_blue.svg'
import toggleColumnIcon from '../../../assets/icons/icon_toggle_column.svg'
import toggleColumnBlueIcon from '../../../assets/icons/icon_toggle_column_blue.svg'

import './MiniBar.scss';

export default class MiniBar extends Component {
    render() {
        const {menuExpand, horizontalMenu, onClickHorizontalMenuItem, page, selectedTab} = this.props;
        return (
            <>
                <div className={cx("miniBar", {"menuExpand": menuExpand})} >
                    {(page === 'competitive' || page === 'segments' || page==='global') && selectedTab<4 && <div className="toggleContainer" >
                        <div onClick={horizontalMenu === 'primary'? null : ()=>onClickHorizontalMenuItem('primary')} className="toggleIconContainer rightBorder" >
                            <img src={horizontalMenu === 'primary' ? toggleColumnBlueIcon : toggleColumnIcon} className="horizontalMenuIcon" alt="column"/>
                        </div>
                        <div onClick={horizontalMenu === 'secondary'? null : ()=>onClickHorizontalMenuItem('secondary')} className="toggleIconContainer" >
                            <img src={horizontalMenu === 'secondary' ? toggleBarBlueIcon : toggleBarIcon} className="horizontalMenuIcon" alt="bar"/>
                        </div>
                    </div>}
                    {/* <div className='viewButtonContainer'>
                        <button type="submit" className="button blueButton" style={{height: '35px',border:'none'}}>Save View</button>
                    </div> */}
                </div>
            </>
        )
    }
}            