import styled from "styled-components";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const StyledCustomDateRangeSelector = styled.div`
  position: relative;
  .to-text {
    // padding: 6px 11px;
    font-weight: 500;
  }
  .to-text-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .date-button {
    height: 20px;
    color: #0062ff;
    // border: 1px solid #dfe2ea;
    font-size: 13px;
    // padding: 10px 12px;
    border-radius: 3px;
    margin-right: ${(props) => (props.disabled ? "0px" : "20px")};
    font-weight: 500;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
  .end-date-button {
    margin-right: 0px;
    margin-left: ${(props) => (props.disabled ? "0px" : "20px")};
  }
`;

const StyledTooltip = styled(Tippy)`
  background-color: white;
  color: #344054;
  overflow: hidden;
  max-width: none !important;
  display: flex;
  .date-button {
    color: #0062ff;
    border: 1px solid #dfe2ea;
    font-size: 12px;
    padding: 10px 12px;
    border-radius: 3px;
    margin-right: 20px;
    font-weight: 500;
  }
  .end-date-button {
    margin-left: 20px;
  }
  .tippy-content {
    padding: 0px;
    color: #344054;
  }
  box-shadow: 4px 1px 10px 4px #00000017;
  .custom-range-container {
    display: flex;
  }
  .range-selection-list {
    min-width: 140px;
    border-right: 1px solid #e7eaee;
    ul {
      font-size: 13px;
      color: #535d6e;
      margin: 0px;
      list-style: none;
      padding: 0px;
      li {
        padding: 8px 12px;
        cursor: pointer;
      }
      .range-selection-active-period {
        color: #0062ff;
      }
    }
  }
  .calendar-container {
    min-width: 430px;
    .react-calendar {
      width: fit-content;
      border: none;
      line-height: 0.75em;
      .react-calendar__navigation {
        margin-bottom: 0px;
        text-align: center;
        padding: 12px;
        border-bottom: 1px solid #e7eaee;
        .react-calendar__navigation__label {
          font-size: 12px;
        }
      }
      .react-calendar__viewContainer {
        display: flex;
        .react-calendar__month-view {
          padding: 12px;
          border-right: 1px solid #e7eaee;
        }
      }
      .calendar__month-view__days__day--weekend {
        color: #344054;
      }
      .react-calendar__month-view__days__day {
        color: #344054;
      }
      .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.7;
      }
    }
    .react-calendar__tile--active {
      background-color: #0062ff24;
    }
    .react-calendar__tile--rangeStart {
      color: white !important;
      background-color: #0062ff;
      border-radius: 2px;
    }
    .react-calendar__tile--hasActive,
    .react-calendar__tile--rangeEnd {
      color: white !important;
      background-color: #0062ff;
      border-radius: 2px;
      opacity: 1 !important;
    }
    .react-calendar__month-view__weekdays__weekday {
      text-transform: capitalize;
      abbr {
        text-decoration: none;
      }
    }
    .react-calendar button:enabled:hover {
      border-radius: 2px;
    }
    .react-calendar__tile:disabled {
      background-color: #c1c1c1;
      opacity: 0.5 !important;
    }
  }
  .custom-range-selector-footer {
    border-top: 1px solid #e7eaee;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    align-items: center;
    span {
      margin-right: 5px;
    }
    .date-selection-column {
      padding: 8px 15px;
      border: 1px solid #e7eaee;
      font-size: 13px;
      border-radius: 2px;
    }
    .custom-range-footer-button {
      button {
        font-size: 14px;
        background: white;
        border: 1px solid #e7eaee;
        color: #344054;
        border-radius: 2px;
        padding: 8px 20px;
        cursor: pointer;
      }
      .active-button {
        background: #0062ff;
        color: white;
        margin-left: 10px;
        border: none;
        font-family: "Silka-Bold", sans-serif;
      }
    }
  }
`;

export { StyledCustomDateRangeSelector, StyledTooltip };
