import React, { useState, useEffect, useRef } from "react";
import { ArrowForwardIos } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import {
  addMessage,
  userTyping,
  assistantResponding,
} from "../../../../reducers/actions/actions";
import axios from "axios";

const MessageInput = () => {
  const [messageInput, setMessageInput] = useState("");
  const dispatch = useDispatch();
  const typingTimeoutRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const userData = "";

  // Debounce typing status
  useEffect(() => {
    if (messageInput.length > 0 || isInputFocused) {
      dispatch(userTyping(true));
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        dispatch(userTyping(false));
      }, 3000); // 3 seconds debounce
    } else {
      dispatch(userTyping(false));
    }
    return () => clearTimeout(typingTimeoutRef.current);
  }, [messageInput, isInputFocused, dispatch]);

  const submitMessageButtonClickHandler = async () => {
    console.log("on click handler");
    console.log(messageInput);

    if (messageInput.trim() !== "" && messageInput.length !== 0) {
      // Temporarily moving it out of the stomp client block
      dispatch(
        addMessage({
          type: "You",
          text: messageInput,
        })
      );
      const msginp = messageInput;
      setMessageInput("");

      // Remove focus from the input field
      inputRef.current.blur();

      // Dispatch assistantResponding to show loading
      dispatch(assistantResponding(true));

      try {
        // Send request to Vertex AI through the backend
        const response = await axios.post(
          process.env.REACT_APP_ASSISTANT_URL + "/usermessage/",
          {
            userInputMessage: msginp,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true, // For Django session
          }
        );
        console.log("This is the response message: " + response.data);
        console.log(response);

        // Dispatch addMessage action with the response
        dispatch(
          addMessage({
            type: "Assistant",
            text: response.data.assistant_response,
          })
        );
      } catch (error) {
        console.error("Error sending message:", error);

        // Show a technical issue message from the assistant
        dispatch(
          addMessage({
            type: "Assistant",
            text: "Due to some technical issue, the server is down. Please try again later.",
          })
        );
      } finally {
        // Stop showing loading
        dispatch(assistantResponding(false));
        // Dispatch userTyping to false to stop showing typing
        dispatch(userTyping(false));
        inputRef.current.focus();
      }
    }
  };

  const messageChangeHandler = (e) => {
    setMessageInput(e.target.value);
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleKeyPress = (event) => {
    // Check if the Enter key is pressed
    if (event.key === "Enter" && messageInput.trim() !== "") {
      submitMessageButtonClickHandler();
    }
  };

  return (
    <form className="card-actions" onSubmit={(e) => e.preventDefault()}>
      <div className="card-footer">
        <textarea
          className="textarea1"
          placeholder="Message"
          onKeyPress={handleKeyPress}
          ref={inputRef}
          onChange={messageChangeHandler}
          value={messageInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maxRows={2}
          multiline
          fullWidth
        ></textarea>
        <button
          className="submit-btn"
          type="button"
          onClick={submitMessageButtonClickHandler}
        >
          <ArrowForwardIos />
        </button>
      </div>
    </form>
  );
};

export default MessageInput;
