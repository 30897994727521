import React from 'react';
import { useEffect } from "react";
import useFileDownload from "../hooks/useFileDownloadHook";
import { subscribeToPrivateChannel } from "../../../utils/socketManager";
import { ExportEventType } from "../../../constants/enums/ExportEventType";

function ComponentToUseHookForProccessingEvent({ ablyClient, clientId }) {
  const { processFailedDownloads, processSuccessDownloads } = useFileDownload();
  useEffect(() => {
    if ((ablyClient, clientId)) {
      subscribeToPrivateChannel(
        ablyClient,
        clientId,
        ExportEventType.EXPORT_DELIVERY,
        processSuccessDownloads
      );
      subscribeToPrivateChannel(
        ablyClient,
        clientId,
        ExportEventType.EXPORT_FAILED,
        processFailedDownloads
      );
    }
  }, []);
  return <></>;
}

export default React.memo(ComponentToUseHookForProccessingEvent);
