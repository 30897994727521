import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import HeaderCard from "./HeaderCard";
import EntityListImageView from "./EntityListImageView";
import ViewCardFooter from "./ViewCardFooter/ViewCardFooter";

import {
  getCountryCodeWithMarketName,
  getModuleDisplayName,
  getPeriodDisplayName,
  getScreenTypeDetail,
} from "./utils";
import ViewsActions from "./ViewsActions";
import StyledViewCard from "./Styled.ViewCard";
import { CircleFlag } from "react-circle-flags";

function ViewCard({
  id,
  reportsIdsSelectedOnView,
  view,
  openViewDetailModal,
  openViewDeleteModal,
  handleDefaultView,
  openCloneViewModal,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "320px",
    backgroundColor: "white",
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1,
  };

  const screenTypeDetail = getScreenTypeDetail(view?.screenType);
  return (
    <StyledViewCard
      className="view-card-main-container"
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      style={style}
    >
      <div className="view-card-container">
        <HeaderCard
          title={`${getModuleDisplayName(view.module)}/${
            screenTypeDetail?.displayName
          }`}
          subtitle={view.reportName}
          icon={screenTypeDetail?.icon}
          variant="secondary"
        >
          <ViewsActions
            reportsIdsSelectedOnView={reportsIdsSelectedOnView}
            view={view}
            onClickDetailView={openViewDetailModal}
            onClickDeleteView={openViewDeleteModal}
            handleDefaultView={handleDefaultView}
            onClickCloneView={openCloneViewModal}
          />
        </HeaderCard>
        <HeaderCard
          title={view.viewName}
          subtitle={getPeriodDisplayName(view.periodType)}
          variant="primary"
        />
        <div className="brand-and-market-list">
          <EntityListImageView
            list={view.brands?.map((brand) => ({
              id: brand.id,
              image: brand.logo,
              name: brand.name,
            }))}
            keyToUseForTooltip={"name"}
          />
          <EntityListImageView
            list={view.markets?.map((market) => ({
              id: market.id,
              image: (
                <CircleFlag
                  countryCode={getCountryCodeWithMarketName(market)}
                  height={32}
                />
              ),
              name: market.displayName,
            }))}
            keyToUseForTooltip={"name"}
          />
        </div>
      </div>
      <ViewCardFooter
        view={view}
        filters={view.filters}
        segments={view.segments}
      />
    </StyledViewCard>
  );
}

export default ViewCard;
