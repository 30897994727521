import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const Toaster = ({ onClose, isOpen, variant = "success", children }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right", zIndex: 9999 }} // Positioning
    >
      <Alert
        onClose={onClose}
        severity={variant}
        variant="filled"
        style={{ width: "100%", top: "120px", position: "relative" }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
