import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import HeaderCard from "../ViewCard/HeaderCard";
import { DateTime } from "luxon";
import {
  getCountryCodeWithMarketName,
  getModuleDisplayName,
  getPeriodDisplayName,
  getScreenTypeDetail,
  getSelectViewURL,
} from "../ViewCard/utils";
import {
  LIGHTEST_BORDER_GRAY_COLOR,
  SECONDARY_BORDER_GRAY_COLOR,
} from "../ViewCard/utils/constant";
import EntityListImageView from "../ViewCard/EntityListImageView";
import { CircleFlag } from "react-circle-flags";
import AudienceTypeIcon from "../../../assets/icons/icon_views_audience_type.svg";
import StatTestingIcon from "../../../assets/icons/icon_views_stat_testing.svg";
import PeriodTypeIcon from "../../../assets/icons/icon_views_periodtype.svg";
import MarketsIcon from "../../../assets/icons/icon_views_markets.svg";
import CloseIcon from "../../../assets/icons/icon_views_close.svg";
import BrandsIcon from "../../../assets/icons/icon_views_brands.svg";
import FilterContainerIcon from "../../../assets/icons/icon_views_filter_parent_container.svg";
import FilterIcon from "../../../assets/icons/icon_views_filter.svg";
import FilterSegmentTypeIcon from "../../../assets/icons/icon_views_filter_segment.svg";
import EditIcon from "../../../assets/icons/icon_views_edit.svg";
import ButtonWithDropdownOption from "../../ButtonWithDropdownOption";
import { FaAngleDown } from "react-icons/fa6";
import CustomDateRangeSelector from "../../widgets/OptionFilters/CustomDateRangeSelector";
import FilterExpiredIcon from "../../../assets/icons/icon_views_filter_expired.svg";
import {
  DATE_PERIOD_KEY_MAPPING,
  DATE_PERIOD_KEY_MAPPING_REVERSE,
  DISPLAY_DATE_FORMAT,
  getDatesBasedonSelectionFromBackend,
} from "../../widgets/OptionFilters/CustomDateRangeSelector/utils";
import ApiData from "../../../utils/Api";
import Skeleton from "../Skeleton";

const StyledViewDetailModalContent = styled.div`
  .view-detail-modal-header {
    padding: 24px;
    border-bottom: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
  }
  .view-detail-card-content {
    & > div {
      padding: 18px 24px;
      border-bottom: 1px solid ${LIGHTEST_BORDER_GRAY_COLOR};
    }
  }
  .view-detail-brand-market-list {
    margin-top: 8px;
  }
  .view-detail-card-content {
    height: calc(100vh - 182px);
    overflow: auto;
    & > div {
      width: auto;
    }
  }
  .header-card-container {
    gap: 18px;
  }
  .view-detail-card-footer {
    height: 80px;
    padding: 0px 24px;
    display: flex;
    gap: 24px;
    align-items: center;
    border-top: 1px solid ${SECONDARY_BORDER_GRAY_COLOR};
    button {
      width: 50%;
      font-family: "Inter", sans-serif;
      padding: 11px;
      font-weight: 500;
      font-size: 13px;
      border-radius: 3px;
      cursor: pointer;
      letter-spacing: 0.3px;
      &:active {
        opacity: 0.9;
      }
      &:focus-visible {
        outline: none;
      }
    }
    .view-detail-delete-button {
      border: 1px solid #d42620;
      background: white;
      color: #d42620;
      &:active {
        opacity: 0.8;
      }
    }
    .view-detail-select-button {
      background: #4aa578;
      color: white;
      border: 1px solid #4aa578;
      &:active {
        opacity: 1;
        background: #4aa578e8;
      }
      .select-view-angle-down {
        position: relative;
        top: 3.5px;
        left: 2px;
      }
    }
  }
  .views-edit-icon {
    cursor: pointer;
  }
  .views-detail-close-button {
    cursor: pointer;
  }
`;

const StyledViewDetailFilterSection = styled.div`
  display: flex;
  flex-direction: column;
  .filter-segment-container,
  .filter-segment-category-container {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .filter-segment-category-container {
    gap: 8px;
  }
  .header-card-title {
    font-size: 14px;
    font-weight: 500;
  }
  .header-card-subtitle {
    font-size: 12px;
    font-weight: 400;
  }
  img {
    height: 16px !important;
    width: 16px !important;
  }
  .filter-segment-dot-in-views {
    height: 4px;
    width: 4px;
    display: inline-block;
    background: #475467;
    border-radius: 50%;
    position: relative;
  }

  .header-card-container {
    padding: 12px 0px;
    border-bottom: 1px solid ${LIGHTEST_BORDER_GRAY_COLOR};
  }
  & > div:last-child .header-card-container {
    border: none;
  }
  .views-filter-heading {
    display: flex;
    gap: 10px;
    align-items: center;
    .views-filter-expired-label {
      display: flex;
      color: #b42318;
      font-size: 12px;
      border-radius: 3px;
      padding: 1px 4px;
      border: 1px solid #fecdca;
      background-color: #fef3f2;
      gap: 5px;
      align-items: center;
    }
  }
`;
function ViewDetailModalContent({ view, onClose = () => {} }) {
  const screenTypeDetail = getScreenTypeDetail(view?.screenType);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDetailData, setViewDetailData] = useState({});

  const [datePeriodSelected, setDatePeriodSelected] = useState([
    DateTime.now().minus({ days: 1 }).toJSDate(),
    DateTime.now().minus({ days: 1 }).toJSDate(),
    "L180D",
  ]);

  useEffect(() => {
    if (view?.periodType) {
      if (view.periodType !== "CUSTOM") {
        const range = getDatesBasedonSelectionFromBackend(view.periodType);
        setDatePeriodSelected([
          range[0],
          range[1],
          DATE_PERIOD_KEY_MAPPING_REVERSE[view.periodType] || "L180D",
        ]);
      } else {
        setDatePeriodSelected([
          DateTime.fromISO(view?.startDate).toJSDate(),
          DateTime.fromISO(view?.endDate).toJSDate(),
          "CUSTOM",
        ]);
      }
    }
    setIsLoading(true);
    try {
      ApiData.getViewDetail(view.viewId).then((res) => {
        setViewDetailData(res);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, [view]);

  const filterSegmentCategoryAndStatusMapping = useMemo(() => {
    const updatedMapping = {
      filters: {},
      segments: {},
    };
    const _ = viewDetailData?.filters?.forEach((filter) => {
      updatedMapping.filters[filter.filterId] = {
        id: filter.filterId,
        categoryName: filter.categoryName,
        categoryId: filter.categoryId,
        isExpired: filter.isFilterExpired,
      };
    });
    const __ = viewDetailData?.segments?.forEach((segment) => {
      updatedMapping.segments[segment.segmentId] = {
        id: segment.segmentId,
        categoryName: segment.categoryName,
        categoryId: segment.categoryId,
        isExpired: segment.isFilterExpired,
      };
    });
    return updatedMapping;
  }, [viewDetailData]);

  function handleSelectView() {
    const url = getSelectViewURL(view.module, view.screenType, view.viewUrl);
    window.open(url, "_blank");
  }

  return (
    <StyledViewDetailModalContent>
      <div className="view-detail-modal-header">
        <HeaderCard
          title={view.viewName}
          subtitle={`${getModuleDisplayName(view.module)}/${
            screenTypeDetail?.displayName
          }`}
          icon={screenTypeDetail.icon}
          variant="primaryLarge"
        >
          <img
            className="views-detail-close-button"
            src={CloseIcon}
            onClick={onClose}
            alt="Modal close"
          />
        </HeaderCard>
      </div>
      <div className="view-detail-card-content">
        {/* <Skeleton /> */}
        <HeaderCard
          title="Audience Type"
          subtitle={
            isLoading ? (
              <Skeleton height={"12px"} width={"180px"} />
            ) : (
              viewDetailData.audienceType
            )
          }
          icon={AudienceTypeIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        <HeaderCard
          title="Stat Testing"
          subtitle={
            isLoading ? (
              <Skeleton height="12px" width="180px" />
            ) : viewDetailData.statTesting ? (
              viewDetailData.statTesting
            ) : (
              "false"
            )
          }
          icon={StatTestingIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        <HeaderCard
          title="Period Type"
          subtitle={
            // <CustomDateRangeSelector
            //   handleApply={(startDate, endDate, period) => {
            //     setDatePeriodSelected([startDate, endDate, period]);
            //   }}
            //   customPeriodOptionToFilter={[1, 2]}
            //   datePeriod={datePeriodSelected[2]}
            //   customTrigger={
            //     <div style={{ cursor: "pointer" }}>
            //       {datePeriodSelected[2] === "CUSTOM"
            //         ? `${DateTime.fromJSDate(datePeriodSelected[0]).toFormat(
            //             DISPLAY_DATE_FORMAT
            //           )} to ${DateTime.fromJSDate(
            //             datePeriodSelected[1]
            //           ).toFormat(DISPLAY_DATE_FORMAT)}`
            //         : getPeriodDisplayName(
            //             DATE_PERIOD_KEY_MAPPING[datePeriodSelected[2]]
            //           )}
            //     </div>
            //   }
            //   value={[datePeriodSelected[0], datePeriodSelected[1]]}
            // />
            <div>
              {datePeriodSelected[2] === "CUSTOM"
                ? `${DateTime.fromJSDate(datePeriodSelected[0]).toFormat(
                    DISPLAY_DATE_FORMAT
                  )} to ${DateTime.fromJSDate(datePeriodSelected[1]).toFormat(
                    DISPLAY_DATE_FORMAT
                  )}`
                : getPeriodDisplayName(
                    DATE_PERIOD_KEY_MAPPING[datePeriodSelected[2]]
                  )}
            </div>
          }
          icon={PeriodTypeIcon}
          variant="primaryInvertedWithoutIconOutline"
        >
          {/* <img
            className="views-edit-icon"
            src={EditIcon}
            alt="edit_period_type"
          /> */}
        </HeaderCard>
        <HeaderCard
          title="Market(s)"
          subtitle={
            <div className="view-detail-brand-market-list">
              <EntityListImageView
                list={view.markets?.map((market) => ({
                  id: market.id,
                  image: (
                    <CircleFlag
                      countryCode={getCountryCodeWithMarketName(market)}
                      height={32}
                    />
                  ),
                  name: market.displayName,
                }))}
                keyToUseForTooltip={"name"}
              />
            </div>
          }
          icon={MarketsIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        <HeaderCard
          title="Brand(s)"
          subtitle={
            <div className="view-detail-brand-market-list">
              <EntityListImageView
                list={view.brands?.map((brand) => ({
                  id: brand.id,
                  image: brand.logo,
                  name: brand.name,
                }))}
                keyToUseForTooltip={"name"}
              />
            </div>
          }
          icon={BrandsIcon}
          variant="primaryInvertedWithoutIconOutline"
        />
        {(view?.filters?.length > 0 || view?.segments?.length > 0) && (
          <HeaderCard
            title="Filters"
            subtitle={
              <StyledViewDetailFilterSection>
                {view?.filters?.map((filter) => (
                  <HeaderCard
                    key={filter?.filterId}
                    title={
                      <div className="views-filter-heading">
                        {filter?.filterOptions
                          ?.map(
                            (filterOption) => filterOption?.filterOptionName
                          )
                          ?.join(", ")}
                        {filterSegmentCategoryAndStatusMapping?.filters[
                          filter?.filterId
                        ]?.isExpired && (
                          <span className="views-filter-expired-label">
                            <img src={FilterExpiredIcon} alt="Filter Expired" />
                            Filter Expired
                          </span>
                        )}
                      </div>
                    }
                    subtitle={
                      <>
                        <span className="filter-segment-container">
                          {filter?.filterName}
                          <span className="filter-segment-category-container">
                            <span className="filter-segment-dot-in-views" />
                            {isLoading ? (
                              <Skeleton height={"10px"} width={"80px"} />
                            ) : (
                              filterSegmentCategoryAndStatusMapping?.filters[
                                filter?.filterId
                              ]?.categoryName
                            )}
                          </span>
                          <span className="filter-segment-category-container">
                            <span className="filter-segment-dot-in-views" />
                            Filter
                          </span>
                        </span>
                      </>
                    }
                    icon={FilterIcon}
                    variant="primary"
                  />
                ))}
                {view?.segments?.map((segment) => (
                  <HeaderCard
                    key={segment?.segmentId}
                    title={segment?.segmentName}
                    subtitle={
                      <span className="filter-segment-container">
                        <span className="filter-segment-category-container">
                          <span className="filter-segment-dot-in-views" />
                          {isLoading ? (
                            <Skeleton height={"10px"} width={"80px"} />
                          ) : (
                            filterSegmentCategoryAndStatusMapping?.segments[
                              segment?.segmentId
                            ]?.categoryName
                          )}
                        </span>
                        <span className="filter-segment-category-container">
                          <span className="filter-segment-dot-in-views" />
                          Segment
                        </span>
                      </span>
                    }
                    icon={FilterSegmentTypeIcon}
                    variant="primary"
                  />
                ))}
              </StyledViewDetailFilterSection>
            }
            icon={FilterContainerIcon}
            variant="primaryInvertedWithoutIconOutline"
          />
        )}
      </div>
      <div className="view-detail-card-footer">
        <button className="view-detail-delete-button">Delete view</button>
        <button
          className="view-detail-select-button"
          onClick={handleSelectView}
        >
          Select view{" "}
          {/* <FaAngleDown size={14} className="select-view-angle-down" /> */}
        </button>
      </div>
    </StyledViewDetailModalContent>
  );
}

export default ViewDetailModalContent;
