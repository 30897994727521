const PRIMARY_BORDER_GRAY_COLOR = "#E4E7EC";
const SECONDARY_BORDER_GRAY_COLOR = "#EAECF0";
const TEXT_BOLD = "#1D2739";
const TEXT_NORMAL = "#667185";
const TEXT_LIGHT = "#98A2B3";
const TEXT_SEMIBOLD = "#71767D";
const TEXT_BLACK_LIGHT = "#475467";
const LIGHTEST_BORDER_GRAY_COLOR = "#F7F9FC";

export {
  PRIMARY_BORDER_GRAY_COLOR,
  SECONDARY_BORDER_GRAY_COLOR,
  TEXT_BOLD,
  TEXT_NORMAL,
  TEXT_SEMIBOLD,
  TEXT_LIGHT,
  TEXT_BLACK_LIGHT,
  LIGHTEST_BORDER_GRAY_COLOR,
};
