import React, { isValidElement } from "react";
import StyledEntityListImageView from "./Styled.EntityListImageView";
import ViewCardTooltip from "../ViewCardTooltip";

function EntityListImageView({
  list = [],
  maximumEntriesToShow = 5,
  keyToUseForTooltip,
}) {
  
  return (
    <StyledEntityListImageView>
      {list.slice(0, maximumEntriesToShow).map((item) => (
        <li key={item.id}>
          {isValidElement(item.image) ? item.image :<img src={item.image} alt={item.id} />}
        </li>
      ))}
      {list.length > 5 && (
        <ViewCardTooltip
          contentList={list.map((item) => item[keyToUseForTooltip])}
        >
          <li key="+ more">
            <span className="more-count">+{list.slice(5).length}</span>
          </li>
        </ViewCardTooltip>
      )}
    </StyledEntityListImageView>
  );
}

export default EntityListImageView;
