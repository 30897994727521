import React, { useEffect, useState } from "react";
import Select from "react-select";

const FormSelect = ({
  options,
  placeholder = "Select",
  isDisabled,
  selectedOption,
  isClearable,
  onChange,
  isViewPage,
}) => {
  const [selectedValue, setSelectedValue] = useState(selectedOption);

  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [selectedOption]);

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "0",
      backgroundColor: state.isDisabled ? "#F3F4F7" : "#FFFFFF",
      borderColor: state.isDisabled ? "#F3F4F7" : "#D9D9D9",
      height: isViewPage ? "45px" : "56px",
      minHeight: isViewPage ? "45px" : "56px",
      paddingLeft: "5px", // Revise
      boxShadow: isViewPage ? "box-shadow: 0px 1px 2px 0px #1018280D" : "none",
      borderRadius: isViewPage ? "3px" : "0px",
    }),
    singleValue: (base, state) => {
      const transition = "opacity 300ms";

      return { ...base, transition, color: "#000000" };
    },
    menu: (base) => ({ ...base, zIndex: 9 }),
  };

  return (
    <Select
      placeholder={placeholder}
      isClearable={isClearable}
      options={options}
      isDisabled={isDisabled}
      styles={customStyles}
      value={selectedValue}
      onChange={handleSelectChange}
    />
  );
};

export default FormSelect;
