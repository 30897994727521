import styled from "styled-components";
import { PRIMARY_BORDER_GRAY_COLOR } from "./utils/constant";

const StyledViewCard = styled.div`
  width: 320px;
  border: 1px solid ${PRIMARY_BORDER_GRAY_COLOR};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 3px -2px #0000000f;
  gap: 12px;
  background: white;
  &:hover .view-card-drag-icon {
    opacity: 1;
  }
  .view-card-container {
    padding: 14px;
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    gap: 16px;
  }
  .brand-and-market-list {
    display: flex;
    gap: 24px;
  }
  * {
    font-family: "Inter", serif;
  }
`;

export default StyledViewCard;