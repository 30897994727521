import { getLocalStorage, getSessionStorage, setSessionStorage } from "../utils/storageHelpers";
import {updateURLParams } from "./URLParamsValidations";

export const filterGroupTypes = {
	OPTION: 'OPTION',
	DATE_RANGE: 'DATE_RANGE',
	FREE_RANGE: 'FREE_RANGE',
	FILTER_GROUP: 'FILTERGROUP',
};


class FiltersTransformations {

	createFilterOptionKey(name, value) {
		return`${name}__${value?.toLowerCase()}`.replace(/[^a-zA-Z0-9]/g,'_');
	}

	transformTypeFromFilterGroupIntoOption(filter) {
		let {
			id,
			name,
			filterOptions,
		} = filter;

		filterOptions = filterOptions.reduce((acc, { id, displayValue}) => [
			...acc,
			{
				id,
				type: 'custom',
				value: displayValue,
			},
		], []);
		return ({
			id,
			name,
			type: filterGroupTypes.OPTION,
			filterOptions,
		});
	}

	checker = (arr, target) => target.every(v => arr.includes(v));

	applyMarketFilters(selectedFilters, segmentsList, selectedMarketOrBrand) {
    	let filters = JSON.parse(getLocalStorage('filters'));
		let selectedMarketList = selectedMarketOrBrand?.marketName ? [selectedMarketOrBrand?.marketName]:this.getGlobalBrandMarketFilters(selectedMarketOrBrand);
		let marketAppliedFilters=[{filters:[],segments:[]}]
        selectedFilters?.length> 0 && selectedFilters.forEach(value=>{
            filters.forEach(obj=>{
                obj.filterResponse.forEach(val=>{ 
					let index = marketAppliedFilters[0].filters.findIndex(obj=> obj.filterId === value.filterId);
                    if(value.filterId === val.filterId && value.filterType !== 'SEGMENT' && index < 0){
                        marketAppliedFilters[0].filters.push(value);
                    }else if(value.filterId === val.filterId && value.filterType === 'SEGMENT' && value.labels[0] === val.filterName ){
						segmentsList.forEach(ele => {
							let findselectedSegment = ele.segments?.find(({segmentId, segmentMarkets})=> segmentId == value.filterId && selectedMarketList.every((val)=> segmentMarkets.indexOf(val) >= 0));
							if(findselectedSegment){
								let selectedSegmentObj = {}
								selectedSegmentObj.segmentId = findselectedSegment.segmentId;
								selectedSegmentObj.segmentName = findselectedSegment.segmentName;
								selectedSegmentObj.filters = this.formatSelectedFilters([findselectedSegment]);
								selectedSegmentObj.position = findselectedSegment.order;
								marketAppliedFilters[0].segments.push(selectedSegmentObj);
							}
						});
					}
                }) 
            })
        })
		return marketAppliedFilters;
	}

	formatSegmentsFilters = (selectedFiltersList) =>{
		let formatterFilterList = [];
		selectedFiltersList?.length>0 && selectedFiltersList.forEach(({filters})=>{ 
			filters.forEach(({filterId,filterName,filterOptions})=>{
							if(filterId === 8){
								formatterFilterList.push({filterId:filterId,filterOptions:[filterOptions[0].filterOptionId,filterOptions[1].filterOptionId],labels:[`Age : ${filterOptions[0].filterOptionId}-${filterOptions[1].filterOptionId}`]})
							}else{
								filterOptions.length>0 && filterOptions.forEach(({filterOptionId,filterOptionName})=>{
									let index = formatterFilterList.findIndex(obj=> obj.filterId === filterId);
									if(index > -1){
										formatterFilterList[index].filterOptions.push(filterOptionId);
										formatterFilterList[index].labels.push(filterOptionName);
									}else{
										formatterFilterList.push({filterId:filterId,filterOptions:[filterOptionId],labels:[filterOptionName?filterOptionName:filterName]})
									}
								})
							}
			})
		})
		return formatterFilterList;
	}

	
	formatSelectedFilters = (selectedFiltersList, selectedMarketOrBrand) =>{
		let selectedMarketList = selectedMarketOrBrand?.marketName ? [selectedMarketOrBrand?.marketName]:this.getGlobalBrandMarketFilters(selectedMarketOrBrand);
		let filtersData = JSON.parse(getLocalStorage('filters'));
		let formatterFilterList = [];
		selectedFiltersList?.length>0 && selectedFiltersList.forEach(({filters})=>{ 
			filters.forEach(({filterId,filterName,filterOptions,isExpired,filterType})=>{
				filtersData && filtersData.forEach(({filterResponse})=>{
					filterResponse.forEach(ele=>{
						if(filterId === ele.filterId && !isExpired ){   
							if(filterId === 8){
								formatterFilterList.push({filterId:filterId,filterOptions:[filterOptions[0].filterOptionId,filterOptions[1].filterOptionId],labels:[`Age : ${filterOptions[0].filterOptionId}-${filterOptions[1].filterOptionId}`]})
							}else if((ele.filterType === "SEGMENT" && selectedMarketList?.every((val)=> ele.filterMarket?.indexOf(val) >= 0)&& filterName === ele.filterName) || (filterType !== "SEGMENT" && filterName === ele.filterName)){
								filterOptions.length>0 && filterOptions.forEach(({filterOptionId,filterOptionName})=>{
									let findFilterOptionId = ele?.filterOptions?.filter(val=> val.filterOptionId === filterOptionId);
									
									if(findFilterOptionId?.length > 0 || filterType === 'SEGMENT'){
										let index = formatterFilterList.findIndex(obj=> obj.filterId === filterId && (obj.filterType === filterType || !filterType));
										if(index > -1 && filterType !== 'SEGMENT'){
											formatterFilterList[index].filterOptions.push(filterOptionId);
											formatterFilterList[index].labels.push(filterOptionName);
										}else{
											formatterFilterList.push({filterId:filterId,filterOptions:[filterOptionId],labels:[filterOptionName?filterOptionName:filterName],filterType :ele.filterType})
										}
									}
								})
							}
						}
					})
				})
			})
		})
		return formatterFilterList;
	}

	createMissingFilters(filterGroups, notFoundFilterIds) {
		let notFoundFilterIdsClone = [...notFoundFilterIds];

		const filters = filterGroups.reduce((acc, filterGroup) => {
			const { id, filterGroupOptions } = filterGroup;

			filterGroupOptions.forEach((filterGroupOption) => {
				const {
					id: optionId,
					filters,
				} = filterGroupOption;

				filters.forEach((filter) => {
					const { id: filterId } = filter;

					if (notFoundFilterIdsClone.includes(filterId)) {
						const filter = {
							filterId: id,
							filterOptions: [optionId],
						};
						const stringifiedFilter = JSON.stringify(filter);

						if (acc.includes(stringifiedFilter)) {
							notFoundFilterIdsClone = notFoundFilterIdsClone.filter((id) => id !== filterId);
						} else {
							acc.push(stringifiedFilter);
						}
					}
				});
			});

			return acc;
		}, []);

		return filters.map((filter) => JSON.parse(filter));
	}

	formatFilters(filters, notFoundFilterIds, missingFilters) {
		/*
			exclude FILTER_GROUP ones, replace with created missingFilters
		 */
		return [...filters, ...missingFilters].filter(({ filterId }) => !notFoundFilterIds.includes(filterId));
	}

	formatFilterList(allFilters) {
		let filters=[]
		if(allFilters?.length>0){
			allFilters.map(catObj=>filters?.push(...catObj.filterResponse))
		}
		return filters;
	}

	parseSegmentsFilters(segments, allFilters) {
        allFilters = this.formatFilterList(allFilters);
        let allSegments = segments?.reduce((acc, segment) => {
            acc.push({
                segmentId:segment.segmentId,
                segmentName:segment.segmentName,
				isActive:segment.isActive,
                filters: this.formatSegmentsFilters([segment])
            })  
            return acc;
        },[]);
        return allSegments;
    }

	parse2x2Segments(segments, allFilters) {
		allFilters = this.formatFilterList(allFilters);
		return segments.reduce((acc, segment) => {
			const {
				data: { filters },
			} = segment;

			if (!filters.length) {
				return [...acc, segment];
			}

			const parsedAggregatedFilters = filters.reduce((acc, filter) => {
				const { filterId, filterOptions } = filter;
				const targetFilter = allFilters.find(({ id }) => id === filterId);
				const { type, filterGroupOptions } = targetFilter;

				if (type !== filterGroupTypes.FILTER_GROUP) {
					return [...acc, filter];
				}

				const parsedFilters = filterOptions.reduce((acc, filterProperty) => {
					const filterGroupOption = filterGroupOptions.find(({ id }) => id === filterProperty);
					const transformedFilters = filterGroupOption.filters.map(({ id, filterOptions }) => {
						const filterProperties = filterOptions.map(({ id }) => id);

						return ({
							filterId: id,
							filterOptions:filterProperties,
						});
					});

					return [...acc, ...transformedFilters];
				}, []);

				return [...acc, ...parsedFilters];
			}, []);

			return [
				...acc,
				{
					...segment,
					data: {
						...segment.data,
						filters: parsedAggregatedFilters,
					}
				}
			];
		}, []);
	}

	formatSelectedSegmentsFilters(selectedSegmentFilters, action) {
    	let filters = JSON.parse(getLocalStorage('filters')).filter(({categoryId})=> categoryId);
	    let filtersList = [];
		selectedSegmentFilters && selectedSegmentFilters.forEach(obj=>{
			let findIndex = filtersList?.findIndex(({categoryId})=>categoryId === obj.categoryId)
			let findCatObj = filters?.find((catobj)=> {if(catobj.filterResponse.filter(({filterId})=>filterId === obj.filterId).length> 0)return catobj});
			let selectedFilter = findCatObj?.filterResponse?.find(({filterId})=> filterId === obj.filterId)
			if(action === 'edit' || action === 'copy') obj.isExpired = selectedFilter?.isExpired;
			if(findIndex > -1){
				filtersList[findIndex].filters.push(obj);
			}else if(action === 'delete' || (findCatObj !== undefined && action !== 'delete')){
				filtersList.push({
					categoryId: obj.categoryId,
					categoryName: obj.categoryName,
					expanded: true,
					filters:[obj]
				})
			}
		})
		return filtersList;
	}

	getGlobalBrandMarketFilters(selectedGlobalBrand){
		let globalBrands = JSON.parse(getLocalStorage("globalBrands"))
		let marketList = globalBrands?.filter(val=> Number(val.id) === globalBrands[selectedGlobalBrand]?.id)[0]?.marketGroups;
		if(marketList){
			let marketsLists = [];
			marketList.forEach(({marketGroupsList})=> marketGroupsList.map(({marketName})=> marketsLists.push(marketName) ))
			return marketsLists;
		}
	}

	checkFilterPresentInMarket (page, filterMarkets, selectedMarket,selectedGlobalBrand ){
		if(page !== 'global'){
			return filterMarkets?.length > 0 && filterMarkets?.includes(selectedMarket);
		}else{
			let marketsLists = this.getGlobalBrandMarketFilters(selectedGlobalBrand);
			return marketsLists?.every((val)=> filterMarkets.indexOf(val) >= 0)
		}
	}

	getFilterObject (categoryObj) {
		return {
			filterExpiresAt: null,
			filterGroup: categoryObj.categoryName,
			filterGroupOrder: categoryObj.categoryOrder,
			filterId:  categoryObj.segments[0]?.segmentId,
			filterMarket: categoryObj.segments[0]?.segmentMarkets,
			filterName: categoryObj.segments[0]?.segmentName,
			filterOptions: null,
			filterOrder:  categoryObj.segments[0]?.order,
			filterType: "SEGMENT",
			isExpired: categoryObj.segments[0]?.expiredFilters?.length>0 ? true:false,
			module: categoryObj.segments[0]?.module,
			userGroups: []
		}
	}

	applyFilters (data, page, action) {
        let cfilters = JSON.parse(getSessionStorage('applyFilters'));
        let segmentId = Object.entries(data)?.length? data.segments[0]?.segmentId:data
		if(page !== 'segments'){
			let findCategoryIndex = cfilters?.findIndex(ele=>ele.categoryId === null);
			let findSegmentIndex = findCategoryIndex > -1 && cfilters[findCategoryIndex]?.filters?.findIndex(({filterId})=>filterId == segmentId)
			if(action === 'edit'){
				 cfilters.forEach(({filters,categoryName},index)=>{
					if( categoryName !== data.categoryName){
						filters.forEach(({filterId},ind)=>{
							if(filterId == data.segments[0].segmentId){
								cfilters[index].filters.splice(ind,1);
								cfilters[index].filters?.length === 0 && (cfilters.splice(index,1)); 
							}
						})
					}else{
						let appliedEditSegmentIndex = filters.findIndex(({filterId})=>filterId == data.segments[0].segmentId)
						cfilters[index].filters[appliedEditSegmentIndex] = {
							filterId: data.segments[0].segmentId,
							filterName: data.segments[0].segmentName,
							filterType: 'SEGMENT',
							filterOptions: [{}]
						}
					}
				})

			}else if(action === 'delete'){
                findCategoryIndex > -1 && cfilters[findCategoryIndex].filters.splice(findSegmentIndex,1)
			}else{
               cfilters = []
			   cfilters.push({
                    categoryId: null,
                    categoryName: data.categoryName, 
                    type: 'SEGMENT',
                    filters:[{
                        filterId: data.segments[0]?.segmentId,
                        filterName: data.segments[0]?.segmentName,
                        filterType: 'SEGMENT',
                        filterOptions: [{}]
                    }],
                    expanded: true
                });
			}
		}
		let segments=[],filters=[];
		cfilters?.length > 0 && cfilters.forEach(({filters,categoryId})=>{
			filters?.length > 0 && filters.forEach(({filterType,filterId,filterOptions})=>{
				if(filterType === 'SEGMENT') segments.push(filterId);
				else filterOptions?.length>0 && filterOptions.forEach(({filterOptionId})=>filters.push(filterOptionId));
			})
		})
		updateURLParams({segments,filters});
		setSessionStorage('applyFilters',JSON.stringify(cfilters))
	}

	formatURLApplyFilters (segmentIds , filterIds, obj){
		let filtersList = JSON.parse(getLocalStorage('filters'));
		let expiredFilters = [], validSegments = [], validFilters = [];
		let {page, selectedMarketObj, selectedGlobalBrandIndex} = obj;
		let applyFiltersData = JSON.parse(getSessionStorage('applyFilters'))?.length>0?JSON.parse(getSessionStorage('applyFilters')):[];
		filtersList?.length > 0 && filtersList.forEach(categoryObj=>{
         	categoryObj.filterResponse.forEach(filterObj=>{
				!categoryObj.categoryId && segmentIds.forEach(segmentId=>{
					//check if segment exist in the filters list
					if(filterObj.filterId == segmentId){
						let categoryIndex = applyFiltersData.findIndex(({categoryName})=> categoryName === filterObj.filterGroup);
						let filterIndex = applyFiltersData[categoryIndex]?.filters?.findIndex(({filterId})=>filterId === filterObj.filterId)
						let {detachedFilters} = filterObj;
						const filtersPresentInMarket = this.checkFilterPresentInMarket(page, filterObj?.filterMarket, selectedMarketObj?.marketName, selectedGlobalBrandIndex);
						detachedFilters = Array.isArray(detachedFilters)?detachedFilters?.length > 0 : detachedFilters;
						if(filtersPresentInMarket && !detachedFilters){
							validSegments.push(segmentId);
							if(categoryIndex < 0){
								applyFiltersData.push({
									categoryId: null,
									categoryName: filterObj.filterGroup,
									expanded: true,
									filters:[{
										filterId: filterObj.filterId,
										filterName: filterObj.filterName,
										filterType: filterObj.filterType,
										filterOptions:[{}]
									}]
								})
							}else if(categoryIndex >= 0 && filterIndex < 0){
								applyFiltersData[categoryIndex].filters.push({
									filterId: filterObj.filterId,
									filterName: filterObj.filterName,
									filterType: filterObj.filterType,
									filterOptions: [{}]
								})
							}
						}
					}
				})
				
				categoryObj.categoryId && filterIds.forEach(optionId=>{
					//check applied filteroptionId exist in filterOptions
					let findfilterOptionObject = filterObj.filterOptions.find(({filterOptionId})=> optionId == filterOptionId)
					if(isNaN(parseInt(optionId))){
						let findRangeFilter = validFilters.findIndex(val=> val?.includes('Age'))
						if(optionId && optionId?.includes('Age')){
							findRangeFilter < 0 && validFilters.push(optionId);
							const categoryIndex = applyFiltersData.findIndex(cf => { return cf.categoryId === 1 });
							const filterIndex = applyFiltersData[categoryIndex]?.filters?.findIndex(cf => { return cf.filterId === 8});
					        if(categoryIndex > -1 && filterIndex > -1){
                                applyFiltersData[categoryIndex].filters[filterIndex].filterOptions =[{filterOptionId:optionId.split('_')[1].split('-')[0] , filterOptionName: null},{filterOptionId: optionId.split('_')[1].split('-')[1], filterOptionName: null}]
							}else if(categoryIndex> -1 && filterIndex === -1){
								applyFiltersData[categoryIndex].filters.push(
									[{
										filterId: 8,
										filterName: 'Range',
										filterOptions: [{filterOptionId:optionId.split(': ')[1].split('-')[0] , filterOptionName: null},{filterOptionId: optionId.split(': ')[1].split('-')[1], filterOptionName: null}],
										filterType: 'Age Range'
									}]
								)
							}else{
								applyFiltersData.push({
									categoryId: 1,
									categoryName: 'Age', 
									type: 'Age Range',
									filters:[{
										filterId: 8,
										filterName: 'Range',
										filterOptions: [{filterOptionId:optionId?.split(': ')[1]?.split('-')[0] , filterOptionName: null},{filterOptionId: optionId?.split(': ')[1]?.split('-')[1], filterOptionName: null}],
										filterType: 'Age Range'
									}],
								})
							}
				
						}
					}else if(!filterObj.isExpired){
						if(findfilterOptionObject){
							validFilters.push(optionId);
							let categoryIndex = applyFiltersData.findIndex(({categoryId})=> categoryId === categoryObj.categoryId);
							let filterIndex = applyFiltersData[categoryIndex]?.filters?.findIndex(({filterId})=>filterId === filterObj.filterId);
							let findFilterOptionIndex = applyFiltersData[categoryIndex]?.filters[filterIndex]?.filterOptions.findIndex(({filterOptionId})=> filterOptionId == optionId);
							if(categoryIndex < 0){
								applyFiltersData.push({
									categoryId:categoryObj.categoryId,
									categoryName: categoryObj.categoryName,
									expanded: true,
									filters:[{
										filterId: filterObj.filterId,
										filterName: filterObj.filterName,
										filterType: filterObj.filterType,
										filterOptions:[{
											filterOptionId: findfilterOptionObject?.filterOptionId,
											filterOptionName: findfilterOptionObject?.filterOptionName
										}]
									}]
								})
							}else if(categoryIndex >= 0 && filterIndex < 0){
								applyFiltersData[categoryIndex].filters.push({
									filterId: filterObj.filterId,
									filterName: filterObj.filterName,
									filterType: filterObj.filterType,
									filterOptions: [{
										filterOptionId: findfilterOptionObject?.filterOptionId,
										filterOptionName: findfilterOptionObject?.filterOptionName
									}]
								})
							}else if( filterIndex >= 0 && findFilterOptionIndex < 0){
								applyFiltersData[categoryIndex].filters[filterIndex].filterOptions.push({
									filterOptionId: findfilterOptionObject?.filterOptionId,
									filterOptionName: findfilterOptionObject?.filterOptionName
								})
							}
						}
					}else if(findfilterOptionObject?.filterOptionName){
						expiredFilters.push(findfilterOptionObject);
					}
			   })
			})
		})
		return {validFilters, validSegments, applyFiltersData, expiredFilters};
	}
}

const filtersTransformations = new FiltersTransformations();


export default filtersTransformations;
