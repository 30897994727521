import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import twoByTwoChart from "./TwoByTwoChart/reducer";
import trends from "./Trends/reducer";
import SearchReducer from "./AdminSearch/SearchReducer";
import chatReducer from "./chatreducer";
import FileDownloadReducer from "./FileDownload/reducer";

const allReducers = combineReducers({
  loginReducer,
  trends,
  twoByTwoChart,
  SearchReducer,
  chat: chatReducer,
  FileDownload: FileDownloadReducer,
});

export default allReducers;
