import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import exportButton from '../../../../assets/icons/icon_export_download.svg';
import CircleProgressBar from '../CircleProgressBar';
import DownloadIcon from './DownloadIcon';
import initData from '../../../../utils/initData';
import './ExportButton.scss';

export default class ExportButton extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        progress: PropTypes.number,
        downloadInProgress: PropTypes.bool,
        exportModalOpened: PropTypes.bool.isRequired,
    }

    state = {
        animateButton: false,
    }

    progressBarColors = initData.getProgressBarColors();

    defaultPathColor = '#1BA379';
    defaultTrailColor = '#000';
    inProgressTrailColor = '#71767D';

    componentDidUpdate(prevProps) {
        const { downloadInProgress, exportModalOpened } = this.props;
        const { downloadInProgress: prevDownloadInProgress, exportModalOpened: prevExportModalOpened } = prevProps;

        if((prevDownloadInProgress !== downloadInProgress) ||
        (prevExportModalOpened !== exportModalOpened)) {
            if(!downloadInProgress && prevDownloadInProgress && !exportModalOpened) {
                this.setState({ animateButton: true });
            } else {
                this.setState({ animateButton: false });
            }
        }
    }
        
    render() {
        const { onClick, progress, downloadInProgress } = this.props;
        const { animateButton } = this.state;
        const { pathColor, trailColorDefault, trailColorMain } = this.progressBarColors;
        const trailColor = downloadInProgress ? trailColorMain : trailColorDefault;

        return (
            <div className={'exportBtn'} id={'exportBtn'} onClick={onClick}>
                <div className={'downloadIcon'}>
                    {downloadInProgress ? <DownloadIcon iconColor={trailColor}/>:<img src={exportButton} alt="" height={22}/>}
                </div>
                {downloadInProgress && <div className='progressBarContainer'>
                    <CircleProgressBar
                        progress={progress}
                        strokeWidth={6}
                        pathColor={pathColor}
                        trailColor={trailColor}
                    />
                </div>}
                <div className={cn({'exportBtnAnimate': animateButton})} />
            </div>
        )
    }
}
