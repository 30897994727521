import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Logo from '../../assets/icons/compass_logo_full.svg'
import Upload from '../../assets/icons/icon_upload.svg'
import './CreateBrandComponent.scss'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import ImageUploader from 'react-images-upload';
import ApiData from '../../utils/Api';
import { getLocalStorage, setLocalStorage } from '../../utils/storageHelpers';

const buttonStyles = {
  alignItems: "center",
  width: "180px",
  height: "180px",
  boxShadow: "none",
  backgroundColor: "transparent",
  padding: 0,
};

const containerStyle = {
  alignItems: "center",
  // width: "180px",
  // height: "180px",
  boxShadow: "none",
  backgroundColor: "transparent",
  padding: 0,
};


class CreateBrandComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.brandName? this.props.brandName: '',
      categoryId: 0,
      subCategoryId: 0,
      turchiaryCategoryId: 0,
      turchiaryCategories: [],
      selectOpen: '',
      logo: undefined,
      loading: false,
    }
    this.handleUpload = this.handleUpload.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
  }
  componentDidMount() {
    let categoriesList = getLocalStorage("brandCategories");;
    if (!categoriesList) {
        ApiData.getCategories().then(
            (result) => {
                setLocalStorage('brandCategories', JSON.stringify(result));
                this.setState({categoriesList: result});
            }
        ).catch(
            (err) => {}
        );
    } else {
        this.setState({categoriesList: JSON.parse(categoriesList)});
    }
  }

  selectCategory = (cid) => {
    this.setState({categoryId: cid, subCategoryId: 0, turchiaryCategoryId: 0, turchiaryCategories: []});
  }

  selectSubCategory = (scid) => {
    ApiData.getSubCategories(scid).then(
      (result) => {
          this.setState({subCategoryId: scid, turchiaryCategoryId: 0, turchiaryCategories: result});
      }
    ).catch(
        (err) => {}
    );
    //this.setState({subCategoryId: scid, turchiaryCategoryId: 0});
  }

  selectTurchiaryCategory = (tcid) => {
    this.setState({turchiaryCategoryId: tcid});
  }

  nameInput = (e) => {
    this.setState({ name: e.target.value });
  }
  handleCreateAccount = () => {
    const { name, logo, categoryId, subCategoryId, turchiaryCategoryId } = this.state;
    // this.props.history.push('/selectbrand', {name: this.state.name, logo: this.state.logo})

    // call api to upload the new brand for current user and get the response
    const data = {
        name,
        imageData: logo,
        categories: [categoryId, subCategoryId, turchiaryCategoryId]
      };

    ApiData.createBrand(data).then(
      (result) => {
        console.log("success")
        if (result) {
          if (this.props.onFinish) {
            if (this.props.existBrands) {
              let newBrand = {id:result.id, name: name, logo: result.logo};
              this.props.existBrands.push(newBrand);
            }
            this.props.onFinish();
          }
        }
      }
    ).catch(
        (err) => {
          console.log("error=" + err);
        }
    );
  }
  handleCancel = () => {
    if (this.props.onFinish) {
      this.props.onFinish();
    }
  }
  handleUpload = (e) => {
    try {
      let reader = new FileReader();
      let file = e[e.length - 1];
      reader.onloadend = () => {
        this.setState({
          logo: reader.result,
        })
        // console.log("Image =" + img)
      };
      reader.readAsDataURL(file);
    } catch (err) {

    }

  }
  handleKeyUp = (e) => {
     let name = e.target.value.replace(/[^0-9a-zA-Z`!@#$%^&*\-';\s]/g,'');
     this.setState({ name });
  }
  render() {
   const { errorMessage, loading, logo, name }  = this.state;
    let customStyle = {};
    if (logo) {
      customStyle.backgroundImage = `url("${logo}")`;
    }
    const inValid=(name.trim().length===0 || name.match(/[^0-9a-zA-Z`!@#$%^&*\-';\s]/));
    return (
      <div className="loginBackground">
        <div className="creatBandContainer">
          <div className="header">
            <img src={Logo} className="" alt="HICOMPASS" />
            <div className='divider' />
            <div className="title">Create a Brand</div>
          </div>
          <div className="content">
            <div className="contentText">If your brand is new and did not appear in the Brand search results, you can create your own brand. It will be submitted to the Compass team for review and approval.
            </div>
            <div className="logoUploader">
              <div className='uploadLabel'>
                UPLOAD BRAND LOGO
              </div>
              <div className='optionalLabel'>
                (Optional)
              </div>
              <div className='uploadContainer' style={customStyle} >
                {!logo && <img src={Upload} className="uploadImage" alt="HICOMPASS" />}
                {!logo && <ImageUploader
                  // className='uploadButton'
                  buttonText=''
                  withIcon={false}
                  buttonStyles={buttonStyles}
                  // buttonClassName={'uploadButton'}
                  maxFileSize={5242880}
                  withLabel={false}
                  singleImage={true}
                  imgExtension={[".jpg", ".gif", ".png"]}
                  fileContainerStyle={containerStyle}
                  onChange={this.handleUpload}>
                </ImageUploader>}
              </div>
            </div>
            <div className="inputContainer">
              <div className="inputBox">
                <input
                  type="text"
                  label="Your Company Name"
                  placeholder="Your Company Name"
                  autoFocus={true}
                  value={name}
                  maxLength = {30}
                  onChange={this.nameInput}
                  onKeyUp={this.handleKeyUp}
                />
              </div>
            </div>
            {errorMessage && <div className="loginWarning">{errorMessage}</div>}  
          </div>
        </div>
        <div className='buttonsContainer'>
          <div>
            <div className="whiteButton button" onClick={this.handleCancel}>
              Cancel
            </div>
              <div className={inValid ? 'blueButton button disabled' : 'blueButton button'} onClick={inValid ? null : this.handleCreateAccount}>
                Create
            </div>
          </div>  
        </div>
        <LoadingComponent showLoading={loading} />
      </div>
    )
  }
}

CreateBrandComponent.protoTypes = {
  brandName: PropTypes.string.isRequired,
  existBrands: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default CreateBrandComponent
