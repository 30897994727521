import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import { Router } from 'react-router'
import App from './App'
import allReducers from './reducers/index'
import './index.css'
import * as Sentry from "@sentry/react";
import { HttpClient } from "@sentry/integrations";
import { QueryClient, QueryClientProvider } from "react-query";
import { AblyProvider } from './contexts/AblyContext';

const history = createBrowserHistory();
const router = routerMiddleware(history);
const queryClient = new QueryClient({});

const middlewares = [
    thunk,
    router,
];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];

const store = createStore(allReducers, composeWithDevTools(...enhancers));

if (!process.env.REACT_APP_SENTRY_DSN || process.env.REACT_APP_SENTRY_ENABLED === "false") {
	if (process.env.NODE_ENV !== "production") {
		console.warn(`Sentry Not Configured - ${process.env.REACT_APP_UUID}`);
	}
}
else {
  // console.log("process.env", process.env);
	Sentry.init({
		// DSN is currently configured separately for each HBP environment.
		dsn: process.env.REACT_APP_SENTRY_DSN,

		// The current release version.
		release: `v${process.env.REACT_APP_VERSION}`,

		// The current environment the application is running on.
		environment: process.env.NODE_ENV,

		// Ideal rate would be 20% for production.
		tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 0.2,

		// Ideal rate would be 20% for production.
		replaysSessionSampleRate: process.env.REACT_APP_SENTRY_REPLAYS_SS_RATE ?? 0.2,

		// Ideal rate would be 20% for production.
		replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAYS_ERROR_SS_RATE ?? 0.2,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			"https://.*.theharrispoll.dev/.*",
		],

		integrations: [
			new HttpClient(),
			new Sentry.BrowserTracing(),
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false
			})
		],

		beforeSend(event, hint) {
			// console.error("Sentry Event", event);
			return event;
		},
	});
}

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<AblyProvider>
				<Router history={history}>
					<App />
				</Router>
			</AblyProvider>
		</Provider>
	</QueryClientProvider>
,
  document.getElementById('root')
)
