import React from "react";
import {
  StyledHeaderCardPrimary,
  StyledHeaderCardSecondary,
  StyledHeaderCardPrimaryLarge,
  StyledHeaderCardPrimaryInvertedWithIconOutline,
  StyledHeaderCardPrimaryInvertedWithoutIconOutline,
} from "./Styled.HeaderCard";

function HeaderCardContent({ title, subtitle, icon, children }) {
  return (
    <>
      <div
        className={`header-card-container ${
          children ? "header-card-container-with-action-button" : ""
        } `}
      >
        {icon && (
          <div className="header-card-icon">
            <img src={icon} alt="header_card_icon" />
          </div>
        )}
        <div
          className={`header-card-title-subtitle ${
            icon ? "header-card-title-width-with-icon" : ""
          }`}
        >
          <div className="header-card-title">{title}</div>
          <div className="header-card-subtitle">{subtitle}</div>
        </div>
      </div>

      {children && <div>{children}</div>}
    </>
  );
}

function HeaderCard({ title, subtitle, icon, variant, children }) {
  if (variant === "primary") {
    return (
      <StyledHeaderCardPrimary>
        <HeaderCardContent title={title} subtitle={subtitle} icon={icon}>
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimary>
    );
  }
  if (variant === "primaryInvertedWithIconOutline") {
    return (
      <StyledHeaderCardPrimaryInvertedWithIconOutline>
        <HeaderCardContent title={title} subtitle={subtitle} icon={icon}>
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimaryInvertedWithIconOutline>
    );
  }
  if (variant === "primaryInvertedWithoutIconOutline") {
    return (
      <StyledHeaderCardPrimaryInvertedWithoutIconOutline>
        <HeaderCardContent title={title} subtitle={subtitle} icon={icon}>
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimaryInvertedWithoutIconOutline>
    );
  }
  if (variant === "primaryLarge") {
    return (
      <StyledHeaderCardPrimaryLarge>
        <HeaderCardContent title={title} subtitle={subtitle} icon={icon}>
          {children}
        </HeaderCardContent>
      </StyledHeaderCardPrimaryLarge>
    );
  }
  return (
    <StyledHeaderCardSecondary>
      <HeaderCardContent title={title} subtitle={subtitle} icon={icon}>
        {children}
      </HeaderCardContent>
    </StyledHeaderCardSecondary>
  );
}

export default HeaderCard;
