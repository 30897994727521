import React from "react";
import StyledViewCardTooltip from "./Styled.ViewCardTooltip";

function ViewCardTooltip({ children, contentList, offset }) {
  return (
    <StyledViewCardTooltip
      arrow={false}
      interactive={true}
      placement="bottom"
      offset={offset ? offset : []}
      delay={[300, null]}
      content={
        <ul>
          {contentList.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      }
    >
      {children}
    </StyledViewCardTooltip>
  );
}

export default ViewCardTooltip;
