import { DateTime } from "luxon";

const DISPLAY_DATE_FORMAT = "MM/dd/yyyy";
const DATE_PERIOD_KEY_MAPPING = {
  L7D: "LAST_7_DAYS",
  L30D: "LAST_30_DAYS",
  L90D: "LAST_90_DAYS",
  L180D: "LAST_180_DAYS",
  YTD: "YEAR_TO_DATE",
  CUSTOM: "CUSTOM",
};

const DATE_PERIOD_KEY_MAPPING_REVERSE = {
  LAST_7_DAYS: "L7D",
  LAST_30_DAYS: "L30D",
  LAST_90_DAYS: "L90D",
  LAST_180_DAYS: "L180D",
  YEAR_TO_DATE: "YTD",
  CUSTOM: "CUSTOM",
};

function getDatesBasedonSelection(condition) {
  if (condition === "L7D") {
    return [
      DateTime.now().minus({ days: 7 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "L30D") {
    return [
      DateTime.now().minus({ days: 30 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "L90D") {
    return [
      DateTime.now().minus({ days: 90 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "L180D") {
    return [
      DateTime.now().minus({ days: 180 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "YTD") {
    return [
      DateTime.now().startOf("year").toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  return [
    DateTime.now().minus({ days: 1 }).toJSDate(),
    DateTime.now().minus({ days: 1 }).toJSDate(),
  ];
}

function getDatesBasedonSelectionFromBackend(condition) {
  if (condition === "LAST_7_DAYS") {
    return [
      DateTime.now().minus({ days: 7 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "LAST_30_DAYS") {
    return [
      DateTime.now().minus({ days: 30 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "LAST_90_DAYS") {
    return [
      DateTime.now().minus({ days: 90 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "LAST_180_DAYS") {
    return [
      DateTime.now().minus({ days: 180 }).toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  if (condition === "YEAR_TO_DATE") {
    return [
      DateTime.now().startOf("year").toJSDate(),
      DateTime.now().minus({ days: 1 }).toJSDate(),
    ];
  }
  return [
    DateTime.now().minus({ days: 1 }).toJSDate(),
    DateTime.now().minus({ days: 1 }).toJSDate(),
  ];
}

export {
  DISPLAY_DATE_FORMAT,
  DATE_PERIOD_KEY_MAPPING,
  DATE_PERIOD_KEY_MAPPING_REVERSE,
  getDatesBasedonSelection,
  getDatesBasedonSelectionFromBackend,
};
