import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Views.scss";
import cx from "classnames";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import addIcon from "../../assets/icons/icon_views_add.svg";
import copIcon from "../../assets/icons/icon_views_clone.svg";
import { RiCloseLine } from "react-icons/ri";
import RequiredIndicator from "../UserAdmin/UsersManagement/RequiredIndicator";
import ApiData from "../../utils/Api";
import { getURLPathParams } from "../../utils/URLParamsValidations";
import { FiCheck } from "react-icons/fi";
import {
  setReports,
  setViews,
} from "../../reducers/TwoByTwoChart/actionCreators";
import ddIcon from "../../assets/icons/icon_arrow_down.svg";
import { formatViewsPayload } from "../../utils/common";

const ctrList = [
  { value: "", label: "Personal" },
  { value: "6ae73be0-e91a-4038-9fb0-67115d8f0469", label: "Finance" },
  { value: "7ae73be0-e91a-4038-9fb0-67115d8f0469", label: "Brand" },
];

const CreateEditView = (props) => {
  let {
    isOpen,
    closeModal,
    selectedViewAction,
    viewNotification,
    queryParamslist,
    handleShowToaster,
    viewDetails,
  } = props;

  const dispatch = useDispatch();
  const viewsListData = useSelector((state) => state?.twoByTwoChart?.viewsList);
  const [viewName, setViewName] = useState();
  const [selectedReports, setSelectedReports] = useState("");
  const [reportsList, setReportsList] = useState(ctrList);
  const [isDefaultView, setIsDefaultView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDynamicDateRange, setIsDynamicDateRange] = useState(false);
  let [reportSuggestions, setReportSuggestions] = useState([]);
  const [text, setText] = useState("");
  const modalRef = useRef(null);
  let suggestionModalRef = useRef();

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      suggestionModalRef.current &&
      suggestionModalRef.current.contains(event.target)
    ) {
      return null;
    } else if (
      suggestionModalRef.current &&
      !suggestionModalRef.current.contains(event.target)
    ) {
      setReportSuggestions([]);
    }
  };

  useEffect(() => {
    try {
      let { viewId } = getURLPathParams();
      getReportsListData();
      console.log(selectedViewAction, "selectedViewAction", viewDetails);
      if (selectedViewAction !== "save") {
        getViewDetails(viewId ? viewId : viewDetails?.viewId);
      }
    } catch (err) {
      console.log(err);
    }
  }, [selectedViewAction]);

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  const getReportsListData = async () => {
    let reportsData = await ApiData.getReports();
    reportsData?.length > 0 && dispatch(setReports([...reportsData]));
    setReportsList(reportsData);
  };

  const getViewDetails = async (viewId) => {
    let viewData = await ApiData.getSelectedViewData(viewId);
    setViewName(viewData?.viewName);
    setSelectedReports(viewData?.viewReport?.name);
    setIsDefaultView(viewData?.defaultView);
    setIsDynamicDateRange(viewData?.dynamicDateRange);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    setText(pastedText);
  };

  const handleCopy = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === "v") {
      navigator.clipboard.readText().then((clipboardText) => {
        setText(clipboardText);
      });
    }
  };

  const handleInputChanges = (e) => {
    setViewName(e.target.value);
  };

  const handleSaveEditView = async () => {
    try {
      setIsLoading(!isLoading);
      let selectedReportObj = reportsList?.find(
        ({ name }) => name === selectedReports
      );
      let data;
      let payload = {
        url: window.location.search,
        name: viewName,
        report: {
          id: selectedReportObj?.id ? selectedReportObj?.id : null,
          name: selectedReportObj?.name
            ? selectedReportObj?.name
            : selectedReports
            ? selectedReports
            : null,
          position: 1,
        },
        name: viewName,
        isDefaultView: isDefaultView,
        isDynamicDateRange: isDynamicDateRange, //DYNAMIC_DATE_RANGE
      };
      if (selectedViewAction === "save") {
        payload = formatViewsPayload(payload, queryParamslist);
        data = await ApiData.createView(payload);
      } else if (selectedViewAction === "clone") {
        let payload = {
          viewName: viewName,
          isDefaultView: isDefaultView,
          report: {
            id: selectedReportObj?.id ? selectedReportObj?.id : null,
            name: selectedReportObj?.name
              ? selectedReportObj?.name
              : selectedReports
              ? selectedReports
              : null,
            description: selectedReportObj?.description,
          },
        };
        data = await ApiData.cloneView(viewDetails?.viewId, payload);
      } else {
        payload = formatViewsPayload(payload, queryParamslist);
        let { viewId } = getURLPathParams();
        payload.viewId = viewId;
        data = await ApiData.updateView(payload);
      }
      dispatch(setViews(data));
      handleCopy(
        window.location.origin + window.location.pathname + data.viewUrl
      );
      handleShowToaster();

      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlecheckbox = (key) => {
    key === "isDefaultView"
      ? setIsDefaultView(!isDefaultView)
      : setIsDynamicDateRange(!isDynamicDateRange);
  };

  const validateFormFields = () => {
    if (viewName !== undefined) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeCategorySearchKey = (e) => {
    let { value } = e.target;
    let findCategory = reportsList?.filter(({ name }) =>
      name.toLowerCase().includes(value?.toLowerCase())
    );
    if (value.trim() === "") {
      setReportSuggestions([]);
    } else if (findCategory.length > 0) {
      setReportSuggestions(findCategory);
    } else if (findCategory?.length === 0) {
      findCategory.push({ name: value });
      setReportSuggestions(findCategory);
    }
    setSelectedReports(value);
  };
  const handleClick = () => {
    if (reportsList?.length > 0) {
      setReportSuggestions(reportsList);
    }
  };

  const onSelectCategory = (report) => {
    setSelectedReports(report);
    setReportSuggestions([]);
  };

  const isSubmitBtnDisabled = validateFormFields();
  return (
    <>
      <Modal
        ref={modalRef}
        open={isOpen}
        onClose={closeModal}
        initialFocusRef={modalRef}
        center
        closeIcon={<span style={{ display: "none" }} />}
        classNames={{
          root: "views_detail_modal_root",
          modal: "warning-modal views-modal views_detail_modal",
          modalAnimationIn: "react-responsive-modal-from-right-in",
          modalAnimationOut: "react-responsive-modal-from-right-out",
        }}
      >
        <div className="modalHeader">
          <div className="modalHeaderContainer">
            <div className="roundedLogo viewModalLogo">
              <img
                src={addIcon}
                alt=""
                className="warningIcon"
                style={{ color: "#7f56d9" }}
              />
            </div>
            <div className="modalHeaderContent">
              <div className="modalTitle">{selectedViewAction} view</div>
              <div className="modalSubTitle">
                {selectedViewAction === "save" || selectedViewAction === "clone"
                  ? "Add view to user."
                  : "Edit user view"}
              </div>
            </div>
          </div>
          <RiCloseLine className="warningIcon closeIcon" onClick={closeModal} />
        </div>
        <div className="modalContent">
          <div className="form-container form-container-modalContent">
            {!viewNotification && (
              <>
                <div className="form-group">
                  <div className="form-label-wrapper">
                    <label htmlFor="viewName" className="form-label view-label">
                      View Name
                      <RequiredIndicator color={"black"} />
                    </label>
                  </div>
                  <div className="form-input-wrapper view-input-wrapper">
                    <div className={"form-input-block"}>
                      <input
                        className="form-input view-input"
                        type="text"
                        id="viewName"
                        placeholder="View 20241128"
                        value={viewName}
                        onChange={(e) => handleInputChanges(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-label-wrapper">
                    <label htmlFor="reports" className="form-label view-label">
                      Report Name
                    </label>
                  </div>
                  <div className="form-input-wrapper view-input-wrapper view-modal">
                    <input
                      type="text"
                      placeholder="Select Report Name"
                      value={selectedReports}
                      className="form-input view-input"
                      onChange={(e) => onChangeCategorySearchKey(e)}
                      onClick={handleClick}
                    />
                    <img
                      src={ddIcon}
                      alt=""
                      style={{
                        position: "absolute",
                        top: "18px",
                        cursor: "pointer",
                        right: "20px",
                      }}
                      onClick={handleClick}
                    />
                    {reportSuggestions?.length > 0 && (
                      <ul
                        className={
                          "suggestionContainer formSuggestionsContainer"
                        }
                        ref={suggestionModalRef}
                      >
                        {reportSuggestions?.map(({ name, id }, ind) => {
                          return (
                            <li
                              style={{
                                flexDirection: "row",
                                justifyContent: "normal",
                              }}
                              key={ind}
                              onClick={() => onSelectCategory(name)}
                            >
                              {id === undefined ? (
                                <span className={"suggestTitle"}>
                                  Add New Report: {`"${selectedReports}"`}
                                </span>
                              ) : (
                                <span
                                  className={cx("suggestTitle", {
                                    active:
                                      selectedReports === name &&
                                      id !== undefined,
                                  })}
                                >{`${name}`}</span>
                              )}
                              {selectedReports === name && id !== undefined && (
                                <FiCheck
                                  style={{
                                    marginLeft: "auto",
                                    color: "blueviolet",
                                    marginTop: "14px",
                                  }}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <div className="checkBoxContainer">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isDefaultView}
                      className={cx(!isDefaultView && "checkbox")}
                      onChange={() => handlecheckbox("isDefaultView")}
                    />
                    <div className="view-input-warpper-input">
                      <div className="viewFormTitle">Set as Default</div>
                      <div className="viewFormSubTitle">
                        Load this view by default when you login.
                      </div>
                    </div>
                  </div>
                  <div className="checkBoxContainer">
                    <input
                      type="checkbox"
                      id="checkbox"
                      disabled={
                        queryParamslist?.selectedDate?.selectedPeriod !== 1
                      }
                      checked={isDynamicDateRange}
                      className={cx(!isDynamicDateRange && "checkbox")}
                      onChange={() => handlecheckbox("isDynamicDateRange")}
                    />
                    <div className="view-input-warpper-input">
                      <div className="viewFormTitle">
                        Use Dynamic Date Range
                      </div>
                      <div className="viewFormSubTitle">
                        <span style={{ fontWeight: "600" }}>'End Date'</span>{" "}
                        will always be relative to the date of viewing.
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={"form-button-actions"}
          style={{ padding: "0px", width: "auto" }}
        >
          <div
            className={cx("copyButton", {
              disabled: isSubmitBtnDisabled || isLoading,
            })}
            onClick={handleSaveEditView}
          >
            <img
              src={copIcon}
              style={{ width: "22px", height: "22px" }}
              alt=""
            />
            Save & Copy Link
          </div>
          <div
            className={cx("saveButton", {
              disabled: isSubmitBtnDisabled || isLoading,
            })}
            onClick={handleSaveEditView}
          >
            Save
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CreateEditView;
