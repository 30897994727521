import { Button } from "@material-ui/core";
import Tooltip from "./tooltip";
import { CloseOutlined, ChatBubbleOutlineRounded } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addMessage,
  userTyping,
  assistantResponding,
} from "../../../../reducers/actions/actions";
import "./chat.scss";
import MessageContent from "./messageContent";
import MessageInput from "./messageInput";
import axios from "axios";

const Chat = () => {
  const [isDataReady, setIsDataReady] = useState(false);
  const [hasInitializedMessages, setHasInitializedMessages] = useState(false);
  const dispatch = useDispatch();

  // Check if localStorage data is ready
  useEffect(() => {
    const checkLocalStorageData = () => {
      const storedBrands = localStorage.getItem("globalBrands");
      if (storedBrands) {
        setIsDataReady(true);
      }
    };
    checkLocalStorageData();
    const interval = setInterval(checkLocalStorageData, 1000);
    return () => clearInterval(interval);
  }, []);

  // Initiate session only when localStorage data is ready
  useEffect(() => {
    if (isDataReady) {
      const initiateSession = async () => {
        try {
          // Fetch and transform the data from localStorage
          const storedBrands =
            JSON.parse(localStorage.getItem("globalBrands")) || [];
          const transformedBrands = storedBrands.reduce((acc, brand) => {
            brand.marketGroups.forEach((marketGroup) => {
              marketGroup.marketGroupsList.forEach((market) => {
                const existingMarket = acc.find(
                  (m) => m.marketId === market.marketId
                );

                const brandInfo = {
                  id: brand.id,
                  name: brand.name,
                };

                if (existingMarket) {
                  existingMarket.brands.push(brandInfo);
                } else {
                  acc.push({
                    marketId: market.marketId,
                    code: market.identity,
                    marketName: market.marketName,
                    brands: [brandInfo],
                  });
                }
              });
            });
            return acc;
          }, []);

          // Define user data with including transformedBrands
          const userInfo = {
            userData: transformedBrands,
          };

          // Make the API call to initialize the session
          const initiationResponse = await axios.post(
            process.env.REACT_APP_ASSISTANT_URL + "/initiatesession/",
            userInfo,
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          console.log(
            "Session Initiated Successfully",
            initiationResponse.data.assistant_response
          );
        } catch (error) {
          console.log("Session Initialization failed...", error);
        }
      };

      initiateSession();
    }
  }, [isDataReady]);

  const toggleToolTipOpenHandler = () => {
    const visibility = document.getElementById("message-content-container");
    if (visibility.style.visibility === "visible") {
      visibility.style.visibility = "hidden";
      visibility.style.opacity = 0;
    } else {
      visibility.style.visibility = "visible";
      visibility.style.opacity = 1;

      // Add initial messages on the first time when the tooltip opens
      if (!hasInitializedMessages) {
        setHasInitializedMessages(true);
        addInitialMessages();
      }
    }
  };

  const addInitialMessages = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const firstName = userInfo?.firstName || "there";
    const lastName = userInfo?.lastName || "";

    // to keep the user more engaging added the seasonalgreeting message 
    // TODO : Make it as configurable through the env or something else 
    const getSeasonalGreeting = () => {
      const month = new Date().getMonth();
      const monthGreetings = {
        0: `Hi ${firstName}! ❄️ January is a fresh start, and I’m here to make it even better. Let’s dive in—what can I help with today?`,
        1: `Hello ${firstName}! ❤️ February is the month of love, and I’m here to assist with care. How can I make your day smoother?`,
        2: `Hi ${firstName}! 🌸 March brings blossoms and possibilities. What would you like to tackle today?`,
        3: `Hello ${firstName}! 🌼 April showers may come, but I’ll make sure your tasks are sunny. What’s on your mind?`,
        4: `Hi ${firstName}! ☀️ May is perfect for new ideas and bright days. Let’s make it amazing—how can I assist?`,
        5: `Hello ${firstName}! 🌊 June is the perfect time for making waves. What’s your next big splash?`,
        6: `Hi ${firstName}! 🏖️ July vibes are all about relaxing and achieving. Let me know how I can help you!`,
        7: `Hello ${firstName}! 🍉 August is full of summer sweetness. Let’s make your day just as sweet. How can I assist?`,
        8: `Hi ${firstName}! 🍂 September brings crisp air and fresh goals. What’s the first thing on your list?`,
        9: `Hello ${firstName}! 🎃 October is spooky good for productivity. Let’s conquer your tasks—what’s first?`,
        10: `Hi ${firstName}! 🦃 November is a time for gratitude, and I’m thankful to help. What’s on your mind today?`,
        11: `Hello ${firstName}! 🎅 December’s festive spirit is here. Let’s wrap up your goals like holiday gifts! What can I help with?`,
      };
      return (
        monthGreetings[month] ||
        `Hi ${firstName} ${lastName}! 😊 How can I make your day special today?`
      );
    };

    const initialMessages = [
      getSeasonalGreeting(),
      "Let me know where you’d like to navigate to  or if you’re exploring something new!",
    ];

    for (const message of initialMessages) {
      dispatch(assistantResponding(true));
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Typing delay
      dispatch(addMessage({ type: "Assistant", text: message }));
      await new Promise((resolve) => setTimeout(resolve, 500)); // Delay between messages
    }
    dispatch(assistantResponding(false));
  };

  const ChatBox = () => (
    <div className="card-container chat-container">
      <div className="card-header">
        <div className="card-title">
          <span>
            HQB Assistant <span>(v2.1.0)</span>
          </span>
          <br />
          <span>How can I assist you?</span>
        </div>
        <div className="close-icon">
          <CloseOutlined fontSize="small" onClick={toggleToolTipOpenHandler} />
        </div>
      </div>
      <MessageContent />
      <MessageInput />
    </div>
  );

  return (
    <Tooltip
      title={<ChatBox />}
      toggleToolTipOpenHandler={toggleToolTipOpenHandler}
    >
      <Button variant="contained" className="tooltip-message-button">
        <ChatBubbleOutlineRounded />
      </Button>
    </Tooltip>
  );
};

export default Chat;
