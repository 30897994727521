import styled from "styled-components";
import Tippy from "@tippyjs/react";
import { PRIMARY_BORDER_GRAY_COLOR, TEXT_NORMAL } from "../utils/constant";

const StyledViewCardTooltip = styled(Tippy)`
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid ${PRIMARY_BORDER_GRAY_COLOR};
  box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
  
  .tippy-content {
    padding: 0px;
  }
  .tippy-content ul {
    font-weight: 500;
    color: ${TEXT_NORMAL};
    margin: 5px 0px;

    li {
      height: auto;
      font-size: 11px;
      padding: 4px 8px;
      width: fit-content;
    }
  }
`;

export default StyledViewCardTooltip;
