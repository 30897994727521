import { ADD_MESSAGE, USER_TYPING, ASSISTANT_RESPONDING } from '../actiontypes/actiontypes';

export const addMessage = (message) => ({
  type: ADD_MESSAGE,
  payload: message,
});

export const userTyping = (isTyping) => ({
  type: USER_TYPING,
  payload: isTyping,
});

export const assistantResponding = (isResponding) => ({
  type: ASSISTANT_RESPONDING,
  payload: isResponding,
});
