import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewFileData } from "../utils";
import {
  addFileToDownload,
  updateFileDownloadRequestTimeout,
  updateFileInDownload,
  updateFileProgressInDowload,
} from "../../../reducers/FileDownload/actionCreators";
import { FILE_STATUS } from "../utils/constant";

function useFileDownload() {
  const downloadFileList = useSelector(
    (state) => state.FileDownload.downloadFileList
  );
  const progressUpdate = useSelector(
    (state) => state.FileDownload.progressUpdate
  );
  const dispatch = useDispatch();

  function addNewFileToDownload({ fileName, jobId, screenType }) {
    const newFileData = createNewFileData({ fileName, jobId, screenType });
    dispatch(addFileToDownload(jobId, newFileData));
    updateProgressOfFileDownload(jobId);
    setTimeout(() => {
      dispatch(updateFileDownloadRequestTimeout(jobId));
    }, 120000);
  }

  function updateProgressOfFileDownload(jobId) {
    let timeOutId = setInterval(() => {
      dispatch(updateFileProgressInDowload(jobId, timeOutId));
    }, 100);
  }

  const processSuccessDownloads = useCallback((message) => {
    const data = JSON.parse(message?.data);
    dispatch(
      updateFileInDownload(data.jobId, {
        status: FILE_STATUS.SUCCESS,
        url: data.url,
      })
    );
  }, []);
  const processFailedDownloads = useCallback((message) => {
    const data = JSON.parse(message?.data);
    dispatch(
      updateFileInDownload(data.jobId, {
        status: FILE_STATUS.FAILED,
        errorMessage: data?.errorMessage,
      })
    );
  }, []);

  function triggerFileDownload(url, fileName) {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.setAttribute("download", fileName);
    link.click();
  }

  return {
    addNewFileToDownload,
    downloadFileList,
    progressUpdate,
    overallProgressStatus: Math.min(...Object.values(progressUpdate || {})),
    triggerFileDownload,
    processSuccessDownloads,
    processFailedDownloads,
  };
}

export default useFileDownload;
