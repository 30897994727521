import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty, isEqual } from 'lodash';
import LoadingLayer from '../widgets/Shared/LoadingLayer';
import BarChart from '../widgets/ChartComponents/BarChart';
import EquityCard from '../widgets/ChartComponents/EquityCard';
import EmotionalCard from '../widgets/ChartComponents/EmotionalCard';
import AdvertisingCard from '../widgets/ChartComponents/AdvertisingCard';
import FunnelChartCard from '../widgets/ChartComponents/FunnelChartCard';
import LineChart from '../widgets/ChartComponents/LineChart';
import StackedBarChart from '../widgets/ChartComponents/StackedBarChart';
import TwoByTwoChart from '../widgets/ChartComponents/TwoByTwoChart';
import initData from '../../utils/initData';
import ApiData from '../../utils/Api';
import plusIcon from '../../assets/icons/icon_plus_big.svg';
import transformHigherLowerThan from '../../utils/transformHigherLowerThan';
import './DetailedIntelligenceHomeComponent.scss';
import {
    setSegments,
    setCustomSegments,
    disableSegments,
    disableCustomSegments,
    setChartData as setTwoByTwoData,
    clearAllData as clearAllTwoByTwoData,
} from '../../reducers/TwoByTwoChart/actionCreators';
import {
    setChartData as setTrendsData,
    clearAllData as clearAllTrendsData,
} from '../../reducers/Trends/actionCreators';
import EquityScrollWrapperHeightService, { selectors } from '../../utils/equityScrollWrapperHeightService';
import TwoByTwoChartAxisPatchService from '../../utils/2x2ChartAxisPatchService';
import filtersTransformations from '../../utils/filterTransformations';
import { getLocalStorage, getSessionStorage, setSessionStorage } from '../../utils/storageHelpers';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import moment from 'moment';
import dateTransform from '../../utils/dateTransform';
import { getURLPathParams } from '../../utils/URLParamsValidations';
import { validationsForHSSMarketLabels } from '../../utils/common';

class DetailedIntelligenceHomeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            brandData: [],
            selectedBrand: this.props.selectedBrand,
            loading: true,
            editing: false,
            readyShow: true,
            variableData: [],
            grid: 8,
            colors:[],
            updateColors:[],
            scrollWrapperService: null,
            scrollWrapperHeight: null,
            requestSegmentsData: false,
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.wrapperRef = React.createRef();
        this.props.setRef(this.wrapperRef);
        this.debounceTimer = false;
        this.maxVisibleByDefault = 5;
        this.TwobyTwoChartAxisPatchService = new TwoByTwoChartAxisPatchService();
    }

    updateListData = (listData) => {
        const {
            setTrendsData,
            selectedTab,
            // customSegmentList,
            interval
        } = this.props;
        const parsedListData = listData?.map((listDataItem) => {
            listDataItem.color = listDataItem.segment.color?listDataItem.segment.color:listDataItem.color
          
            return {
                ...listDataItem,
                segment: listDataItem.segment,
            };
        });
        // listData items structure for Trends is slightly different
        const notTrendsTab = selectedTab !== 4;
        this.props.setDataAvailability(parsedListData.every(item => notTrendsTab ? !isEmpty(item.data.data) : !isEmpty(item.data)))
        this.setState({
            loading: false,
            readyShow: true,
            brandData: parsedListData,
        });
        let customSegmentList = this.props.segments?.reduce((acc, sr) => {
           acc.push(...sr.segments);
           return acc;
       }, [])
           const segments = customSegmentList?.filter(item => item?.isActive === true && item?.segmentMarkets?.includes(this.props.selectedCampaign.marketName))
        //    const parsedCustomSegmentList = filtersTransformations.parseSegmentsFilters(segments, this.filters);
        if (!notTrendsTab) {
            parsedListData.sort((a, b) => {
                const orderA = segments.findIndex(item => item.segmentId === a.segment.segmentId);
                const orderB = segments.findIndex(item => item.segmentId === b.segment.segmentId);
                return orderA - orderB;
            });
            setTrendsData(dateTransform(parsedListData,interval));
        }
    }

    getTendsOptionsData = (trendsData)=>{
        this.setState({trendsDataName: trendsData});
    }

    groupBy =(arr, property) =>{
        return arr.reduce(function(memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    getDefaultListData = (tab, defaultData) => {
        const bcolors = initData.getColors().map(color => color[0])
        const { brands, selectedBrand } = this.props;
        const brand = brands[selectedBrand]
        let brandColor;
        let  customSegment = JSON.parse(getSessionStorage('segmentsList'))
        let customSegmentList = customSegment?.reduce((acc, sr) => {
           acc.push(...sr.segments);
           return acc;
       }, [])
        return customSegmentList?.filter(item => item.isActive !== false).map((item, index) => {
            if(!item.color){
                brandColor = bcolors[index%10]
               }else{
                brandColor = item.color
               }
            return {
                tab: tab,
                segment: item,
                brand: brand,
                color: brandColor,
                data: defaultData,
            }
        })
    }

    getDefaultTrendsData = (stab,segments,brand) => {
        const bcolors = initData.getColors().map(color => color[0])
        let finalData=[];
        segments.map((obj,ind)=>{
            let color = obj.color;
            if(!color){
              color = bcolors[ind % 10];
            }
            finalData.push({ tab: 4, stab: stab, segment: segments[ind], brand: brand, color:color , brandShow: false, data: []})
        })
        return finalData;
    }


    getXYZfor2x2 = () => {
        const mapFunc = filter => {
            let filterOptions = [];
            filter.filterOptions.forEach(fo => {
                if (fo.type === 'group' && fo.groupingIds) {
                    filterOptions = [...filterOptions, ...fo.groupingIds];
                } else {
                    filterOptions = [...filterOptions, fo.id];
                }
            });
            return { filterId: filter.id, filterOptions };
        };

        const {
            xAxisSelect,
            yAxisSelect,
            zAxisSelect,
        } = this.props;

        let x = get(xAxisSelect, 'subcategory.filters', []).map(mapFunc);
        let y = get(yAxisSelect, 'subcategory.filters', []).map(mapFunc);
        let z = get(zAxisSelect, 'subcategory.filters', []).map(mapFunc);

        // Size works incorrect if payload is an empty array
        x = x.length ? this.TwobyTwoChartAxisPatchService.patchAxisPayload(xAxisSelect, x) : null;
        y = y.length ? this.TwobyTwoChartAxisPatchService.patchAxisPayload(yAxisSelect, y) : null;
        z = z.length ? this.TwobyTwoChartAxisPatchService.patchAxisPayload(zAxisSelect, z) : null;

        return { x, y, z };
    };

    getListData = async () => {
        const {
            brands,
            currentVariableFilter,
            interval,
            selectedBrand,
            selectedTab,
            useExposed,
            selectedCampaign,
            lineOption,
            selectedDate,
            currentVariableMainFilter,
            selectedPeriod,
            currentVariableGroup,
            customSegmentList,
            page
        } = this.props;
        const bcolors = initData.getColors().map(c=>{return c[0] });
        let optionModes = initData.getModes();
        const brand = brands[selectedBrand];
        let brandIds = [];
        let startDate = selectedDate.startdate;
        if(((selectedTab === 3 )||(selectedTab === 4 && lineOption.stab === 3)||(selectedTab === 5 && currentVariableGroup === 5)) && (selectedPeriod === 1 && moment(startDate).isBefore('2022-09-21'))){
            startDate = '2022-09-21'
        }

        brands.map(brandObj => brandIds.push(brandObj.id));
        let {segmentIds} = getURLPathParams();
        let selectedMode = optionModes.filter(obj=> obj.id === useExposed)[0]?.name?.toUpperCase().replace(/ /g, "");
        let segmentsList = this.props.customSegmentList?.filter(item => item?.isActive === true && item?.segmentMarkets?.includes(selectedCampaign?.marketName)).map(item => {
            return {
                segmentName: item.segmentName,
                segmentId: item.segmentId,
                filters: [...item.filters]
            }
        })
        let filteredSegments = segmentsList?.filter(({segmentId}) => segmentIds?.some(id => segmentId == id));
        segmentsList = filteredSegments?.length > 0 ? filteredSegments : segmentsList;
        const parsedCustomSegmentList = filtersTransformations.parseSegmentsFilters(segmentsList, this.filters);

        let body = {
            marketId: selectedCampaign.marketId,
            brandIds: [brand?.id],
            fromDate: startDate,
            toDate: selectedDate.enddate,
            segments: parsedCustomSegmentList,
            audienceType: selectedMode,
            module: initData.getModuleType(page),
            isStatTestingEnabled:true,
        }

        this.setState({ loading: true });

        if (selectedTab === 0 && parsedCustomSegmentList?.length > 0) {
           ApiData.getGeoEquityData(body).then(
                (result) => {
                    this.updateListData(
                        result.map((item, index) => {
                            const brandColor = bcolors[index % 10];
                            const data = {
                                value: Number(item.equity?.toFixed(1)),
                                upDown: Number(item.equityDiff?.toFixed(1)),
                                pool: Number(item.pool?.toFixed(2)),
                                data: [
                                    { label: "Momentum", value: Number(item.netMomentum?.toFixed(1)), diff: Number(item.netMomentumDiff?.toFixed(1)) },
                                    { label: "Consideration", value: Number(item.consideration?.toFixed(1)), diff: Number(item.considerationDiff?.toFixed(1)) },
                                    { label: "Quality", value: Number(item.highQuality?.toFixed(1)), diff: Number(item.highQualityDiff?.toFixed(1)) },
                                    { label: "Familiarity", value: Number(item.familiarity?.toFixed(1)), diff: Number(item.familiarityDiff?.toFixed(1)) }
                                ]
                            }
                            return {
                                tab: 0,
                                segment: customSegmentList.find(elem => elem.segmentId === item.inputId),
                                brand: brand,
                                color: brandColor,
                                data: data,
                                lowerThan: transformHigherLowerThan(item.lowerThan),
                                higherThan: transformHigherLowerThan(item.higherThan),
                            }
                            
                        })
                    )
                }
            ).catch(
                (err) => {
                    console.log("error" + err)
                    this.updateListData(this.getDefaultListData(0, { value: 0, pool: 0, data: [] }))
                }
            )
        }

        if (selectedTab === 1 && parsedCustomSegmentList.length > 0) {
            body.audienceType = "GENPOP";
            ApiData.getGeoConversionData(body).then(
                (result) => {
                    this.updateListData(
                        result.map((item, index) => {
                            const brandColor = bcolors[index%10];
                            const data = [
                                { label: 'Awareness', value: Number(item.awareness?.toFixed(1)), percentage: Number(item.awarenessPercent?item.awarenessPercent?.toFixed(1):0), key: 'awareness' },
                                { label: 'Familiarity', value: Number(item.familiarity?.toFixed(1)), percentage: Number(item.familiarityPercent?item.familiarityPercent?.toFixed(1):0), key: 'familiarity' },
                                { label: 'Trial', value: Number(item.trial?.toFixed(1)), percentage: Number(item.trialPercent?item.trialPercent?.toFixed(1):0), key: 'trial' },
                                { label: 'Usage', value: Number(item.usage?.toFixed(1)), percentage: Number(item.usagePercent?item.usagePercent?.toFixed(1):0), key: 'usage' },
                                { label: 'Recommend', value: Number(item.recommend?.toFixed(1)), percentage: Number(item.recommendPercent?item.recommendPercent?.toFixed(1):0), key: 'recommend' }
                            ]
                            data.sort((a, b) => { return b.value - a.value })
                            return {
                                tab: 1,
                                segment: customSegmentList?.find(elem => elem.segmentId === item.inputId),
                                brand: brand,
                                color: brandColor,
                                data: { pool: Number(item.pool?.toFixed(2)), data: data },
                                lowerThan: item.lowerThan,
                                higherThan: item.higherThan,
                            }
                        })
                    )
                }
            ).catch(
                (err) => {
                    console.log("error=" + err)
                    this.updateListData(this.getDefaultListData(1, { pool: 0, data: [] }))
                }
            )
        }

        if (selectedTab === 2 && parsedCustomSegmentList.length > 0) {
            ApiData.getGeoImageryData(body).then(
                (result) => {
                    this.updateListData(
                        result.map((item, index) => {
                            const brandColor = bcolors[index%10];
                            const data = validationsForHSSMarketLabels(item, selectedCampaign?.countryCode);
                            

                            return {
                                tab: 2,
                                segment: customSegmentList.find(elem => elem?.segmentId === item.inputId),
                                brand: brand,
                                color: brandColor,
                                data: { pool: Number(item['pool']?.toFixed(2)), data: data },
                                lowerThan: item?.lowerThan,
                                higherThan: item?.higherThan,
                            }
                        })
                    )
                }
            ).catch(
                (err) => {
                    console.log("error=" + err)
                    this.updateListData(this.getDefaultListData(2, { pool: 0, data: [] }))
                }
            )
        }

        if (selectedTab === 3 && parsedCustomSegmentList.length > 0) {
            ApiData.getGeoAdvertisingData(body).then(                
            (result) => {
                    this.updateListData(
                        result.map((item, index) => {
                            const brandColor = bcolors[index%10];
                            const data = validationsForHSSMarketLabels(item, selectedCampaign?.countryCode);

                            return {
                                tab: 3,
                                segment: customSegmentList.find(elem => elem.segmentId === item.inputId),
                                brand: brand,
                                color: brandColor,
                                data: { pool: Number(item['pool']?.toFixed(2)), upDown: Number(item.advertisingRecallDiff?.toFixed(1)), upDownNA: (item.advertisingRecallDiff === null), data: data },
                                lowerThan: item?.lowerThan,
                                higherThan: item?.higherThan,
                            }
                        })
                    )
                }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    this.updateListData(this.getDefaultListData(0, { value: 0, upDown: 0, upDownNA: true, pool: 0, data: [] }))
                }
            )       
        }

        if (selectedTab === 4 && parsedCustomSegmentList.length > 0) {  
            let body = {
                marketId: selectedCampaign.marketId,
                brandIds: [brand.id],
                fromDate: startDate,
                toDate: selectedDate.enddate,
                segments: parsedCustomSegmentList,
                trend: {intervalUnit: interval},
                audienceType: selectedMode,
                module: initData.getModuleType(page),
                isStatTestingEnabled:true,
            }
            if(lineOption.stab === 0 ) {
                ApiData.getGeoEquityData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map((obj,ind)=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    data.push({
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        dailyAverageSampleSize: Number(r.dailyAverageSampleSize?.toFixed(2)),
                                        total: Number(r.equity?r.equity.toFixed(1):0),
                                        // momentum: Number(lineOption.selectedScoreOption === 'positive' && r.postiveNetMomentum?.toFixed(1) || lineOption.selectedScoreOption === 'negative' && r.negativeNetMomentum?.toFixed(1)|| 0 ),
                                        momentum: Number(r[lineOption?.selectedScoreOption+'NetMomentum']?r[lineOption.selectedScoreOption+'NetMomentum'].toFixed(1):0),
                                        consideration: Number(r[lineOption?.selectedScoreOption+'Consideration']?r[lineOption.selectedScoreOption+'Consideration'].toFixed(1):0),
                                        quality:Number(r[lineOption?.selectedScoreOption+'HighQuality']?r[lineOption.selectedScoreOption+'HighQuality'].toFixed(1):0),
                                        familiarity: Number(r.familiarity?r.familiarity.toFixed(1):0),
                                        lowerThan: r?.lowerThan,
                                        higherThan: r?.higherThan,
                                    })
                                })
                                let color = customSegmentList.filter(item=> item.segmentId == obj)[0]?.color
                                if(!color){
                                    color = bcolors[ind%10]
                                } 
                            return  finalData.push({ tab: 4, stab: 0,segment: customSegmentList.filter(item=> item.segmentId == obj)[0], brand: brand, color:color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 0;
                            this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err);
                        let stab = 0;
                        this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                    }
                );
            }
            if(lineOption.stab === 1) {
                ApiData.getGeoConversionData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map((obj,ind)=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    data.push({
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        dailyAverageSampleSize: Number(r.dailyAverageSampleSize?.toFixed(2)),
                                        awareness: Number(r.awareness?r.awareness.toFixed(1):0),
                                        familiarity: Number(r.familiarity?r.familiarity.toFixed(1):0),
                                        trial: Number(r.trial?r.trial.toFixed(1):0),
                                        usage: Number(r.usage?r.usage.toFixed(1):0),
                                        recommend: Number(r[lineOption?.selectedScoreOption+'Recommend']?r[lineOption.selectedScoreOption+'Recommend'].toFixed(1):0),
                                        lowerThan: r?.lowerThan,
                                        higherThan: r?.higherThan,
                                    });
                                });
                                let color = customSegmentList.filter(item=> item.segmentId == obj)[0].color
                                if(!color){
                                    color = bcolors[ind%10]
                                } 
                            return  finalData.push({ tab: 4, stab: 1,segment: customSegmentList.filter(item=> item.segmentId == obj)[0], brand: brand, color:color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 1;
                            this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err);
                        let stab = 1;
                        this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                    }
                );
            }
            if(lineOption.stab === 2) {
                ApiData.getGeoImageryData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map((obj,ind)=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    const rdata = {
                                        date: r.date,
                                        pool: Number(r.pool?r.pool?.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        dailyAverageSampleSize: Number(r.dailyAverageSampleSize?.toFixed(2)),
                                        lowerThan: r.lowerThan,
                                        higherThan: r.higherThan,
                                    };
                                    for (const key in r) {
                                        console.log(key)
                                        if (key !== 'date' && key !== 'pool' && key !== 'higherThan' && key !== 'lowerThan' && key !== 'inputName' && key !== 'inputId' && key !=='genpop' && key !== 'newDate') {
                                            rdata[key] = Number(key && r[key]?r[key]?.toFixed(1):0);
                                        }
                                    }
                                    data.push(rdata);
                                });
                                let color = customSegmentList.filter(item=> item.segmentId == obj)[0].color
                                if(!color){
                                        color = bcolors[ind%10]
                                } 
                            return finalData.push({ tab: 4, stab: 2,segment: customSegmentList.filter(item=> item.segmentId == obj)[0], brand: brand, color:color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 2;
                            this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                        }
                    }                        
                ).catch(
                    (err) => {
                        console.log("error=" + err);
                        let stab = 2;
                        this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                    }
                );
            }
            if(lineOption.stab === 3) {
                ApiData.getGeoAdvertisingData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map((obj,ind)=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    const rdata = {
                                        date: r.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        dailyAverageSampleSize: Number(r.dailyAverageSampleSize?.toFixed(2)),
                                        lowerThan: r.lowerThan,
                                        higherThan: r.higherThan,
                                    };
                                    for (const key in r) {
                                        if (key !== 'date' && key !== 'pool' && key !== 'poolTotal' && key !== 'higherThan' && key !== 'lowerThan' && key !== 'inputName' && key !=='genpop' && key !== 'newDate') {
                                            rdata[key] =  Number(r[key]?r[key]?.toFixed(1):0);
                                        }
                                    }
                                    data.push(rdata);
                                });
                                let color = customSegmentList.filter(item=> item.segmentId == obj)[0].color
                                if(!color){
                                        color = bcolors[ind%10]
                                } 
                            return finalData.push({ tab: 4, stab: 3,segment: customSegmentList.filter(item=> item.segmentId == obj)[0], brand: brand, color:color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 3;
                            this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                        }
                    }                        
                ).catch(
                    (err) => {
                        console.log("error=" + err );
                        let stab = 3;
                        this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                    }
                );
            }

            if(lineOption.stab === 4 ) {
                ApiData.getGeoIrreplaceabilityData(body, true).then(
                    (result) => {
                        if(result?.length > 0){
                            result = this.groupBy(result,'inputId');
                            let finalData = [];
                            Object.keys(result).map((obj,ind)=>{
                                let data = [];
                                result[obj].forEach((r)=>{
                                    data.push({
                                        date: r?.date,
                                        pool: Number(r.pool?r.pool.toFixed(2):0),
                                        poolTotal: Number(r.poolTotal?.toFixed(2)),
                                        dailyAverageSampleSize: Number(r.dailyAverageSampleSize?.toFixed(2)),
                                        // total: Number(r.equity?r.equity.toFixed(1):0),
                                        positive: Number(r.positive? r.positive.toFixed(1):0),
                                        neutral: Number(r.neutral?r.neutral.toFixed(1):0),
                                        negative:Number(r.negative? r.negative.toFixed(1):0),
                                        net: Number(r.net?r.net.toFixed(1):0),
                                        lowerThan: r?.lowerThan,
                                        higherThan: r?.higherThan,
                                    })
                                })
                                let color = customSegmentList.filter(item=> item.segmentId == obj)[0]?.color
                                if(!color){
                                    color = bcolors[ind%10]
                                } 
                            return  finalData.push({ tab: 4, stab: 4,segment: customSegmentList.filter(item=> item?.segmentId == obj)[0], brand: brand, color:color , brandShow: true, data: data})
                            })
                            this.updateListData(finalData)
                        }else{
                            let stab = 4;
                            this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                        }
                    }
                ).catch(
                    (err) => {
                        console.log("error=" + err);
                        let stab = 4;
                        this.updateListData(this.getDefaultTrendsData(stab ,customSegmentList ,brand));
                    }
                );
            }
        }
        if (selectedTab === 5 && parsedCustomSegmentList.length > 0) {
            this.setState({ variableData: [] });
            let payload = {
                marketId: selectedCampaign.marketId,
                brandIds: [brand.id],
                fromDate: startDate,
                toDate: selectedDate.enddate,
                segments: parsedCustomSegmentList,
                audienceType: selectedMode,
                profile: {
                    groupId: currentVariableMainFilter,
                    metricId: currentVariableGroup,
                    responseId: currentVariableFilter.index
                },
                module: initData.getModuleType(page),
                isStatTestingEnabled:true
            }
            ApiData.getGeoDetailedVariableData(payload).then(
                (result) => {
                    let age18Segment = result.findIndex(item => item.inputName === "Age 18+");
                    if(currentVariableGroup !== 1){
                        result = result.filter(val=> val.inputName !== "Age 18+");
                    }else if(age18Segment !== -1){
                        result.unshift(result.splice(age18Segment, 1)[0])
                    }
                    this.props.setDataAvailability(!isEmpty(result))
                    this.setState({
                        loading: false,
                        readyShow: true,
                        variableData: result,
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err);
                    this.props.setDataAvailability(false)
                    this.setState({ loading: false });
                }
            );
        }

    }

    componentDidMount() {
        this.initializeAndUseScrollWrapperService();
        this.getUserSegments();
        this.filters = JSON.parse(getLocalStorage("filters"));
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { requestSegmentsData: prevRequestSegmentsData } = prevState;
        const { requestSegmentsData } = this.state;
        /*
            This is triggered only when the segment without data to show on the chart is clicked
            and it should be requested.
         */
        if (requestSegmentsData && (requestSegmentsData !== prevRequestSegmentsData)) {
            this.getListData();
        }

        const serviceInitialized = Boolean(this.state.scrollWrapperService);
        const menuStateDifferent = (prevProps.menuExpand !== this.props.menuExpand) || (prevProps.filtersOn !== this.props.filtersOn);

        if (serviceInitialized && menuStateDifferent) {
            setTimeout(() => {
                const scrollWrapperHeight = this.state.scrollWrapperService.calculateScrollWrapperHeight();

                this.setState({ scrollWrapperHeight });
            }, 500);
        }
    }

    getUserSegments = async () => {
        const {
            setCustomSegments,
        } = this.props;
        try {
            let customSegments = this.props.segments.reduce((acc, sr) => {
                acc.push(...sr.segments);
                return acc;
            }, [])
            customSegments = customSegments.filter(obj => obj?.isActive === true)
            await setCustomSegments(customSegments);
        } catch (err) {
            console.log(err);
        }
    }

    getAllSegments = () => {
        const {
            filters,
            setSegments,
            disableSegments,
        } = this.props;
        const allSegments = initData.get2x2AllSegments(filters);
        const disabledSegmentIds = allSegments.reduce((acc, segment, i) => {
            const { id } = segment;

            return i >= this.maxVisibleByDefault && id ? [...acc, id] : acc;
        }, []);
        setSegments(allSegments);
        disabledSegmentIds.length && disableSegments(disabledSegmentIds);
    }

    componentWillUnmount() {
        const {
            clearAllTwoByTwoData,
            clearAllTrendsData
        } = this.props;

        clearAllTwoByTwoData();
        clearAllTrendsData();
        window.removeEventListener('resize', this.onResize);
    }

    initializeAndUseScrollWrapperService = () => {
        const scrollWrapperService = new EquityScrollWrapperHeightService(selectors);
        const scrollWrapperHeight = scrollWrapperService.calculateScrollWrapperHeight();

        this.setState({
            scrollWrapperService,
            scrollWrapperHeight,
        });
    };

    onResize = () => {
        setTimeout(() => {
            const scrollWrapperHeight = this.state.scrollWrapperService.calculateScrollWrapperHeight();

            this.setState({ scrollWrapperHeight });
        }, 500);
    };

    requestMoreSegmentsData = () => this.setState({
        requestSegmentsData: true,
    });

    emulateLoading = (loading, readyShow) => this.setState({
        loading,
        readyShow,
    });
    // Implementation of Draggable and Reordering of the Segments with React-beautiful-dnd 
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = async (result) => {
        try {
            if (!result.destination) {
                return;
            }
            clearTimeout(this.debounceTimer);
            let deactivearr = [];
       
            const activearr = this.reorder(
                this.state.brandData,            
                result.source.index,
                result.destination.index
            );
            this.setState({ brandData:activearr});
            this.props.customSegmentList.map(obj=>{ if(obj.isActive === false) deactivearr.unshift(obj)})
            let finalArry=[...activearr,...deactivearr]

            const data = await finalArry.map((b, i) => {
                b.segment?b.segment.position=i+1:b.position=i+1
                return b;
            });
            const myUserInfo = getLocalStorage('userInfo');
            const userId = myUserInfo?JSON.parse(myUserInfo).id:'USER';
            let checkData = [],sortedData = [];
            
            await data.forEach(item => {
                sortedData.push(item.segment?item.segment:item) 
                checkData.push({ entityId: item.segment?item.segment.segmentId:item.segmentId,
                    color: item.segment?item.segment.color:item.color,
                    position: item.segment?item.segment.position:item.position,
                })
            })
            let finalData = this.props.segments.filter(obj => this.state.brandData.map(
                obj1 => obj.segments.map(b => {
                    if (b.segmentId === obj1.segment.segmentId) {
                        b.position = obj1.segment.position
                    }
                })
            ))
            let resultdata = finalData.filter(ele => (ele.segments.sort((a, b) => a.position - b.position).map(sr => this.state.brandData.map(da => da.segment.segmentId === sr.segmentId))))
            setSessionStorage('segmentsList',JSON.stringify(resultdata))

        } catch (error) {
            console.log('Updating  error', error);
        };
    }

    getListStyle = (isDraggingOver, itemsLength) => ({
        background: (isDraggingOver && this.props.selectedTab != 5) ? "white" : '',
        flexGrow: 1,
        marginRight: isDraggingOver ? "200px" : "0px"
    });

    //End of the Draggable Implementation

    LightenDarkenColor(col, amt) {
        var usePound = false;
        if(col){
            if (col[0] == "#") {
                col = col.slice(1);
                usePound = true;
            }
        }
        var num = parseInt(col,16);
        var r = (num >> 16) + amt;
        if (r > 255) r = 255;
        else if  (r < 0) r = 0;
        var b = ((num >> 8) & 0x00FF) + amt;
        if (b > 255) b = 255;
        else if  (b < 0) b = 0;
        var g = (num & 0x0000FF) + amt;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }
    
    getColors = (color) => {
        if (color) {
            const newColors = color.reduce((acc, next) => {
                const colorCode = this.LightenDarkenColor(next, 80)
                let newAcc = [...acc];
                newAcc.push(next, colorCode)
                return newAcc;
            }, [])
            const colors = newColors
            return colors;
        }
    }

    render() {
        const { brandData, loading, readyShow, variableData } = this.state;
        const {
            selectedTab,
            selectedBrand,
            brands,
            horizontalMenu,
            selectedPeriod,
            startdate,
            enddate,
            lineOption,
            onClickShowOrHide,
            onChangeLineOption,
            onClickFilterFunction,
            onSelectVariableMainFilter,
            onSelectVariableFilter,
            onSelectVariableGroup,
            currentVariableFilter,
            currentVariableGroup,
            currentVariableMainFilter,
            variableGroupOptions,
            mainVariableFilterOptions,
            variableFilterOptions,
            variableOptionOpen,
            setVariableOptionOpen,
            main2x2FilterOptions,
            current2x2MainFilter,
            option2x2Open,
            set2x2OptionOpen,
            onSelect2x2MainFilter,
            axisSelectOptionChange,
            xAxisSelect,
            yAxisSelect,
            zAxisSelect,
            statTestingEnabled,
            page,
            showAdminPanelMessage,
            useExposed,
            downloadexport,
            selectedCampaign
        } = this.props;
        const profileTabIndex = 5;
        let parsedCustomSegmentList = [];
        let segmentData = JSON.parse(getSessionStorage('segmentsList'));
        let customSegmentList = segmentData?.reduce((acc, sr) => {
            acc.push(...sr.segments);
            return acc;
        }, [])
        const segments = customSegmentList?.filter(item => item?.isActive === true && item?.segmentMarkets?.includes(selectedCampaign.marketName))
        const segmentsCount = customSegmentList;
        if(segments?.length > 0){
          parsedCustomSegmentList = filtersTransformations.parseSegmentsFilters(segments, this.filters);
          if(selectedCampaign?.countryCode === 'HSS'){
            parsedCustomSegmentList.forEach(item =>{ if(item.segmentName === "Age 18+") item.segmentName = "Age 35+"});
            variableData.forEach(item =>{ if(item.inputName === "Age 18+") item.inputName = "Age 35+"});
            brandData.forEach(ele=>{ if(ele?.segment?.segmentName === 'Age 18+') ele.segment.segmentName = 'Age 35+'});
          }
        }
        let chartContent = [];
        if (horizontalMenu.toLowerCase() === 'secondary' && (selectedTab !== 4 && selectedTab !== 5)) {
            chartContent.push(
                <BarChart
                    key='barChart'
                    selectedTab={selectedTab}
                    data={brandData.filter(b => { return b.tab === selectedTab && b.brand.id === brands[selectedBrand].id && b.segment.isActive === true })}
                    isDetailed={true}
                    statTestingEnabled={statTestingEnabled}
                    page={page}
                    cperiod={selectedPeriod > 1 ? initData.getPeriodOptions()?.find(({id})=> id === selectedPeriod)?.name : startdate + ' to ' + enddate}
                    getList={this.props.getSegments}
                    hiddenList={this.props.hiddenList}
                    selectedMarket={selectedCampaign}
                    emulateLoading={this.emulateLoading}
                />
            );
        } else {
            switch (selectedTab) {
                default:
                case 0:
                    let equityData = brandData.filter(b => { return b.tab === 0 && b.brand?.id === brands[selectedBrand]?.id && b.segment.isActive=== true}).sort((x, y) => { return y.data.number - x.data.number });
                    equityData.forEach((bData, index) => {
                        chartContent.push(
                            <Draggable key={bData.segment.segmentId} draggableId={`${bData.segment.segmentId}`} index={index}>
                                {(provided, snapshot) => (
                                    <div className='drag'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <EquityCard
                                            key={"eq" + index}
                                            tag={"eq" + index}
                                            id={index}
                                            segments={brandData}
                                            brand={bData.brand?.displayName ? bData.brand?.displayName : bData.brand?.name}
                                            logo={bData.brand?.logo}
                                            data={bData?.data.data}
                                            colors={initData.getDonutColors()}
                                            chartTitle={'EQUITY'}
                                            number={bData.data.value}
                                            upDown={bData.data.upDown}
                                            upDownNA={bData.data.upDownNA}
                                            segment={bData.segment}
                                            pool={bData?.data.pool}
                                            isDetailed={true}
                                            onClickSegment={() => this.props.onClickSegment(bData.segment)}
                                            cperiod={selectedPeriod > 1 ? initData.getPeriodOptions()?.find(({id})=> id === selectedPeriod)?.name : startdate + ' to ' + enddate}
                                            statTestingEnabled={statTestingEnabled}
                                            higherThan={bData.higherThan}
                                            lowerThan={bData.lowerThan}
                                            page={page}
                                            selectedTab={selectedTab}
                                            selectedMarket={selectedCampaign?.countryCode}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        );
                    });
                    break;
                case 1:
                    brandData.filter(b => { return b.tab === 1 && b.brand.id === brands[selectedBrand].id && b.segment.isActive === true }).forEach((bData, index) => {
                        let data = [];
                        data.push(bData.segment.color);
                        chartContent.push(
                            <Draggable key={bData.segment.segmentId} draggableId={`${bData.segment.segmentId}`} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <FunnelChartCard
                                            key={'cv' + index}
                                            tag={'cv' + index}
                                            id={index}
                                            data={bData.data.data}
                                            colors={this.getColors(data)}
                                            brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                            logo={bData.brand.logo}
                                            brandValue={bData.data.pool}
                                            hightRatio={0.8}
                                            segment={bData.segment}
                                            onClickSegment={() => this.props.onClickSegment(bData.segment)}
                                            lowerThan={bData.lowerThan}
                                            higherThan={bData.higherThan}
                                            statTestingEnabled={statTestingEnabled}
                                            page={page}
                                            selectedMarket={selectedCampaign?.countryCode}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        );
                    });
                    break;
                case 2:
                    brandData.filter(b => { return b?.tab === 2 && b.brand.id === brands[selectedBrand].id && b.segment.isActive === true }).forEach((bData, index) => {
                        let data = [];
                        data.push(bData.segment.color);
                        chartContent.push(
                            <Draggable key={bData.segment.segmentId} draggableId={`${bData.segment.segmentId}`} index={index}>
                                {(provided, snapshot) => (
                                    <div  style={{transition:'transform 0.2s;'}}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <EmotionalCard
                                            key={'em' + index}
                                            label="emotional"
                                            brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                            logo={bData.brand.logo}
                                            brandValue={bData.data.pool}
                                            data={bData.data.data}
                                            colors={this.getColors(data)}
                                            segment={bData.segment}
                                            onClickSegment={() => this.props.onClickSegment(bData.segment)}
                                            lowerThan={bData.lowerThan}
                                            higherThan={bData.higherThan}
                                            statTestingEnabled={statTestingEnabled}
                                            page={page}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        );
                    });
                    break;
                case 3:
                    brandData.filter(b=>{return b?.tab===3 &&b.brand.id === brands[selectedBrand].id && b.segment.isActive === true }).forEach( (bData, index)=> {
                        let data=[bData.segment.color,'#DFE2EA'];
                        chartContent.push(                
                            <Draggable key={bData.segment.segmentId} draggableId={`${bData.segment.segmentId}`} index={index}>
                                {(provided, snapshot) => (
                                <div className='advertisingDragContainer'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <AdvertisingCard 
                                        key={"eq" + index}
                                        tag={"eq" + index}
                                        id={index}
                                        label="advertising"
                                        segments={brandData}
                                        brand={bData.brand.displayName ? bData.brand.displayName : bData.brand.name}
                                        logo={bData.brand.logo}
                                        data={bData.data.data}
                                        colors={data}
                                        chartTitle={'AD RECALL'}
                                        number={bData?.data?.data?.filter(item => item.label === "advertisingRecall")[0]?.value}
                                        segment={bData.segment}
                                        upDown={bData.data.upDown}
                                        upDownNA={bData.data.upDownNA}
                                        pool={bData.data.pool}
                                        isDetailed={true}
                                        onClickSegment={() => this.props.onClickSegment(bData.segment)}
                                        cperiod={selectedPeriod > 1 ? initData.getPeriodOptions()?.find(({id})=> id === selectedPeriod)?.name : startdate + ' to ' + enddate}
                                        statTestingEnabled={statTestingEnabled}
                                        higherThan={bData.higherThan}
                                        lowerThan={bData.lowerThan}
                                        page={page}
                                        selectedTab={selectedTab}
                                        useExposed={useExposed}
                                    />
                                </div>
                                )}
                            </Draggable>
                        );
                    });
                    break;
                case 4:
                    chartContent.push(
                        <LineChart
                            getTendsOptionsData={this.getTendsOptionsData}
                            key="lineChart"
                            interval={this.props.interval}
                            isDetailed={true}
                            page={page}
                            stab={lineOption.stab}
                            selectedOption={lineOption.selectedOption}
                            selectedScoreOption={lineOption.selectedScoreOption}
                            onChangeLineOption={typeof onChangeLineOption === 'function' ? onChangeLineOption : null}
                            statTestingEnabled={statTestingEnabled}
                            emulateLoading={this.emulateLoading}
                            axisSelectOptionChange={axisSelectOptionChange}
                            onChangeFunction={this.props.onChangePeriodFunction}
                            startdate={this.props.selectedDate.startdate}
                            enddate={this.props.selectedDate.enddate}
                            selectedPeriod={this.props.selectedDate.selectedPeriod}
                            dateFilterId={this.props.dateFilterId}
                            yAxisSelect={yAxisSelect}
                            downloadexport={downloadexport}
                            selectedMarket={selectedCampaign}
                            getList={this.props.getSegments}
                            selectedTab={selectedTab}
                        />
                    );
                    break;
                case 5:
                    chartContent.push(
                        <StackedBarChart
                            key={'StackedBarChart'}
                            chartType={'segments'}
                            data={variableData}
                            rowsData={parsedCustomSegmentList.map(item =>item)}
                            onClickFilterFunction={onClickFilterFunction}
                            onSelectVariableMainFilter={onSelectVariableMainFilter}
                            onSelectVariableFilter={onSelectVariableFilter}
                            onSelectVariableGroup={onSelectVariableGroup}
                            currentVariableFilter={currentVariableFilter}
                            currentVariableGroup={currentVariableGroup}
                            currentVariableMainFilter={currentVariableMainFilter}
                            // variableSubfilterOptions={variableSubfilterOptions}
                            mainVariableFilterOptions={mainVariableFilterOptions}
                            variableGroupOptions={variableGroupOptions}
                            variableFilterOptions={variableFilterOptions}
                            variableOptionOpen={variableOptionOpen}
                            setVariableOptionOpen={setVariableOptionOpen}
                            statTestingEnabled={statTestingEnabled}
                        />
                    );
                    break;
                case 6:
                    chartContent.push(
                        <TwoByTwoChart
                            key={'TwoByTwoChart'}
                            main2x2FilterOptions={main2x2FilterOptions}
                            current2x2MainFilter={current2x2MainFilter}
                            option2x2Open={option2x2Open}
                            set2x2OptionOpen={set2x2OptionOpen}
                            onSelect2x2MainFilter={onSelect2x2MainFilter}
                            isDetailed={true}
                            page={page}
                            onClickFilterFunction={onClickFilterFunction}
                            onClickShowOrHide={typeof onClickShowOrHide === 'function' ? onClickShowOrHide : null}
                            axisSelectOptionChange={axisSelectOptionChange}
                            xAxisSelect={xAxisSelect}
                            yAxisSelect={yAxisSelect}
                            zAxisSelect={zAxisSelect}
                            requestMoreSegmentsData={this.requestMoreSegmentsData}
                            showAdminPanelMessage={showAdminPanelMessage}
                        />
                    );
                    break;
            }
        }

        return (
            <div ref={this.wrapperRef} style={{overflowX:(this.props.selectedTab == 5 || (horizontalMenu === 'secondary' && selectedTab !== 4))?"inherit":"auto",display:"flex",backgroundColor: '#F5F7FA'}}>            
                {/* {sessionTimeoutErr && <SessionTimeoutModal />} */}
                <DragDropContext onDragEnd={this.onDragEnd} style={{transition:'transform 0.2s;'}}>
                    <Droppable droppableId="droppable" direction="horizontal"  style={{transition:'transform 0.2s;'}}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={this.getListStyle(snapshot.isDraggingOver, this.state.brandData.length)}
                                {...provided.droppableProps}
                            >
                                <div
                                    className={cx({
                                        scrollWrapper: selectedTab !== profileTabIndex,
                                    })}
                                    style={selectedTab !== profileTabIndex
                                        ? { height: this.state.scrollWrapperHeight }
                                        : {}
                                    }
                                >
                                    <div className={
                                        cx(
                                            "dataItemsContainer",
                                            {
                                                "loading": loading,
                                                "scrollBarHidden": selectedTab === 4
                                            }
                                        )
                                    }
                                    >
                                        {loading && <LoadingLayer />}
                                        {readyShow && chartContent}
                                        {horizontalMenu !== 'secondary' && selectedTab !== 4 && selectedTab !== 5 && selectedTab !== 6 && segmentsCount?.length < 75 &&<div className="createCustomContainer">
                                            <div className="contentContainer">
                                                <div className="iconContainer" onClick={this.props.onClickFilter}>
                                                    <img className="plusIcon" src={plusIcon} alt="" />
                                                </div>
                                                <div>
                                                    Create custom segments to compare your data
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }
}
const mapStateToProps = ({ twoByTwoChart }) => ({
    allSegmentList: twoByTwoChart.allSegmentList,
    customSegmentList: twoByTwoChart.customSegmentList,
    hiddenSegmentIdList: twoByTwoChart.hiddenSegmentIdList,
    hiddenCustomSegmentIdList: twoByTwoChart.hiddenCustomSegmentIdList,
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        setSegments,
        setCustomSegments,
        disableSegments,
        disableCustomSegments,
        setTwoByTwoData,
        clearAllTwoByTwoData,
        setTrendsData,
        clearAllTrendsData,
    }, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(DetailedIntelligenceHomeComponent);