import React, { Component } from "react";
import "./Views.scss";
import ReportsListComponent from "./ReportsListComponent";
import ViewsReorderableList from "./ViewsReorderableList";

class ViewsHomeComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { selectedTab } = this.props;
    return (
      <div className="scrollWrapper">
        <div className="dataItemsContainer">
          {selectedTab === 0 && <ReportsListComponent data={this.props} />}
          {selectedTab === 1 && <ViewsReorderableList />}
        </div>
      </div>
    );
  }
}
export default ViewsHomeComponent;
