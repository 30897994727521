import { FILE_STATUS, REQUEST_TIMED_OUT } from "./constant";

function createNewFileData({ fileName, jobId, screenType, fileType }) {
  return {
    fileName,
    jobId,
    screenType,
    status: FILE_STATUS.PENDING,
    errorMessage: null,
    downloadUrl: "",
    fileType: fileType || "",
    date: new Date(),
  };
}

function getRequestTimeOutMessageUpdated(file) {
  return {
    ...file,
    errorMessage: REQUEST_TIMED_OUT,
    status: FILE_STATUS.FAILED,
  };
}

export { createNewFileData, getRequestTimeOutMessageUpdated };
