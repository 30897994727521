import React from "react";
import Modal from "react-responsive-modal";
import "./detailModal.scss";

function DetailModal({ children, ...props }) {
  return (
    <Modal
      {...props}
      classNames={{
        root: "views_detail_modal_root",
        modal: "views_detail_modal",
        modalAnimationIn: "react-responsive-modal-from-right-in",
        modalAnimationOut: "react-responsive-modal-from-right-out",
      }}
    >
      {children}
    </Modal>
  );
}

export default DetailModal;
