import { getRequestTimeOutMessageUpdated } from "../../components/FileDownload/utils";
import { FILE_STATUS } from "../../components/FileDownload/utils/constant";
import combineEvents from "../../utils/combineEvents";
import {
  ADD_FILE_DATA_TO_DOWNLOAD,
  REMOVE_FILE_DATA_FROM_DOWNLOAD,
  UPDATE_FILE_DATA_IN_DOWNLOAD,
  UPDATE_FILE_DOWNLOAD_TIMEOUT,
  UPDATE_FILE_PROGRESS_IN_DOWNLOAD,
} from "./actions";

const initialState = {
  downloadFileList: {
    ids: [],
    values: {},
  },
  progressUpdate: {},
};

export default combineEvents(
  {
    [ADD_FILE_DATA_TO_DOWNLOAD]: (state, { jobId, data }) => {
      const { downloadFileList, progressUpdate } = state;
      const { ids, values } = downloadFileList;
      const newIds = [...ids, jobId];
      const updatedValues = {
        ...values,
        [jobId]: data,
      };
      return {
        ...state,
        downloadFileList: {
          ids: newIds,
          values: updatedValues,
        },
        progressUpdate: {
          ...progressUpdate,
          [jobId]: 5,
        },
      };
    },
    [REMOVE_FILE_DATA_FROM_DOWNLOAD]: (state, { jobId }) => {
      const { ids, values } = state.downloadFileList;
      const newIds = ids?.filter((id) => id !== jobId);
      delete values[jobId];
      return {
        ...state,
        downloadFileList: {
          ids: newIds,
          values,
        },
      };
    },
    [UPDATE_FILE_DATA_IN_DOWNLOAD]: (state, { jobId, data }) => {
      const { progressUpdate, downloadFileList } = state;
      const { values } = downloadFileList;
      values[jobId] = {
        ...values[jobId],
        ...data,
      };
      if (
        data.status === FILE_STATUS.SUCCESS ||
        data.status === FILE_STATUS.FAILED
      ) {
        return {
          ...state,
          downloadFileList: {
            ...downloadFileList,
            values,
          },
          progressUpdate: {
            ...progressUpdate,
            [jobId]: 100,
          },
        };
      }
      return {
        ...state,
        downloadFileList: {
          ...state.downloadFileList,
          values,
        },
      };
    },
    [UPDATE_FILE_PROGRESS_IN_DOWNLOAD]: (state, { jobId, intervalId }) => {
      const { progressUpdate, downloadFileList } = state;
      const { values } = downloadFileList;
      if (
        values[jobId].status === FILE_STATUS.PENDING &&
        progressUpdate[jobId] < 92
      ) {
        return {
          ...state,
          progressUpdate: {
            ...progressUpdate,
            [jobId]: progressUpdate[jobId] + 0.5,
          },
        };
      }
      clearInterval(intervalId);
      return state;
    },
    [UPDATE_FILE_DOWNLOAD_TIMEOUT]: (state, { jobId }) => {
      const { downloadFileList, progressUpdate } = state;
      const { values } = downloadFileList;
      const currentFile = values[jobId];
      if (currentFile.status === FILE_STATUS.PENDING) {
        const updatedFile = getRequestTimeOutMessageUpdated(currentFile);
        return {
          ...state,
          downloadFileList: {
            ...downloadFileList,
            values: {
              ...values,
              [jobId]: updatedFile,
            },
          },
          progressUpdate: {
            ...progressUpdate,
            [jobId]: 100,
          },
        };
      }
      return state;
    },
  },
  initialState
);
