import lookup from "country-code-lookup";
import EquityIcon from "../../../../assets/icons/icon_views_equity.svg";
import ConversionIcon from "../../../../assets/icons/icon_views_conversion.svg";
import TrendsIcon from "../../../../assets/icons/icon_views_trends.svg";
import EmotionalIcon from "../../../../assets/icons/icon_views_emotional.svg";

const MODULE_DISPLAY_MAPPING = {
  DETAILED: "Segments",
  SUMMARY: "Summary",
  COMPETITIVE: "Competitive",
  GLOBAL: "Global",
  ADVANCED: "Advanced",
};

const PERIOD_DISPLAY_NAME_MAPPING = {
  CUSTOM: "Custom",
  LAST_180_DAYS: "Last 180 days",
  LAST_90_DAYS: "Last 90 days",
  LAST_30_DAYS: "Last 30 days",
  YEAR_TO_DATE: "Year to date",
};

function getModuleDisplayName(module) {
  return MODULE_DISPLAY_MAPPING[module];
}

function getPeriodDisplayName(period) {
  return PERIOD_DISPLAY_NAME_MAPPING[period];
}

const SCREEN_TYPE_DETAIL = {
  SUMMARY: {
    screenType: "SUMMARY",
    displayName: "Summary",
    icon: EquityIcon,
  },
  EQUITY: {
    screenType: "EQUITY",
    displayName: "Equity",
    icon: EquityIcon,
  },
  CONVERSION: {
    screenType: "CONVERSION",
    displayName: "Conversion",
    icon: ConversionIcon,
  },
  TREND: {
    screenType: "TRENDS",
    displayName: "Trends",
    icon: TrendsIcon,
  },
  EMOTIONAL: {
    screenType: "EMOTIONAL",
    displayName: "Emotional",
    icon: EmotionalIcon,
  },
  ADVERTISING: {
    screenType: "ADVERTISING",
    displayName: "Advertising",
    icon: EquityIcon,
  },
  PROFILE: {
    screenType: "PROFILE",
    displayName: "Profile",
    icon: EmotionalIcon,
  },
  ALL: {
    screenType: "ALL",
    displayName: "All",
    icon: EquityIcon,
  },
  DIFFERENTIATION: {
    screenType: "DIFFERENTIATION",
    displayName: "Differentiation",
    icon: ConversionIcon,
  },
  PERCEPTUAL: {
    screenType: "PERCEPTUAL",
    displayName: "Perceptual",
    icon: EquityIcon,
  },
  PREPOST: {
    screenType: "PREPOST",
    displayName: "Pre post",
    icon: EmotionalIcon,
  },
};

function getScreenTypeDetail(screenType) {
  return SCREEN_TYPE_DETAIL[screenType];
}

function getCountryCodeWithMarketName(market) {
  let countryCode = lookup.countries.filter(
    (obj) =>
      obj?.country === market?.displayName || obj?.internet === market?.code
  );
  return (countryCode = countryCode[0]?.internet
    ? countryCode[0]?.internet.toLowerCase()
    : market?.code.startsWith("HSS")
    ? "us"
    : countryCode[0]?.internet);
}

function getSelectViewURL(module, screenType, url) {
  const viewUrl = new URL(
    `${window.location.protocol}//${
      window.location.host
    }/${MODULE_DISPLAY_MAPPING[
      module
    ]?.toLowerCase()}/${screenType?.toLowerCase()}${url}`
  );
  return viewUrl;
}

export {
  getModuleDisplayName,
  getPeriodDisplayName,
  getScreenTypeDetail,
  getCountryCodeWithMarketName,
  getSelectViewURL,
};
