import {
  ADD_FILE_DATA_TO_DOWNLOAD,
  REMOVE_FILE_DATA_FROM_DOWNLOAD,
  UPDATE_FILE_DATA_IN_DOWNLOAD,
  UPDATE_FILE_DOWNLOAD_TIMEOUT,
  UPDATE_FILE_PROGRESS_IN_DOWNLOAD,
} from "./actions";

export const addFileToDownload = (jobId, data) => ({
  type: ADD_FILE_DATA_TO_DOWNLOAD,
  jobId,
  data,
});

export const removeFileFromDownload = (jobId) => ({
  type: REMOVE_FILE_DATA_FROM_DOWNLOAD,
  jobId,
});

export const updateFileInDownload = (jobId, data) => ({
  type: UPDATE_FILE_DATA_IN_DOWNLOAD,
  jobId,
  data,
});

export const updateFileProgressInDowload = (jobId, intervalId) => ({
  type: UPDATE_FILE_PROGRESS_IN_DOWNLOAD,
  jobId,
  intervalId,
});

export const updateFileDownloadRequestTimeout = (jobId) => ({
  type: UPDATE_FILE_DOWNLOAD_TIMEOUT,
  jobId,
});
