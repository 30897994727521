import styled from "styled-components";
import { TEXT_BOLD } from "../utils/constant";

const StyledEntityListImageView = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: "Inter", serif;
  display: flex;
  li {
    display: flex;
    align-items: center;
    width: 24px;
    height: 32px;
    text-align: center;
    img {
      min-height: 30px;
      min-width: 30px;
      border: 3px solid white;
      border-radius: 50%;
    }
    .more-count {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 32px;
      font-size: 12px;
      min-width: 32px;
      color: ${TEXT_BOLD};
      font-weight: 500;
      background-color: #f2f4f7;
      border-radius: 50%;
    }
  }
`;

export default StyledEntityListImageView;
