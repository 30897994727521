import React, { useState, useRef, useEffect } from "react";
import 'react-responsive-modal/styles.css';
import cx from 'classnames';
import { IoWarningOutline } from "react-icons/io5";
import { RiCloseLine } from "react-icons/ri";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import "./WarningModal.scss";
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { updateURLParams } from "../../../utils/URLParamsValidations";
import ApiData from "../../../utils/Api";
import initData from "../../../utils/initData";
import { getSessionStorage, setLocalStorage, setSessionStorage } from "../../../utils/storageHelpers";
import { safeParseJSONLocalStorage, safeParseJSONSessionStorage } from "../../../utils/getBrowserStorageData";
import filtersTransformations from "../../../utils/filterTransformations";
import { segmentsList } from "../../../utils/common";
import cubePlus from "../../../assets/icons/cube-plus.svg";

const WarningModal = (props) => {
    let {expiredFilters, inValidORSharedSegments, page, setSegmentsData, selectedCampaign, updateSharedSegments, selectedGlobalBrand, selectedMarketIds, invalidBrandsforUser, invalidMarketsforUser} = props;
    const segmentsMaxLimit = 75;
    const modalRef = useRef(null);

    let [ step, setStep ] = useState(0);
    let [ isOpen, setIsOpen ] = useState(true);
    let [ sharedSegmentsData, setSharedSegmentsData ] = useState([]);
    let [ isSegmentsMaxLimit, setIsSegmentsMaxLimit ] = useState(false);
    let [ expiredorDetachedSegmentsList, setExpiredorDetachedSegmentsList ] = useState([]);
    let [ filteredSegments, setFilteredSegments ] = useState([]);

    useEffect(()=>{
        inValidORSharedSegments = inValidORSharedSegments?.filter(val => val != undefined);
        inValidORSharedSegments?.length> 0 && !(sharedSegmentsData?.length) && validateSegments(inValidORSharedSegments);
        let segments = safeParseJSONSessionStorage('segmentsList');
        filteredSegments = segmentsList(segments); 
        filteredSegments?.length > 0 && setFilteredSegments(filteredSegments);
    },[inValidORSharedSegments]);

    const validateSegments = async(segmentsIds) =>{
        let response = await ApiData.validateSegments({segmentIds:segmentsIds,geoIds: selectedMarketIds});
        let expiredorDetachedSegmentsList = response?.filter(({expiredFilters, detachedFilters, segmentId, segmentMarkets})=>{
            const filtersPresentInMarket = filtersTransformations.checkFilterPresentInMarket(page, segmentMarkets, selectedCampaign?.marketName, selectedGlobalBrand);
            if((expiredFilters?.length>0 || detachedFilters?.length>0) && filtersPresentInMarket){
               return segmentId;
            }
        });
        response = response.filter(ele=> ele.segmentName != 'Age 18+');
        setExpiredorDetachedSegmentsList(expiredorDetachedSegmentsList);
        response = response?.filter(ele=>{
            let {segmentMarkets, module} = ele;
            const filtersPresentInMarket = filtersTransformations.checkFilterPresentInMarket(page, segmentMarkets, selectedCampaign?.marketName, selectedGlobalBrand);
            if(filtersPresentInMarket){
                if(module === "GLOBAL" && page === "global")return ele;
                else if(page !== "global" && module !== "GLOBAL") return ele;
            }
        });
        if(expiredorDetachedSegmentsList?.length === 0){
            let validSegments = response?.filter(({expiredFilters, detachedFilters}) => !(expiredFilters?.length > 0  && detachedFilters?.length >0));
            setIsSegmentsMaxLimit(filteredSegments?.length + validSegments?.length > segmentsMaxLimit);
            response = response.map(obj=>{
                if(filteredSegments?.length == segmentsMaxLimit) obj.disabled = true;
                return obj;
            })
        }
        setSharedSegmentsData(response);

    }

    const addSharedSegments = async() => {
        //get URL segments
        let localUrl = safeParseJSONSessionStorage("params");
        const urlParams = new URLSearchParams(localUrl?localUrl:window.location.search);
        let segmentIds = urlParams.getAll('segments');

        let validSegments = sharedSegmentsData.filter(({expiredFilters, isActive})=> !expiredFilters?.length && isActive).map(({segmentId})=>segmentId);
        if(validSegments?.length > 0){
            let payload = {
                segmentIds: validSegments,
                module: initData.getModuleType(page),
                geoIds: selectedMarketIds
            }
            let addedSegmentsData = await ApiData.addSharedSegments(payload);

            let updatedSegmentsList = segmentsList(addedSegmentsData);
            let sessionSegments = safeParseJSONSessionStorage('segmentsList');
            let sharedCategory = addedSegmentsData?.filter(ele=> ele?.categoryName === 'Shared');
            let findSharedCategory = sessionSegments?.filter(ele=> ele?.categoryName === 'Shared');
            let sharedCategoryIndex = sessionSegments?.findIndex(ele=> ele?.categoryName == 'Shared');
            if(findSharedCategory?.length > 0 ){
                let sharedSegments = updatedSegmentsList.filter(ele => validSegments.some(segmentId=> segmentId == ele.segmentId));
                let selectedSegments = segmentsList(sessionSegments)?.filter(({isActive}) => isActive === true)?.map(({segmentId})=>segmentId);
                if(selectedSegments?.length + sharedSegments?.length <= 20){
                    sharedSegments.map(ele=> ele.isActive = true);
                }
                sharedCategoryIndex > -1 && sessionSegments[sharedCategoryIndex].segments.push(...sharedSegments);
            }else{
                sharedCategory?.length > 0 && sessionSegments.unshift(...sharedCategory)
            }           
            if(page !== 'segments'){
                let filtersList =  safeParseJSONLocalStorage('filters');
                sessionSegments?.length> 0 && setSessionStorage('segmentsList',JSON.stringify(sessionSegments));

                validSegments.forEach(segmentId =>{
                    let findSharedCategory = addedSegmentsData.find(ele=> ele.categoryName === 'Shared')
                    let findCategoryInFilters = filtersList.findIndex(ele => !ele.categoryId);
                    let findSegment = filtersList[findCategoryInFilters]?.filterResponse?.findIndex(ele => ele.filterId === segmentId);
                    let segmentsObj =  updatedSegmentsList.find(obj=> obj.segmentId === segmentId)
                    findSharedCategory && (findSharedCategory.segments = [segmentsObj]);
                    let newOrEditSegment = filtersTransformations.getFilterObject(findSharedCategory);

                    //Add shared segments as filters for CI,Global,Advanced
                    let cfilters = JSON.parse(getSessionStorage('applyFilters'));
                    let findCategoryIndex = cfilters?.findIndex(ele=>ele.categoryId === null);
                    if(findCategoryIndex < 0 ){
                        cfilters.push({
                            categoryId: null,
                            categoryName: findSharedCategory.categoryName, 
                            type: 'SEGMENT',
                            filters:[{
                                filterId: findSharedCategory.segments[0]?.segmentId,
                                filterName: findSharedCategory.segments[0]?.segmentName,
                                filterType: 'SEGMENT',
                                filterOptions: [{}]
                            }],
                            expanded: true
                        })
                    }else{
                        cfilters[findCategoryIndex].filters.push({
                            filterId: findSharedCategory.segments[0]?.segmentId,
                            filterName: findSharedCategory.segments[0]?.segmentName,
                            filterType: 'SEGMENT',
                            filterOptions: [{}]
                        })
                    }
                    setSessionStorage('applyFilters',JSON.stringify(cfilters))

                    //add shared segmets in filters list to apply as segment
                    if(findCategoryInFilters < 0 ){
                        filtersList.push({
                            categoryId: findSharedCategory?.categoryId,
                            categoryName: findSharedCategory?.categoryName,   
                            categoryOrder: 0,
                            categoryType: "USER",
                            filterResponse: [newOrEditSegment]
                        })
                    }
                    if(findCategoryInFilters > -1){
                        if(findSegment > -1){
                            findCategoryInFilters > -1 && (filtersList[findCategoryInFilters].filterResponse[findSegment] = newOrEditSegment);
                        }else{
                            findCategoryInFilters > -1 && filtersList[findCategoryInFilters].filterResponse.push(newOrEditSegment);
                        }
                    }else{
                        findCategoryInFilters > -1 && filtersList[findCategoryInFilters].filterResponse.push(newOrEditSegment);
                    }
                })
                await setLocalStorage('filters', JSON.stringify(filtersList));
                updateURLParams({segments: segmentIds},['segments']);
                updateSharedSegments(sessionSegments, filtersList);
            }else{
                //changing the segments status as active based on url segments
                let segments = sessionSegments?.reduce((acc, sr) => {
                    validSegments?.length> 0 &&  sr.segments.forEach(obj=> {
                        let {segmentId, segmentName} = obj;
                        if(segmentIds?.includes(segmentId?.toString()))obj.isActive = true; 
                        else if(!(segmentName === 'Age 18+' || segmentName === 'Age 35+'))obj.isActive = false; 
                    });
                    acc.push(...sr?.segments);
                    return acc;
                }, []);
                let selectedSegments = segments?.filter(({isActive, segmentMarkets}) => isActive === true && segmentMarkets?.includes(selectedCampaign?.marketName))?.map(({segmentId})=>segmentId);
                sessionSegments?.length> 0 && setSessionStorage('segmentsList',JSON.stringify(sessionSegments));
    
                setSegmentsData(sessionSegments);
                updateURLParams({segments: selectedSegments},['segments']);
            }
        }
        setIsOpen(!isOpen);
    }

    const updateUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if(expiredFilters?.length > 0){
            let filterIds = urlParams?.getAll('filters')?.filter(id=>!expiredFilters.some(({filterOptionId}) => filterOptionId == id));
            filterIds?.length > 0 && updateURLParams({filters:filterIds},['filters']);
        }
        if(invalidBrandsforUser?.length > 0){
            let brandIds = urlParams?.getAll('brands')?.filter(brandId=>!invalidBrandsforUser.some((id => id == brandId)));
            brandIds?.length > 0 && updateURLParams({brands:brandIds},['brands']);    
        }
        setIsOpen(!isOpen);
    }

    const onSelecteSegment = (ele) => {
        let validSegments = sharedSegmentsData?.filter(({expiredFilters, detachedFilters}) => !(expiredFilters?.length > 0  && detachedFilters?.length >0)).map(({segmentId})=>segmentId);
        let sharedSegmentsList = sharedSegmentsData.map(obj => {
                if(ele.segmentId === obj.segmentId) obj.isActive = !ele.isActive;
            return obj;
        })
        let activeSegments = sharedSegmentsData.filter(({expiredFilters, detachedFilters, isActive})=> !(expiredFilters?.length > 0  && detachedFilters?.length >0) && isActive).map(({segmentId})=>segmentId);
        sharedSegmentsList = sharedSegmentsData.map(obj => {
            if(obj.expiredFilters?.length === 0 && obj.detachedFilters?.length === 0){
                let disabledsegments = validSegments.filter(ele=> !activeSegments?.includes(ele))
                if(filteredSegments?.length + activeSegments?.length > segmentsMaxLimit ){
                    if(ele.segmentId === obj.segmentId){ 
                        obj.isActive = !obj.isActive;
                    }
                }
                if(filteredSegments?.length + activeSegments?.length < segmentsMaxLimit && validSegments.includes(obj.segmentId)) obj.disabled = false;
                if(filteredSegments?.length + activeSegments?.length == segmentsMaxLimit && disabledsegments.includes(obj.segmentId)) obj.disabled = true;
            }
            return obj;
        })
        setSharedSegmentsData(sharedSegmentsList);
    }

    const handleSegmentsLimit = () => {
        let validSegments = sharedSegmentsData?.filter(({expiredFilters, detachedFilters}) => !(expiredFilters?.length > 0  && detachedFilters?.length >0));
        setIsSegmentsMaxLimit(filteredSegments?.length + validSegments?.length > segmentsMaxLimit);

        let listData = sharedSegmentsData.map((obj,ind)=>{
            if(obj.expiredFilters?.length > 0 || obj.detachedFilters?.length > 0 || !obj.segmentMarkets?.includes(selectedCampaign?.marketName)){
                obj.disabled = true;
            }
            if(filteredSegments?.length == segmentsMaxLimit){
                obj.disabled = true;
            }
            return obj;
        });
        setSharedSegmentsData(listData);
        let allExpiredOrDetached = sharedSegmentsData.every(({expiredFilters, detachedFilters}) => (expiredFilters?.length>0 || detachedFilters?.length>0))
        if(allExpiredOrDetached) setIsOpen(!isOpen);
        else setStep(1);
    }

    const handleCancel = () => {
        if(!!expiredorDetachedSegmentsList?.length && step === 0) handleSegmentsLimit();
        else setIsOpen(!isOpen);
    }
    
    return (
        <>
            {(sharedSegmentsData?.length > 0 || invalidBrandsforUser?.length > 0 || invalidMarketsforUser?.length > 0) &&
                <Modal ref={modalRef} open={isOpen} onClose={handleCancel} initialFocusRef={modalRef} center closeIcon={<span style={{ display: 'none' }} />}
                classNames={{
                    modal: 'warning-modal',
                }}>
                    <>
                        <div className='modalHeader'>
                            {sharedSegmentsData?.length > 0 && 
                                <>
                                    <div className='modalHeaderContainer'>
                                        <div className='roundedLogo sharedSegmentsLogo'>
                                            {((!!expiredorDetachedSegmentsList?.length && step === 0) || isSegmentsMaxLimit)? 
                                                <IoWarningOutline className="warningIcon"/>:
                                                <img src={cubePlus} alt="" width={22} height={22} className="warningIcon"/>
                                            }
                                        </div>
                                        {((step === 1 && !isSegmentsMaxLimit) || (step === 0 && expiredorDetachedSegmentsList?.length === 0 && !isSegmentsMaxLimit )) && <div className='modalHeaderContent'> 
                                            <div className="modalTitle">New shared segments</div>
                                            <div className="modalSubTitle">You have been shared new segments.</div>
                                        </div>}
                                        {!!expiredorDetachedSegmentsList?.length && step === 0 && <div className='modalHeaderContent'> 
                                            <div className="modalTitle">Limited access</div>
                                            <div className="modalSubTitle">You may not have access to certain filters.</div>
                                        </div>}
                                        {isSegmentsMaxLimit &&<div className='modalHeaderContent'> 
                                            <div className="modalTitle">Limit reached</div>
                                            <div className="modalSubTitle">You have reached the maximum segments limit.</div>
                                        </div>}
                                    </div>
                                    <RiCloseLine className="warningIcon closeIcon" onClick={handleCancel}/>
                                </>
                            }
                            {invalidBrandsforUser?.length > 0 || invalidMarketsforUser?.length > 0 &&
                                <>
                                    <div className='modalHeaderContainer'>
                                        <div className='roundedLogo sharedSegmentsLogo'>
                                            <IoWarningOutline className="warningIcon"/>
                                        </div>
                                        <div className='modalHeaderContent'> 
                                            <div className="modalTitle">Limited access</div>
                                            <div className="modalSubTitle">You may not have access to certain {invalidBrandsforUser?.length > 0 ? 'brands': 'markets'}.</div>                                    
                                        </div>
                                    </div>
                                    <RiCloseLine className="warningIcon closeIcon" onClick={handleCancel}/>
                                </>
                            }
                        </div>
                        <div className="modalContent">
                            {sharedSegmentsData?.length>0 &&
                                <>
                                    {!!expiredorDetachedSegmentsList?.length && step === 0 &&
                                        <>
                                            <div className="modalContentText">The following filters are unavailable in your account.</div>
                                            <div className="modalListContainer">
                                                {sharedSegmentsData?.map(({expiredFilters,detachedFilters, filters})=>{
                                                    if(expiredFilters?.length > 0 || detachedFilters?.length > 0){
                                                        return filters.map(({filterName, filterOptions})=>{
                                                            if(expiredFilters.includes(filterName)|| detachedFilters.includes(filterName)){
                                                                return filterOptions.map(({filterOptionName})=> {
                                                                    return(
                                                                        <li>{filterName} / {filterOptionName} </li>
                                                                    )
                                                                })
                                                            }
                                                        })                                        
                                                    }
                                                })} 
                                            </div>
                                        </> 
                                    } 
                                    {isSegmentsMaxLimit && 
                                    <>
                                        {filteredSegments?.length != segmentsMaxLimit ?
                                            <div className="modalContentText">You have used <span style={{fontWeight:'500'}}>{filteredSegments?.length} out of {segmentsMaxLimit}</span> segments.You can create <span style={{fontWeight:'500'}}>{segmentsMaxLimit-filteredSegments?.length}</span> more from the shared segments below.</div>
                                            :
                                            <div className="modalContentText">You have used <span style={{fontWeight:'500'}}>{filteredSegments?.length} out of {segmentsMaxLimit}</span> segments.You can't create from the shared segments below.</div>
                                        }
                                        <div className="selectListConatiner">
                                            {sharedSegmentsData?.map((ele)=>{
                                                return(
                                                    <div className={cx("selectListItem", {"active": !ele?.disabled})} onClick={()=>!ele?.disabled && onSelecteSegment(ele)}>
                                                        <input type="checkbox" checked={ele?.isActive} className='categoryCheckBox' disabled={ele?.disabled}/>
                                                        {ele.segmentName}
                                                        {(ele.expiredFilters?.length > 0 || ele.detachedFilters?.length > 0) &&
                                                            <div className="segmentWarning">
                                                                <IoWarningOutline className="segmentsWarningIcon"/>
                                                                <div className={'tooltipWarning'}>
                                                                    <span style={{padding:"5px 0px",fontSize:"12px",display:"flex"}}>{ele.detachedFilters?.length > 0 ? 'The following filter(s) are not available in this portal view:':'The following filter(s) have expired:'}</span>
                                                                    {ele.filters.map(({filterName, filterOptions})=>{
                                                                            if(ele.expiredFilters.includes(filterName)|| ele.detachedFilters.includes(filterName)){
                                                                                return(
                                                                                    <li>{filterName}</li>
                                                                                )
                                                                            }
                                                                        })                                        
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                    }
                                    {((step === 1 && !isSegmentsMaxLimit) || (step === 0 && expiredorDetachedSegmentsList?.length === 0 && !isSegmentsMaxLimit)) &&
                                        <>
                                            <div className="modalContentText">Would you like us to set up the shared segments in your account?Please choose from the following options to proceed:</div>
                                            <div className="selectListConatiner">
                                                {sharedSegmentsData?.map((ele)=>{
                                                        return(
                                                            <div className={cx("selectListItem", { "active": !ele?.disabled })} onClick={()=> !ele?.disabled && onSelecteSegment(ele)}>
                                                                <input type="checkbox" checked={ele?.isActive} className='categoryCheckBox' disabled={ele?.disabled}/>
                                                                <div className={cx("checkBoxTextConatiner", { "disabled": ele?.disabled })} >{ele.segmentName}</div>
                                                                {ele?.disabled && 
                                                                <div className="segmentWarning">
                                                                    <IoWarningOutline className="segmentsWarningIcon"/>
                                                                    <div className={'tooltipWarning'}>
                                                                        <span style={{padding:"5px 0px",fontSize:"12px",display:"flex"}}>{ele.detachedFilters?.length > 0 ? 'The following filter(s) are not available in this portal view:':'The following filter(s) have expired:'}</span>
                                                                        {(ele.expiredFilters?.length > 0 || ele.detachedFilters?.length > 0) && ele.filters.map(({filterName, filterOptions})=>{
                                                                                if(ele.expiredFilters.includes(filterName)|| ele.detachedFilters.includes(filterName)){
                                                                                    return(
                                                                                        <li>{filterName}</li>
                                                                                    )
                                                                                }
                                                                            })                                        
                                                                        }
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        )
                                                })}
                                            </div>
                                        </>
                                    }
                                    <div className="modalContentText" style={{fontStyle:'italic'}}>Contact your customer success representative for assistance.</div>
                                    {isSegmentsMaxLimit && <div className="warningConatiner">
                                        <HiOutlineInformationCircle className="informationIcon"/>
                                        <div className="modalContentText">We recommend deleting some existing segments.</div>
                                    </div>}
                                </>
                            }
                            { (invalidBrandsforUser?.length> 0 || invalidMarketsforUser?.length > 0) && 
                                <>
                                    <div className="modalContentText" style={{fontSize: '14px'}}>Some details for the view are not applicable to your account.</div>
                                    <div className="warningText">Following {invalidBrandsforUser?.length> 0?'brands':'markets'} are no longer available</div>
                                    <div className="expiredFiltersContainer">
                                        {invalidBrandsforUser.map(id=>{
                                            return (
                                                <>
                                                    <li key={id} className="expiredItem">{'Amazon'}</li>
                                                    <li key={id} className="expiredItem">{'Google'}</li>
                                                </>
                                             )
                                        })}
                                        {invalidMarketsforUser.map(id=>{
                                            return (
                                                <>
                                                    <li key={id} className="expiredItem">{'UK'}</li>
                                                    <li key={id} className="expiredItem">{'Canada'}</li>
                                                </>
                                             )
                                        })}
                                    </div>
                                </>
                            }
                        </div>
                    </>
                    {sharedSegmentsData?.length> 0 ?
                        ((step === 1 || isSegmentsMaxLimit || (step === 0 && expiredorDetachedSegmentsList?.length === 0) ) ?
                            <div className= {cx("buttonContainer",{"disabled": (filteredSegments?.length === segmentsMaxLimit)})} onClick={addSharedSegments}>Proceed</div>
                            :
                            <div className="buttonContainer" onClick={handleSegmentsLimit}>Understood</div> 
                        )
                        :
                        <div className="buttonContainer" onClick={handleCancel}>Confirm</div>
                    }
                    
                </Modal>
            }
        </>
    )
}

const mapStateToProps = ({ twoByTwoChart }) => ({
    customSegmentList: twoByTwoChart.customSegmentList,
});

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true },
)(WarningModal);