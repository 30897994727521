import styled from "styled-components";
import Tippy from "@tippyjs/react";
import {
  PRIMARY_BORDER_GRAY_COLOR,
  TEXT_NORMAL,
} from "../ViewsComponents/ViewCard/utils/constant";

const StyledCustomTooltip = styled(Tippy)`
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid ${PRIMARY_BORDER_GRAY_COLOR};
  box-shadow: 0px 5px 3px -2px #0000000f;
  .tippy-content {
    padding: 0px;
  }
  .tippy-content ul {
    font-weight: 500;
    color: ${TEXT_NORMAL};
    margin: 5px 0px;
    li {
      height: auto;
      font-size: 11px;
      padding: 4px 8px;
      width: fit-content;
    }
  }
`;

export default StyledCustomTooltip;
