import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import NoData from './NoData';
import ApiData from '../../../../utils/Api';
import initData from '../../../../utils/initData';
import ListTable from '../../../widgets/Shared/ListTable';
import ComboBox from '../../../widgets/Shared/ComboBox';
import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import StatusLabel from '../../../widgets/Shared/StatusLabel';
import AddFilters from './AddFilters';
import EditFilter from './EditFilter';
import RemoveFilter from './RemoveFilter';
import { Box, Typography } from '@material-ui/core';
import LoadingLayer from '../../../widgets/Shared/LoadingLayer';

const FilterContainer = styled.div`
    border-top: 1px solid #DFE2EA;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    & input[type=checkbox] {
        width: 20px;
        height: 15px;
    }
`;

const AddFilterBtn = styled.button`
    width: auto;
    height: auto;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background-color: #0062FF;
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    transition: all 0.3s ease-out 0s;
    display: flex;
    gap: 5px;
    padding: 0.5rem;
    align-items: center;
    &:hover {
        background-color: #0062FF;
        box-shadow: 0 2px 12px 0 rgba(0,98,255,0.5);
    }
    &.disabled {
        opacity: 0.5;
        cursor: default;
        &:hover {
          background-color: #0062FF;
          box-shadow: none;;
        }
    }
    & > .icon {
        width: 24px;
    }
`;

const FilterCell = styled.div`
    column-gap: 5px;
    display: flex;
    & > div.wrapper {
        display: flex;
        flex-direction: column;
        & > .name {
            font-weight: bold;
        }
    }
    
`;

const TableCell = styled.div`
    color: #71767D;
`;

const StyledTable = styled.div`
    & > div.listTable {
        display: table;
        min-width: auto;
        & > div.tableHeader, & > div.tableRow {
            display: table-row;
            text-transform: capitalize;
            & .cell-0 {
                width: 20%;
            }
            & > div.headerCell {
                display: table-cell;
            }
            & > div.rowCell {
                display: table-cell;
                vertical-align: middle;
            }
        }
        & > div.tableRow {
            height: 70px;
        }
    }
`;

const SearchContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    & > div.btn-container {
        margin-left: auto;
    }
    margin: 20px 0;
`;

const FilterAlertBox = styled(Box)`
    display: flex;
    position: fixed;
    bottom: 0px;
    z-index: 1;
    & > .content {
        background-color: green;
        padding: 15px;
        border-radius: 10px;
        color: white;
        & .delete {
            color: red;
        }
        & > .icon {
            cursor: pointer;
            width: 15px;
        }
        & .action-btn {
            background: white;
            text-transform: capitalize;
            margin: 0 10px;
        }
    } 
`;
const PAGE_SIZE = 50;
export const START_DATE = 'editStartDate';
export const EXPIRY_DATE = 'editExpiryDate';

const Filters = (({ groupId, showAdminPanelMessage, updateFilterCount, errorMessage }) => {
    const [listData, setListData] = useState([]);
    const [filterCheckAry, setFilterCheckAry] = useState(new Array(listData?.length).fill(false));
    const [totalCount, setTotalCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [listColumns, setListColumns] = useState([]);
    const [addFilterIsOpen, setAddFilterIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [selectedFiltersList, setSelectedFiltersList] = useState([]);
    const [filterAction, setFilterAction] = useState('');
    const [searchData, setSearchData] = useState({});
    const [allMarketList, setAllMarketList] = useState([]);
    const [marketAry, setMarketAry] = useState([]);
    const [categoryAry, setCategoryAry] = useState([]);
    const [typeAry, setTypeAry] = useState([]);
    const [userSorting, setUserSorting] = useState({ sort: 'filter', asc: true });
    const [headerCheck, setHeaderCheck] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const { types } = initData.getStatusMessages();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePageChange = (newCurrentPage) => {
        setCurrentPage(newCurrentPage)
    };
    const handleFilterCheck = (event, filterData, position) => {
        const updatedCheckedState = filterCheckAry.map((item, index) =>
            index === position ? !item : item
        );
        const aObj = { ...selectedFilters };
        const { id } = filterData.filter;
        aObj[`${id}`] = { checked: event.target.checked, ...filterData };
        setFilterCheckAry(updatedCheckedState);
        setSelectedFilters({ ...aObj });
        if(event.target.checked){
            setSelectedFiltersList([...selectedFiltersList,filterData])
        }else{
            let filteredList = selectedFiltersList.filter(obj=>obj.id !== filterData.id)
            setSelectedFiltersList([...filteredList])
        }
    }
    const handleSorting = ({ value, sorting }) => {
        setUserSorting({ sort: value, asc: !userSorting.asc })
    }
    const getSelectedFilterIds = (startDate, expiryDate) => {
        let selectedList = []; 
        
        selectedFiltersList.forEach(el=>{
            selectedList.push({
                id:el.id,
                filterIds: [el.filter.id] ,
                startDate: startDate?startDate:el.date_started,
                expiryDate: expiryDate?expiryDate:el.expiry_date
            })
        })
        return selectedList;
    }
    const getSelectedMinDate = () => {
        const [minDate]= Object.keys(selectedFilters).sort((a,b) => {
            const prevDate = moment(selectedFilters[a].date_started);
            const nextDate = moment(selectedFilters[b].date_started);
            return nextDate - prevDate;
        });
        return selectedFilters[minDate].date_started;
    }
    const getSelectedMaxDate = () => {
        const [maxDate]= Object.keys(selectedFilters).sort((a,b) => {
            const prevDate = moment(selectedFilters[a].expiry_date);
            const nextDate = moment(selectedFilters[b].expiry_date);
            return prevDate - nextDate;
        });
        return selectedFilters[maxDate].expiry_date;
    }
    const getMinMaxDate = (filterAction) => {
        const maxDate = getSelectedMaxDate();
        const minDate = getSelectedMinDate();
        return {maxDate, minDate};
    };
    const editFilterHandler = async ({ startDate, expiryDate }) => {
        let payload = getSelectedFilterIds(startDate, expiryDate)
        const data = await ApiData.editFilters(groupId, payload);
        let existingfilters = selectedFiltersList.filter(val => !data?.some(el=> el?.filterId == val?.filter?.id)).map(el=>el?.filter?.name[0]+ el?.filter?.name?.substring(1)?.toLowerCase());
        
        if(existingfilters?.length > 0){
            showAdminPanelMessage(types.existingFilter,existingfilters.toString());
            errorMessage(true);
        }else{
            showAdminPanelMessage(types.filterUpdated);
            errorMessage(false);
        }
        await getFilterData(groupId);
    };
    const removeFilter = async () => {
        const payload = getSelectedFilterIds();
        await ApiData.removeFilters(groupId, payload);
        await getFilterData(groupId);
        errorMessage(false);
        showAdminPanelMessage(types.filterRemove)
        resetFilterAction();
    }
    const getFilterCell = (data, index, colData) => {
        return (
            <FilterCell>
                <input type="checkbox" onChange={(event) => handleFilterCheck(event, colData, index)} name={data?.id} checked={filterCheckAry[index]} />
                <div className="wrapper">
                    <div className='name'>{data?.name.toLowerCase()}</div>
                    <TableCell className='id' style={{textTransform:'lowercase',fontSize:'13px'}}>({colData.decipherVariableName} || # {data?.id})</TableCell>
                </div>
            </FilterCell>
        )
    };
    const getFilterJsx = () => {
        return <input type="checkbox" onClick={(event) => setHeaderCheck(event.target.checked)} />
    }
    const getCellElement = (data) => {
        return (<TableCell>{data}</TableCell>);
    };
    const getStatusElement = (data) => {
        return (<StatusLabel status={data} />);
    };
    const getAddedBy = (rowData) => {
        return (
            <>
                <TableCell>{rowData?.name}</TableCell>
                <TableCell>{rowData?.email}</TableCell>
            </>
        );
    };
    const getMarketCell = (rowData = []) => {
        if (rowData.length > 1) {
            return (<TableCell>{rowData[0]},<span className='more' title={rowData.join(',')}>{rowData.length - 1} more</span></TableCell>)
        }
        return (
            <TableCell>{rowData[0]}</TableCell>
        );
    }
    const getDateCell = (rowData) => {
        let date = null;
        if (rowData) {
            date = moment(rowData).format('MMM D, YYYY')
        }
        return getCellElement(date);
    }
    const CellMap = {
        filter: getFilterCell,
        status: getStatusElement,
        added_by: getAddedBy,
        markets: getMarketCell,
        date_started: getDateCell,
        expiry_date: getDateCell,
        date_expired: getDateCell,
        field_start_date: getDateCell,
        date_added: getDateCell,
    };
    const getTableCells = (rowData, colName, index, rowCount, colData) => {
        let ele;
        if (CellMap[`${colName}`]) {
            ele = CellMap[`${colName}`](rowData, rowCount, colData)
        } else {
            ele = getCellElement(rowData)
        }
        return ele;
    };

    const addFilterPopUp = () => {
        setAddFilterIsOpen(!addFilterIsOpen)
    }

    const handleFilterAction = (val) => {
        setFilterAction(val);
        handleClose();
    }

    const resetFilterAction = () => {
        setFilterAction('');
        setFilterCheckAry(new Array(listData?.length).fill(false));
        setSelectedFilters({});
        setSelectedFiltersList([])
        setMarketAry([]);
        setCategoryAry([]);
    }

    const unSelectAllFilters = () => {
        setFilterCheckAry(new Array(listData?.length).fill(false));
    };
    const getFilterHeaders = (data) => {
        const headerSorting = {
            'filter': 0,
            'category': 1,
            'markets': 2,
            'status': 3,
            // 'type': 4,
            'date_started': 5,
            'date_expired': 6,
            'expiry_date': 6,
            'field_start_date': 7,
            'date_added': 8,
            'added_by': 9
        };
        const headers = Object.keys(data[0]).filter((el=>el !== 'type' && el !== 'decipherVariableName' && el !== 'id')).map(header => {
            const aObj = {};
            aObj.title = header.split('_').join(' ');
            aObj.value = header;
            aObj.rank = headerSorting[header];
            if (header === 'filter') {
                aObj.sorting = true;
                aObj.jsx = getFilterJsx();
            }
            return aObj;
        });
        const sortedHeader = headers.sort((prev, next) => prev.rank - next.rank);
        setListColumns([...sortedHeader]);
    };
    const getAllMarketList = async (groupId) => {
        const resData = await ApiData.getAllMarketList(groupId);
        return resData;
    }

    const splitFilterData=(listData, chunk_size)=>{
        var results = [];
        while (listData?.length) {
            results.push(listData?.splice(0, chunk_size));
        }
        return results;
    }

    const getFilterData = async (groupId, params) => {
        const paramsObj = params || {
            ...userSorting,
            // page: currentPage,
            num: 150,
            marketSearch: marketAry.join(','),
            categorySearch: categoryAry.join(','),
            typeSearch: typeAry.join(','),
        }

        let { pageInfo, data } = await ApiData.getGroupFiltersList(groupId, paramsObj);
        let result = splitFilterData(data, 50);
        data = result[currentPage-1];
        setTotalCount(pageInfo?.totalCount);
        updateFilterCount();
        getSearchContainerData(groupId);
        if (data?.length) {
            setListData(data);
            getFilterHeaders(data);
            setHeaderCheck(false);
            setFilterCheckAry(new Array(data.length).fill(false));
        } else if (currentPage > 1) {
            handlePageChange(currentPage-1);
        } else if(currentPage === 1 && !data?.length){
            setListData([])
        }
        setLoading(false);
    };
    const getSearchContainerData = async (groupId) => {
        const data = await ApiData.getAllSearchFiltersTypes(groupId);
        if(allMarketList?.length  === 0){
           const marketData = await getAllMarketList(groupId);
            if(!data?.markets?.length) {
                data.markets = [...marketData];
            }
            setAllMarketList(marketData)
        }
       
        setSearchData({ ...data });
    }
    const createFilterCallback = (existFilters) => {
        if(existFilters?.length > 0){
            showAdminPanelMessage(types.existingFilter, existFilters.toString());
            errorMessage(true);
        }else{
            showAdminPanelMessage(types.filterCreate);
            errorMessage(false);
        }
        getFilterData(groupId);
    }
    
    useEffect(() => {
        setLoading(true);
        getFilterData(groupId);
    }, [marketAry, categoryAry, typeAry, currentPage, userSorting]);

    useEffect(() => {
        const aObj = listData?.reduce((acc, data) => {
            const { id } = data.filter;
            acc[`${id}`] = { checked: headerCheck, ...data };
            return acc;
        }, {});
        setFilterCheckAry(new Array(listData?.length).fill(headerCheck));
        setSelectedFilters({ ...aObj });
        headerCheck ? setSelectedFiltersList([...listData]) : setSelectedFiltersList([])
    }, [headerCheck])
    return (
        <>
            {loading && <LoadingLayer />}
            {!loading && <FilterContainer>
                {(listData?.length > 0) && <>
                    <SearchContainer>
                        <ComboBox
                            style={{ width: '20%' }}
                            data={searchData?.markets}
                            name={"Market"}
                            value={marketAry}
                            setValue={setMarketAry}
                            icon={<QuestionMarkCircleIcon className='icon' />} />
                        <ComboBox
                            style={{ width: '20%' }}
                            data={searchData?.filterCategories}
                            name={"Category"}
                            value={categoryAry}
                            setValue={setCategoryAry}
                            icon={<QuestionMarkCircleIcon className='icon' />} />
                        {/* <ComboBox
                            style={{ width: '20%' }}
                            data={searchData?.filterType}
                            name={"Type"}
                            value={typeAry}
                            setValue={setTypeAry}
                            icon={<QuestionMarkCircleIcon className='icon' />} /> */}
                        <div className='btn-container'>
                            {(listData?.length > 0) && <AddFilterBtn color="0062FF" onClick={addFilterPopUp}><PlusIcon className='icon' />Add Filter</AddFilterBtn>}
                            
                        </div>
                    </SearchContainer>
                    <StyledTable>
                        <ListTable
                            listData={listData}
                            total={totalCount}
                            limit={PAGE_SIZE}
                            current={currentPage}
                            listColumns={listColumns}
                            handlePageChange={handlePageChange}
                            sortedColumn={userSorting}
                            tableItem={true}
                            cells={getTableCells}
                            withPagination={true}
                            sortTable={handleSorting}
                            isSecondaryTable={true}
                        // sortTable={pathname === '/users' ? this.sortUsers : this.sortGroups}
                        >
                        </ListTable>
                    </StyledTable>
                </>}
                {filterCheckAry.some((ele) => ele) && <FilterAlertBox>
                    <Box className="content">
                        <Typography component="span">{filterCheckAry.filter(ele => ele).length} {filterCheckAry.filter(ele => ele).length > 1 ? `Filters` : `Filter`} selected</Typography>
                        <Button onClick={handleClick} endIcon={<ExpandMoreIcon />} className='action-btn'>
                            Actions
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => handleFilterAction(START_DATE)} >Edit Filter Date Started</MenuItem>
                            <MenuItem onClick={() => handleFilterAction(EXPIRY_DATE)} >Edit Filter Expiry Date</MenuItem>
                            <MenuItem style={{ color: 'red' }} onClick={() => handleFilterAction('remove')}>Remove Filter</MenuItem>
                        </Menu>
                        <XMarkIcon className="icon" onClick={unSelectAllFilters} />
                    </Box>
                </FilterAlertBox>}
                {(filterAction === START_DATE || filterAction === EXPIRY_DATE) && <EditFilter isOpen={true} action={filterAction} closeFilter={resetFilterAction} filterCount={filterCheckAry.filter(ele => ele)?.length} editFilterHandler={editFilterHandler} minMaxDates={getMinMaxDate(filterAction)} />}
                {<RemoveFilter open={filterAction === 'remove'} closeHandle={resetFilterAction} removeHandle={removeFilter} filterCount={filterCheckAry.filter(ele => ele)?.length} />}
                {addFilterIsOpen && <AddFilters marketData={allMarketList} isOpen={addFilterIsOpen} closeAddFilter={addFilterPopUp} groupId={groupId} onCreation={createFilterCallback} data={listData}/>}

                {(Number(listData?.length) === 0 && !loading) && <NoData>
                    <AddFilterBtn color="0062FF" onClick={addFilterPopUp}><PlusIcon className='icon' />Add Filter</AddFilterBtn>
                </NoData>}
            </FilterContainer>}
        </>
    );
});
export default Filters;