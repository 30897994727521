import React from "react";
import styled from "styled-components";

const StyledSkeleton = styled.div`
  background-color: white;
  border-radius: 8px;
  width: ${(props) => (props.width ? props.width : "100%")};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  .skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear;
    position: relative;
  }
  .skeleton-avatar {
    width: ${(props) => (props.width ? props.width : "50px")};
    height: ${(props) => (props.height ? props.height : "50px")};
    border-radius: 50%;
  }
  .skeleton-text {
    height: ${(props) => (props.height ? props.height : "20px")};
    width: 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  .skeleton {
    background: linear-gradient(to right, #e0e0e0ab 8%, #f4f4f4 18%, #e0e0e0ab 33%);
    background-size: 1000px 100%;
  }
`;

function Skeleton({ variant = "text", height, width }) {
  if (variant === "avatar") {
    return (
      <StyledSkeleton height={height} width={width}>
        <div className="skeleton skeleton-avatar" />
      </StyledSkeleton>
    );
  }
  return (
    <StyledSkeleton height={height} width={width}>
      <div className="skeleton skeleton-text" />
    </StyledSkeleton>
  );
}

export default Skeleton;
