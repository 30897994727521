import React from "react";
import { FaAngleRight } from "react-icons/fa6";

import StyledViewCardFooter from "./Styled.ViewCardFooter";

import FilterIcon from "../../../../assets/icons/icon_filter_gray.svg";
import ViewCardTooltip from "../ViewCardTooltip";
import { getSelectViewURL } from "../utils";

function ViewCardFooter({ filters, segments, view }) {
  function handleSelectView() {
    const url = getSelectViewURL(view.module, view.screenType, view.viewUrl);
    window.open(url, "_blank");
  }

  return (
    <StyledViewCardFooter>
      <div className="segment-and-filter">
        {filters?.length > 0 && (
          <ViewCardTooltip
            contentList={filters.map((filter) => filter.filterName)}
            offset={[0, -5]}
          >
            <div className="segments-filter-list">
              <img src={FilterIcon} alt="filters" />
              <span>{filters?.length} Filters</span>
            </div>
          </ViewCardTooltip>
        )}
        {segments.length > 0 && (
          <ViewCardTooltip
            contentList={segments.map((segment) => segment.segmentName)}
            offset={[0, -5]}
          >
            <div className="segments-filter-list">
              <img src={FilterIcon} alt="segments" />
              <span>{segments?.length} Segments</span>
            </div>
          </ViewCardTooltip>
        )}
      </div>
      <div className="select-view" onClick={handleSelectView}>
        <div>Select view</div>
        <FaAngleRight fontSize={18} />
      </div>
    </StyledViewCardFooter>
  );
}

export default ViewCardFooter;
