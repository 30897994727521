import React, { useState, useRef, useEffect } from "react";
import { DateTime } from "luxon";
import Calendar from "react-calendar";
import InitData from "../../../../utils/initData";
import { StyledTooltip, StyledCustomDateRangeSelector } from "./style";
import {
  DATE_PERIOD_KEY_MAPPING,
  DATE_PERIOD_KEY_MAPPING_REVERSE,
  DISPLAY_DATE_FORMAT,
  getDatesBasedonSelection,
  getDatesBasedonSelectionFromBackend,
} from "./utils";

function CustomDateRangeSelector({
  value = [],
  showMovingAverage,
  handleApply,
  disabled,
  minDate,
  showDoubleView = true,
  maxDate,
  customTrigger,
  datePeriod,
  customPeriodOptionToFilter = [1],
}) {
  const [dateRangeSelected, setDateRangeSelected] = useState(
    getDatesBasedonSelection("L180D")
  );
  const [currentView, setCurrentView] = useState("month");
  const [appliedDateRange, setAppliedDateRange] = useState(
    getDatesBasedonSelection("L180D")
  );
  const [activeDatePeriod, setActiveDatePeriod] = useState("L180D");

  const tippyRef = useRef();

  useEffect(() => {
    setDateRangeSelected([...value]);
    setAppliedDateRange([...value]);
    if (datePeriod?.length > 0) {
      setActiveDatePeriod(datePeriod);
    }
  }, [value, datePeriod]);

  function handleOnViewChange(view) {
    setCurrentView(view.view);
  }

  function handleRangeChange(condition) {
    setDateRangeSelected(getDatesBasedonSelection(condition));
  }

  function handleCancel() {
    tippyRef.current._tippy.hide();
    setDateRangeSelected([...appliedDateRange]);
  }

  function handleApplyLocal() {
    handleApply(dateRangeSelected[0], dateRangeSelected[1], activeDatePeriod);
    setAppliedDateRange([...dateRangeSelected]);
    tippyRef.current._tippy.hide();
  }

  return (
    <StyledCustomDateRangeSelector disabled={disabled}>
      {disabled ? (
        customTrigger ? (
          customTrigger
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <span className="date-button">
              {DateTime.fromJSDate(appliedDateRange[0]).toFormat(
                DISPLAY_DATE_FORMAT
              )}
            </span>
            <span className="to-text" onClick={(e) => e.preventDefault()}>
              to
            </span>
            <span className="date-button end-date-button">
              {DateTime.fromJSDate(appliedDateRange[1]).toFormat(
                DISPLAY_DATE_FORMAT
              )}
            </span>
          </div>
        )
      ) : (
        <>
          <StyledTooltip
            ref={tippyRef}
            allowHTML={true}
            placement="bottom-end"
            trigger="click"
            dis
            interactive="true"
            hideOnClick={false}
            onClickOutside={handleCancel}
            content={
              <div className="custom-range-container">
                <div className="range-selection-list">
                  <ul>
                    {InitData.getPeriodOptions(
                      showMovingAverage,
                      customPeriodOptionToFilter
                    ).map((item) => (
                      <li
                        key={item.key}
                        className={
                          item.key === activeDatePeriod
                            ? "range-selection-active-period"
                            : ""
                        }
                        onClick={() => {
                          setActiveDatePeriod(item.key);
                          handleRangeChange(item.key);
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="calendar-container">
                  <Calendar
                    key={dateRangeSelected}
                    selectRange={true}
                    showDoubleView={currentView === "month"}
                    onViewChange={handleOnViewChange}
                    onChange={(val) => {
                      setActiveDatePeriod("CUSTOM");
                      setDateRangeSelected([...val]);
                    }}
                    value={dateRangeSelected}
                    showNeighboringMonth={true}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                  <div className="custom-range-selector-footer">
                    <div>
                      {/* {JSON.stringify(dateRangeSelected)} */}
                      <span className="date-selection-column">
                        {DateTime.fromJSDate(dateRangeSelected[0]).toFormat(
                          DISPLAY_DATE_FORMAT
                        )}
                      </span>
                      <span>-</span>
                      <span className="date-selection-column">
                        {DateTime.fromJSDate(dateRangeSelected[1]).toFormat(
                          DISPLAY_DATE_FORMAT
                        )}
                      </span>
                    </div>
                    <div className="custom-range-footer-button">
                      <button onClick={handleCancel}>Cancel</button>
                      <button
                        className="active-button"
                        onClick={handleApplyLocal}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            {customTrigger ? (
              customTrigger
            ) : (
              <div>
                <span className="date-button">
                  {DateTime.fromJSDate(appliedDateRange[0]).toFormat(
                    DISPLAY_DATE_FORMAT
                  )}
                </span>
                <span className="date-button end-date-button">
                  {DateTime.fromJSDate(appliedDateRange[1]).toFormat(
                    DISPLAY_DATE_FORMAT
                  )}
                </span>
              </div>
            )}
          </StyledTooltip>
          {!customTrigger && (
            <span
              className="to-text to-text-position"
              onClick={(e) => e.preventDefault()}
            >
              to
            </span>
          )}
        </>
      )}
    </StyledCustomDateRangeSelector>
  );
}

export default CustomDateRangeSelector;
