import React from "react";

const Tooltip = ({ title, children, toggleToolTipOpenHandler }) => {
  console.log("components");
  console.log(children);
  return (
    <div className="tooltip-container">
      <div onClick={toggleToolTipOpenHandler}>{children}</div>
      <span className="tooltip-text" id="message-content-container">
        {title}
      </span>
    </div>
  );
};

export default Tooltip;
