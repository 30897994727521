const productSuiteData = [
    {
        logo:'QuestDIY',
        label: "QuestDIY",
        description: 'Global audience of survey respondents at your fingertips.',
        link: "/quest-diy",
        contact: "vignesh.rajan@harrispoll.com"
    },
    {
        logo:'QuestAI',
        label: 'QuestAI',
        description: 'Analyze Quant and Qual data with the power of AI.',
        link: "/quest-ai",
        contact: "vignesh.rajan@harrispoll.com"
    },
    {
        logo:'QuestIC',
        label: 'QuestIC',
        description: `Direct access to customer respondents using Quest's research innovation.`,
        link: "/quest-ic",
        contact: "vignesh.rajan@harrispoll.com"
    },
    {
        logo:'QuestPro',
        label: 'QuestPro',
        description: 'Truly underastand your customers with an advanced survey capability.',
        contact: "vignesh.rajan@harrispoll.com"
    }
]

export default productSuiteData;