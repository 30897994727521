import React, { useEffect, useRef } from "react";
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from "@material-ui/icons";

// Helper function to parse text and extract links
const parseText = (text) => {
  const regex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
  let parts = [];
  let lastIndex = 0;
  let match;

  // Find all the matches for the [text](link) pattern
  while ((match = regex.exec(text)) !== null) {
    // Push the text before the match as a regular string
    if (match.index > lastIndex) {
      parts.push(text.slice(lastIndex, match.index));
    }
    // Push the match as an object containing the link and text
    parts.push({ text: match[1], link: match[2] });
    lastIndex = regex.lastIndex;
  }

  // Push any remaining text after the last match
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const Message = ({ type = "You", text = "", previousType = "" }) => {
  const messageRef = useRef(null);
  useEffect(() => {
    const messageElement = messageRef.current;
    if (messageElement) {
      setTimeout(() => {
        messageElement.classList.add("show");
      }, 100);
    }
  }, []);

  // Parse the message text to identify links
  const parsedText = parseText(text);

  return (
    <li
      ref={messageRef}
      className={`msg-text ${type === "You" ? "align-right" : "align-left"}`}
    >
      {/* Render the type indicator if needed */}
      {type !== previousType && <p>{type}</p>}
      {/* Map through parsedText and create a separate div for each item */}
      {parsedText
        .filter((part) =>
          typeof part === "string" ? part.trim() !== "" : true
        )
        .map((part, index) => (
          <>
            <div key={index} className={type}>
              {typeof part === "string" ? (
                // Render plain text
                <span className="typing-effect">{part}</span>
              ) : (
                // Render a link
                <a href={part.link} target="_blank" rel="noopener noreferrer">
                  {part.text}
                </a>
              )}
            </div>
            {/* Feedback reaction icons */}
            {/* <span className="messageFeedbackicons">
                    <ThumbUpAltOutlined/>
                    <ThumbDownAltOutlined/>
                </span> */}
          </>
        ))}
    </li>
  );
};

export default Message;
