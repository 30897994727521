import React, { useEffect, useState } from "react";

const MessageLoader = ({ type = "You", isVisible }) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (isVisible) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [isVisible]);

  return (
    <li
      className={`msg-text ${showLoader ? "show" : "hide"} ${
        type === "You" ? "align-right" : "align-left"
      }`}
    >
      <p>{type}</p>
      <div className={`typing-loader ${type}`}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </li>
  );
};

export default MessageLoader;
